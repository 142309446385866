import { ProjectBoInterface, ProjectStatus } from '@boostpoint/types';
import { useEffect, useState } from 'react';
import { BiSolidChevronUp } from 'react-icons/bi';
import { FiMenu } from 'react-icons/fi';
import { GrClose } from 'react-icons/gr';
import { Link, useParams } from 'react-router-dom';
import MenuDots from '../MenuDots';
import Logo from '../assets/boostpoint-logo.png';
import DesktopBg from '../assets/menuBorderDesktop.png';

export const Menu = (props: Props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [projectsOpen, setProjectsOpen] = useState(false);
  const { projectId } = useParams();

  const onToggle = () => {
    if (projectsOpen) setProjectsOpen(false);
    if (props.toggle) props.toggle();
  };

  useEffect(() => {
    const vw = window.innerWidth;
    if (vw > 640) setMenuOpen(true);
  }, []);

  const clickItem = () => {
    if (props.toggle) props.toggle();
  };

  useEffect(() => {
    if (projectId) setProjectsOpen(true);
  }, [projectId]);

  return (
    <div
      className='md:border-r-solid bottom-0 z-20 bg-white bg-contain bg-[-50px_center] bg-no-repeat supports-[height:100cqh]:h-[calc(100cqh-88px)] md:absolute md:top-0 md:z-10 md:h-[100dvh] md:border-r-[1px] md:border-r-[#EDEBEB] md:bg-left'
      style={{ backgroundImage: `url('${DesktopBg}')` }}
    >
      <div
        onClick={onToggle}
        className={`${
          !props.open && 'bg-bp-indigo'
        } absolute bottom-6 right-6 z-40 flex h-12 w-12 cursor-pointer items-center justify-center rounded-full text-bp-green md:hidden`}
      >
        {!props.open ? (
          <FiMenu
            className='text-bp-green'
            size='2em'
          />
        ) : (
          <GrClose
            className='text-bp-green'
            color='#64EDC2'
            size='2em'
          />
        )}
      </div>
      <div className='border-b-solid ml-[23px] hidden h-[110px] w-full items-center justify-start border-b-[1px] border-b-[#EDEBEB] pl-[27px] pt-[10px] md:flex'>
        <Link to='/'>
          <img
            src={Logo}
            alt='Boostpoint'
            className='w-[166px]'
          />
        </Link>
      </div>
      <div
        className={`${
          props.open ? 'top-[3px]' : 'top-[100dvh]'
        } absolute bottom-0 w-[100vw] flex-col justify-between overflow-hidden bg-white px-[40px] pt-[70px] transition-[top] duration-[500ms] ease-in-out md:static md:top-0 md:flex md:h-[90dvh] md:max-h-max md:w-[344px] md:bg-transparent md:pl-[52px] md:pr-[40px]`}
      >
        <div className='mb-4 shrink grow overflow-y-scroll'>
          <div
            className='group flex justify-between gap-4 text-[16px] font-semibold md:cursor-pointer'
            onClick={() => {
              setMenuOpen(!menuOpen);
            }}
            id='menu-projects-link'
          >
            <div className='flex items-center gap-4'>
              <MenuDots open={menuOpen} />
              Projects
            </div>
            <BiSolidChevronUp
              className={`${
                menuOpen ? 'md:rotate-180' : 'md:rotate-0'
              } transition-500 mt-[4px] rotate-180 text-bp-green transition-all ease-in-out`}
            />
          </div>
          <ul
            className={`ml-[38px] overflow-hidden ${
              menuOpen ? 'md:max-h-[100dvh]' : 'md:max-h-0'
            } transition-500 transition-all ease-in-out`}
          >
            <li className='my-[10px] text-[16px]'>
              <Link
                to='/project/new/name'
                className='inline-block w-full hover:font-semibold hover:text-bp-pink'
                onClick={clickItem}
              >
                New Project
              </Link>
            </li>
            <li>
              <span
                onClick={() => setProjectsOpen(!projectsOpen)}
                className='my-[10px] cursor-pointer text-[16px] hover:font-semibold hover:text-bp-pink'
              >
                All Projects
              </span>
              <ul
                className={`${
                  projectsOpen ? 'max-h-[calc(100dvh-300px)]' : 'max-h-0'
                } transition-500 ml-4 mt-4 overflow-y-auto overflow-x-hidden transition-all ease-in-out`}
              >
                {props.projectList.map(project => (
                  <li
                    key={project.id}
                    className={`mb-[10px] hover:font-semibold hover:text-bp-pink ${
                      project.id === projectId
                        ? 'font-semibold text-bp-pink'
                        : ''
                    }`}
                  >
                    <Link
                      to={
                        project.status === ProjectStatus.Draft
                          ? `/project/new/name/${project.id}`
                          : `/project/results/${project.id}`
                      }
                      onClick={clickItem}
                      className='inline-block w-full'
                    >
                      {project.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </div>
        {props.children}
      </div>
    </div>
  );
};

type Props = {
  open: boolean;
  toggle?: () => void;
  projectList: ProjectBoInterface[];

  children?: React.ReactNode;
};
