import { useListBoxSection } from 'react-aria';
import { ListState, Node } from 'react-stately';
import { Option } from './Option';

interface Props<T> {
  section: Node<T>;
  state: ListState<T>;
}

export const ListBoxSection = <T extends object>(props: Props<T>) => {
  const { section, state } = props;

  const { itemProps, headingProps, groupProps } = useListBoxSection({
    heading: section.rendered,
    'aria-label': section['aria-label'],
  });

  // If the section is not the first, add a separator element to provide visual separation.
  // The heading is rendered inside an <li> element, which contains
  // a <ul> with the child items.
  return (
    <>
      {section.key !== state.collection.getFirstKey() && (
        <li
          role='presentation'
          className='mx-2 my-1 border-[1px] border-bp-gray'
        />
      )}
      <li {...itemProps}>
        {section.rendered && (
          <span
            {...headingProps}
            className='px-2 py-1 text-lg font-bold'
          >
            {section.rendered}
          </span>
        )}
        <ul
          {...groupProps}
          className='list-none p-0'
        >
          {[...section.childNodes].map(node => (
            <Option
              key={node.key}
              item={node}
              state={state}
            />
          ))}
        </ul>
      </li>
    </>
  );
};
