import { ReactNode } from 'react';

interface Props {
  title?: string;
  img?: string;
  children?: ReactNode;
}

export const ActionDetails = (props: Props) => {
  const { title, img, children } = props;

  return (
    <div className='flex min-w-[50%] flex-1 flex-col p-16 md:overflow-y-auto'>
      <div className='sticky flex items-center'>
        {img && (
          <img
            src={img}
            alt={title}
            className='h-6 w-6'
          />
        )}
        <h2 className='ml-2 flex-1 text-xl font-semibold text-bp-black'>
          {title}
        </h2>
        <div className='justify-self-end'>
          {/* TODO: Add action menu for action details */}
        </div>
      </div>
      <div className='mt-6'>{children}</div>
    </div>
  );
};
