import {
  CompanyBoInterface,
  CompanyInvitationBoInterface,
  MemberRole,
  ProjectBoInterface,
  SubscriptionBoInterface,
  UserBoInterface,
} from '@boostpoint/types';
import { ReactNode, Ref, useState } from 'react';
import Carl from '../assets/carl.png';
import { Header } from './Header';
import { Menu } from './Menu';

export const Page = (props: Props) => {
  const { showCarl = true, fullHeight = false, contentContainerRef } = props;
  const [companyMenu, setCompanyMenu] = useState(false);

  return (
    <div className='absolute top-0 flex h-[100dvh] w-full flex-col overflow-hidden'>
      <Header
        user={props.user}
        hasRole={props.hasRole}
        companyMenu={companyMenu}
        setCompanyMenu={setCompanyMenu}
        company={props.company}
        invitations={props.invitations}
        subscription={props.subscription}
        getBillingPortal={props.getBillingPortal}
      />
      <div className='flex h-[calc(100dvh-90px)] md:h-[calc(100dvh-110px)]'>
        <Menu
          open={props.menuIsOpen}
          toggle={props.toggleMenu}
          projectList={props.projectList}
        >
          {props.menuChildren}
        </Menu>
        <div className='relative flex w-full justify-end overflow-hidden md:ml-[344px]'>
          <div
            ref={contentContainerRef}
            className='z-1 grow-[2] overflow-y-auto pb-[40px] md:mx-[50px] md:pt-16'
          >
            <div
              className={`mx-auto max-w-[1250px] px-[40px] ${
                fullHeight ? 'h-full' : ''
              }`}
            >
              {props.children}
            </div>
          </div>
          {!!showCarl && (
            <div className='pointer-events-none absolute -bottom-10 z-0 hidden h-[100dvh] w-[270px] items-end justify-end md:flex'>
              <img
                src={Carl}
                alt='carl'
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

type Props = {
  company?: CompanyBoInterface;
  user?: UserBoInterface;
  invitations?: CompanyInvitationBoInterface[];
  projectList: ProjectBoInterface[];
  children: ReactNode;
  menuIsOpen: boolean;
  showCarl?: boolean;
  fullHeight?: boolean;
  contentContainerRef?: Ref<HTMLDivElement>;
  toggleMenu: () => void;
  hasRole: (role: MemberRole) => boolean;
  menuChildren?: ReactNode;
  subscription?: SubscriptionBoInterface;
  getBillingPortal: () => Promise<string>;
};
