import {
  ActionBoInterface,
  ProjectBoInterface,
  ActionType,
} from '@boostpoint/types';
import {
  ActionCard,
  ActionOverlay,
  AddProjectActions,
  PrimaryButton,
  SecondaryButton,
  TextButton,
  WizardStepTitle,
} from '@boostpoint/ui';
import { BiPlus } from 'react-icons/bi';
import { useOverlayTriggerState } from 'react-stately';
import { useDialog } from '../../../providers/DialogProvider';
import { actionTypeMetaMap } from '../../../constants/actionTypeMeta.map';
import useActions from '../../../hooks/useActions';
import useActionRequest from '../../../hooks/useActionRequest';
import { useParams } from 'react-router-dom';
import { useState } from 'react';

interface Props {
  draftProject?: ProjectBoInterface;
  onBack?(): void;
  onContinue?(): void;
  onRename?(name: string): void;
  onArchive?(): Promise<void>;
}

const ProjectWizardActionsStep = (props: Props) => {
  const { draftProject, onBack, onContinue, onRename, onArchive } = props;

  const { actions } = useActions();
  const { projectId } = useParams();
  const { actionRequests, createBulkRequest, deleteRequest } = useActionRequest(
    { projectId },
  );
  const filteredActions = actions.filter(a =>
    a.tags.includes(draftProject?.promptType ?? 'GENERAL'),
  );
  const persistedActionTypes = actionRequests.map(a => a.type);
  const persistedActions = actions
    .filter(a => persistedActionTypes.includes(a.type))
    .sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      } else {
        return -1;
      }
    });
  const [selectedActionTypes, setSelectedActionTypes] = useState<ActionType[]>(
    [],
  );

  const {
    isOpen: isOverlayOpen,
    open: openOverlay,
    close: closeOverlay,
  } = useOverlayTriggerState({});
  const { setDialogOpen, setDialog } = useDialog();

  const selectAction = (action: ActionBoInterface) => {
    setDialog(
      <div
        className={`block w-full flex-row items-end justify-between gap-12 xl:flex`}
      >
        <div>
          <h2 className='text-[24px] font-semibold md:text-[32px]'>
            Are you sure?
          </h2>
          <p className=''>
            Are you sure you want to remove the {action.name} action from the
            project?
          </p>
        </div>
        <div className='mt-8 flex flex-row-reverse gap-4'>
          <PrimaryButton onPress={() => removeAction(action.type)}>
            Remove
          </PrimaryButton>
          <SecondaryButton onPress={() => setDialogOpen(false)}>
            Cancel
          </SecondaryButton>
        </div>
      </div>,
    );
    setDialogOpen(true);
  };

  const removeAction = (type: ActionType) => {
    //delete action request by type
    const requestToRemove = actionRequests.find(ar => ar.type === type);
    if (requestToRemove?.id) {
      deleteRequest(requestToRemove.id);
    }
    setDialogOpen(false);
  };

  const handleAddActions = () => {
    console.debug('Add actions to project', selectedActionTypes);
    //bulk create action types
    if (projectId) {
      const ActionRequestDTOMap = selectedActionTypes.map(type => {
        return {
          type,
          projectId,
        };
      });
      createBulkRequest(ActionRequestDTOMap);
      setSelectedActionTypes([]);
      closeOverlay();
    }
  };

  const handleOpenOverlay = () => {
    console.debug('Open add project overlay');
    setSelectedActionTypes([]);
    openOverlay();
  };

  const handleCloseOverlay = () => {
    console.debug('Close add project overlay');
    setSelectedActionTypes([]);
    closeOverlay();
  };

  return (
    <div>
      <WizardStepTitle
        projectName={draftProject?.name}
        onRename={onRename}
        onArchive={onArchive}
        id='project-wizard-actions-title'
      >
        What would you like to create?
      </WizardStepTitle>
      <div className='mt-16 grid gap-4 lg:grid-cols-2 xl:grid-cols-3'>
        {persistedActions.map(action => {
          const meta = actionTypeMetaMap.get(action.type);
          return (
            <ActionCard
              key={action.id}
              action={action}
              img={meta?.img ?? ''}
              selected={false}
              onSelect={() => {
                selectAction(action);
              }}
            />
          );
        })}
        <div className='flex min-h-[166px] items-center justify-center rounded-2xl border-[4px] border-dashed border-bp-gray'>
          <TextButton
            className='flex gap-2'
            onPress={handleOpenOverlay}
          >
            <BiPlus fontSize='24px' />
            <span>Add More Actions</span>
          </TextButton>
        </div>
      </div>
      <div className='mt-16 flex gap-4 pb-12'>
        <SecondaryButton onPress={onBack}>Back</SecondaryButton>
        <PrimaryButton
          isDisabled={!persistedActions.length}
          onPress={onContinue}
        >
          Continue
        </PrimaryButton>
      </div>
      <ActionOverlay
        buttons={
          <div className='flex gap-4'>
            <SecondaryButton onPress={handleCloseOverlay}>
              Cancel
            </SecondaryButton>
            <PrimaryButton
              isDisabled={!selectedActionTypes.length}
              onPress={handleAddActions}
            >
              Add to Project
            </PrimaryButton>
          </div>
        }
        isOpen={isOverlayOpen}
        onClose={handleCloseOverlay}
      >
        <AddProjectActions
          actions={filteredActions}
          persistedActionTypes={persistedActionTypes}
          selectedActionTypes={selectedActionTypes}
          actionTypeMetaMap={actionTypeMetaMap}
          setSelectedActionTypes={setSelectedActionTypes}
        />
      </ActionOverlay>
    </div>
  );
};

export default ProjectWizardActionsStep;
