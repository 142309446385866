import { useMenuSection, useSeparator } from 'react-aria';
import { Node, TreeState } from 'react-stately';
import { Key } from '@react-types/shared';
import { ActionMenuItem } from './ActionMenuItem';

interface Props<T> {
  section: Node<T>;
  state: TreeState<T>;
  onAction: (key: Key) => void;
  onClose: () => void;
}

export const ActionMenuSection = <T extends object>({
  section,
  state,
  onAction,
  onClose,
}: Props<T>) => {
  const { itemProps, groupProps } = useMenuSection({
    heading: section.rendered,
    'aria-label': section['aria-label'],
  });

  const { separatorProps } = useSeparator({
    elementType: 'li',
  });

  return (
    <>
      {section.key !== state.collection.getFirstKey() && (
        <li
          {...separatorProps}
          className='mx-2 mb-1 mt-1 border-t border-gray-300'
        />
      )}
      <li {...itemProps}>
        <ul {...groupProps}>
          {[...section.childNodes].map(node => (
            <ActionMenuItem
              key={node.key}
              item={node}
              state={state}
              onAction={onAction}
              onClose={onClose}
            />
          ))}
        </ul>
      </li>
    </>
  );
};
