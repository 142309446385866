import { Loading } from '@boostpoint/ui';
import { useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { useAuth } from '../providers/AuthProvider';
import { authenticatedRouter } from './components/routes/AuthenticatedRoutes';
import { unauthenticatedRouter } from './components/routes/UnauthenticatedRoutes';

const Routes = () => {
  const { currentUser, logOut, authLoading } = useAuth();

  const [router, setRouter] = useState(unauthenticatedRouter);

  useEffect(() => {
    if (currentUser) {
      setRouter(authenticatedRouter(logOut));
    } else {
      setRouter(unauthenticatedRouter);
    }
  }, [currentUser]);

  if (authLoading) return <Loading loading={true} />;
  return <RouterProvider router={router} />;
};

export default Routes;
