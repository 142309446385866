import {
  CompanyInvitationBoInterface,
  InvitationBoInterface,
} from '@boostpoint/types';
import { createContext, useContext } from 'react';

export const InvitationContext = createContext<any>(null);

export const useInvitation = () => {
  const invitation = useContext<{
    revokeInvitation: (id: string) => Promise<InvitationBoInterface>;
    fetchSentInvitations: () => Promise<InvitationBoInterface[]>;
    fetchInvitationById: (id: string) => Promise<CompanyInvitationBoInterface>;
    fetchInvitationsByEmail: (
      email: string,
    ) => Promise<CompanyInvitationBoInterface[]>;
    acceptInvitation: (
      invitationId: string,
      reassignProjects: boolean,
    ) => Promise<InvitationBoInterface>;
    declineInvitation: (invitationId: string) => Promise<InvitationBoInterface>;
    companyInvitations: CompanyInvitationBoInterface[];
  }>(InvitationContext);

  if (!invitation) {
    throw new Error(
      `You must call useInvitation() inside of an <InvitationProvider />`,
    );
  }

  return invitation;
};
