var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getSchemaUtils } from "../schema-utils";
export function lazy(getter) {
    const baseSchema = constructLazyBaseSchema(getter);
    return Object.assign(Object.assign({}, baseSchema), getSchemaUtils(baseSchema));
}
export function constructLazyBaseSchema(getter) {
    return {
        parse: (raw, opts) => __awaiter(this, void 0, void 0, function* () { return (yield getMemoizedSchema(getter)).parse(raw, opts); }),
        json: (parsed, opts) => __awaiter(this, void 0, void 0, function* () { return (yield getMemoizedSchema(getter)).json(parsed, opts); }),
        getType: () => __awaiter(this, void 0, void 0, function* () { return (yield getMemoizedSchema(getter)).getType(); }),
    };
}
export function getMemoizedSchema(getter) {
    return __awaiter(this, void 0, void 0, function* () {
        const castedGetter = getter;
        if (castedGetter.__zurg_memoized == null) {
            castedGetter.__zurg_memoized = yield getter();
        }
        return castedGetter.__zurg_memoized;
    });
}
