import {
  ActionJobBoInterface,
  ProjectBoInterface,
  ProjectUpsertDtoInterface,
  RewriteJobBoInterface,
} from '@boostpoint/types';
import { createContext, useContext } from 'react';

export const ProjectContext = createContext<any>(null);

export const useProjects = () => {
  const project = useContext<{
    refreshProjects: () => void;
    activateProject: (id: string) => Promise<boolean | undefined>;
    createProject: (
      project: ProjectUpsertDtoInterface,
    ) => Promise<ProjectBoInterface | undefined>;
    updateProject: (
      id: string,
      project: ProjectUpsertDtoInterface,
    ) => Promise<ProjectBoInterface | undefined>;
    archiveProject: (id: string) => Promise<boolean | undefined>;
    getActionJobs: (id: string) => Promise<ActionJobBoInterface[]>;
    getRewriteJobs: (id: string) => Promise<RewriteJobBoInterface[]>;
    projects: ProjectBoInterface[];
    isLoading: boolean;
  }>(ProjectContext);
  if (!project) {
    throw new Error(
      `You must call useProject() inside of an <ProjectProvider />`,
    );
  }
  return project;
};
