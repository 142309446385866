import { useRef } from 'react';
import { mergeProps, useFocusRing, useTableColumnHeader } from 'react-aria';
import type { TableState } from 'react-stately';
import type { GridNode } from '@react-types/grid';

export const ColumnHeader = (props: {
  column: GridNode<unknown>;
  state: TableState<unknown>;
}) => {
  const { column, state } = props;
  const ref = useRef(null);
  const { columnHeaderProps } = useTableColumnHeader(
    { node: column },
    state,
    ref,
  );
  const { isFocusVisible, focusProps } = useFocusRing();
  const arrowIcon = state.sortDescriptor?.direction === 'ascending' ? '▲' : '▼';

  return (
    <th
      {...mergeProps(columnHeaderProps, focusProps)}
      className={`border-b-[1px] border-bp-gray px-4 py-8 font-semibold text-bp-indigo ${
        isFocusVisible ? 'shadow-[0_0_0_2px] shadow-bp-purple' : ''
      }`}
      colSpan={column.colspan}
      style={{
        textAlign: column.colspan && column.colspan > 1 ? 'center' : 'left',
        outline: 'none',
        cursor: 'default',
      }}
      ref={ref}
    >
      {column.rendered}
      {column.props.allowsSorting && (
        <span
          aria-hidden='true'
          style={{
            padding: '0 2px',
            visibility:
              state.sortDescriptor?.column === column.key
                ? 'visible'
                : 'hidden',
          }}
        >
          {arrowIcon}
        </span>
      )}
    </th>
  );
};
