import clsx from 'clsx';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  justifyRight?: boolean;
}

export const ModalButtons = ({ children, justifyRight }: Props) => {
  return (
    <div className={clsx('mt-6 flex gap-2', [justifyRight && 'justify-end'])}>
      {children}
    </div>
  );
};
