import clsx from 'clsx';
import { useRef } from 'react';
import { AriaMenuProps, useMenuTrigger } from 'react-aria';
import { MenuTriggerProps, useMenuTriggerState } from 'react-stately';
import { ActionMenu, Button, Popover } from '.';

interface Props<T extends object> extends AriaMenuProps<T>, MenuTriggerProps {
  isDisabled?: boolean;
  isLoading?: boolean;
  buttonId?: string;
  popoverId?: string;
}

export const ActionMenuButton = <T extends object>(props: Props<T>) => {
  const { isDisabled, isLoading, buttonId, popoverId, id } = props;

  // Create state based on the incoming props
  const state = useMenuTriggerState(props);

  // Get props for the menu trigger and menu elements
  const ref = useRef(null);
  const { menuTriggerProps, menuProps } = useMenuTrigger<T>({}, state, ref);

  return (
    <div
      style={{ position: 'relative', display: 'inline-block' }}
      id={id}
    >
      {/* Button */}
      <Button
        {...menuTriggerProps}
        id={buttonId}
        buttonRef={ref}
        className={clsx('flex items-center justify-center gap-1 p-2')}
        isLoading={isLoading}
        isDisabled={isDisabled}
      >
        <span
          className={clsx(
            'h-[6px] w-[6px] rounded-full',
            isDisabled && 'bg-bp-gray',
            !isDisabled && state.isOpen && 'bg-bp-purple',
            !isDisabled && !state.isOpen && 'bg-bp-pink',
          )}
        ></span>
        <span
          className={clsx(
            'h-[6px] w-[6px] rounded-full',
            isDisabled && 'bg-bp-gray',
            !isDisabled && state.isOpen && 'bg-bp-purple',
            !isDisabled && !state.isOpen && 'bg-bp-pink',
            // state.isOpen ? 'bg-bp-purple' : 'bg-bp-pink',
            // isDisabled && 'bg-bp-gray',
          )}
        ></span>
        <span
          className={clsx(
            'h-[6px] w-[6px] rounded-full',
            isDisabled && 'bg-bp-gray',
            !isDisabled && state.isOpen && 'bg-bp-purple',
            !isDisabled && !state.isOpen && 'bg-bp-pink',
          )}
        ></span>
      </Button>

      {/* Menu */}
      {state.isOpen && (
        <Popover
          state={state}
          triggerRef={ref}
          placement='bottom end'
        >
          <ActionMenu
            {...menuProps}
            {...props}
            id={popoverId}
            autoFocus={state.focusStrategy || true}
            onClose={() => state.close()}
          />
        </Popover>
      )}
    </div>
  );
};
