import { AiOutlineHeart } from 'react-icons/ai';
import { BiComment, BiExport, BiRepost } from 'react-icons/bi';
import { FiBarChart } from 'react-icons/fi';
import { ActionPreviewCard } from '../ActionPreviewCard';

interface Props {
  companyName?: string;
  result: any;
}

export const OrganicTwitterPreview = (props: Props) => {
  const { companyName } = props;
  const { post, hashtags } = props.result as {
    post?: string;
    hashtags?: string[];
  };
  return (
    <ActionPreviewCard>
      <div className='flex h-full flex-row gap-2 overflow-y-scroll p-8 md:w-96'>
        <div className='flex flex-col'>
          <div className='bg-sidebar h-10 w-10 rounded-full bg-bp-gray bg-cover bg-center' />
        </div>
        <div className='flex flex-col gap-2'>
          <div className='flex flex-col'>
            <h3 className='text-sm text-gray-400'>
              <b>{companyName}</b> @{companyName?.toLowerCase()} |{' '}
              {new Date().toLocaleString('default', { month: 'long' })}{' '}
              {new Date().getDate()}{' '}
            </h3>
            <p className='text-left text-sm'>{post}</p>
            <div className='flex flex-wrap overflow-hidden '>
              {hashtags?.map(tag => (
                <p className='mr-1 text-sm text-bp-indigo'>{tag}</p>
              ))}
            </div>
          </div>
          <div className='flex flex-row justify-between'>
            <p className='flex flex-row items-center gap-1 text-sm text-gray-500'>
              <BiComment /> 499
            </p>
            <p className='flex flex-row items-center gap-1 text-sm text-gray-500'>
              <BiRepost /> 132
            </p>
            <p className='flex flex-row items-center gap-1 text-sm text-gray-500'>
              <AiOutlineHeart /> 1,924
            </p>
            <p className='flex flex-row items-center gap-1 text-sm text-gray-500'>
              <FiBarChart /> 671.4K
            </p>
            <p className='flex flex-row items-center gap-1 text-sm text-gray-500'>
              <BiExport />
            </p>
          </div>
        </div>
      </div>
    </ActionPreviewCard>
  );
};
