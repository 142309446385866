import clsx from 'clsx';
import { useRef } from 'react';
import { mergeProps, useFocusRing, useOption } from 'react-aria';
import { ListState, Node } from 'react-stately';

interface Props<T> {
  item: Node<T>;
  state: ListState<T>;
}

export const Option = <T extends object>(props: Props<T>) => {
  const { item, state } = props;

  // Get props for the option element
  const ref = useRef(null);
  const { optionProps, isSelected, isDisabled } = useOption(
    { key: item.key },
    state,
    ref,
  );

  // Determine whether we should show a keyboard
  // focus ring for accessibility
  const { isFocusVisible, focusProps } = useFocusRing();

  return (
    <li
      {...mergeProps(optionProps, focusProps)}
      ref={ref}
      className={clsx(
        'w-full px-6 py-4 font-medium hover:cursor-pointer hover:bg-bp-gray98 hover:text-bp-purple',
        isSelected && ['bg-bp-lGray text-bp-purple'],
        !isSelected && [
          'bg-transparent',
          isDisabled ? 'text-bp-gray98' : 'text-bp-black',
        ],
        isFocusVisible ? 'border-1 outline-bp-purple' : 'outline-none',
      )}
    >
      {item.rendered}
    </li>
  );
};
