import UpgradeCTAModal from './UpgradeCTAModal';
import { useEffect, useState } from 'react';
import PlanSelect from './PlanSelect';
import Invite from './Invite';
import UpgradeCTAUsersModal from './UpgradeCTAUsersModal';
import { useSubscription } from '../providers/SubscriptionProvider/context';
import { useCompany } from '../providers/CompanyProvider';
import CheckoutCanceled from './CheckoutCanceled';
import { useInvitation } from '../providers/InvitationProvider';
import {
  BulkCompanyMemberUpsertResponseBoInterface,
  InvitationBoInterface,
  InvitationStatus,
} from '@boostpoint/types';

const Subscription = (props: Props) => {
  const {
    startingPage,
    close,
    projectCount = 0,
    freemiumProjectCount = 0,
  } = props;
  const { getCheckout } = useSubscription();
  const { updateMembers } = useCompany();
  const { fetchSentInvitations } = useInvitation();
  const [invitations, setInvitations] = useState<InvitationBoInterface[]>([]);
  const [dialogPage, setDialogPage] = useState('upgradeCtaProject');
  const [selectedPlan, setSelectedPlan] = useState('');
  const [planValidation, setPlanValidation] = useState('');
  const [users, setUsers] = useState<string[]>(['']);
  const [loading, setLoading] = useState(false);
  const [updateMembersResponse, setUpdateMembersResponse] =
    useState<BulkCompanyMemberUpsertResponseBoInterface | null>(null);

  useEffect(() => {
    if (startingPage) setDialogPage(startingPage);
    if (startingPage === 'canceled') {
      (async () => {
        const invitationsResponse = await fetchSentInvitations();
        setInvitations(invitationsResponse);
      })();
    }
  }, [startingPage]);

  const closePlanSelect = async (plan?: string) => {
    if (!selectedPlan && !plan) {
      setPlanValidation('Please select a plan');
      return;
    }
    if (selectedPlan === 'singleUser' || plan === 'singleUser') {
      handleCheckout(1);
      return;
    }
    setDialogPage('invite');
  };

  const continueCheckout = async () => {
    const invitationsToAdd = invitations.filter(
      i => i.status === InvitationStatus.Pending,
    );
    console.log('invitationsToAdd', invitationsToAdd);
    if (invitationsToAdd.length > 0) {
      setUsers(invitationsToAdd.map(i => i.email));
      closePlanSelect('team');
    } else {
      closePlanSelect('singleUser');
    }
  };

  const handleCheckout = async (quantity?: number) => {
    setLoading(true);
    const checkout = await getCheckout({ quantity: quantity ? quantity : 1 });
    if (checkout.url) {
      window.location.replace(checkout.url);
    }
  };

  const cancel = () => {
    setSelectedPlan('');
    setPlanValidation('');
    setDialogPage(startingPage ? startingPage : 'upgradeCtaProject');
    close();
  };

  const onConfirm = async () => {
    const emails = users.filter(u => u !== '');
    const response = await updateMembers({ invitationsToAdd: emails });

    setUpdateMembersResponse(response);
    console.log('response', response);
    // If there was an error, return early and do not handle checkout.
    if (
      response.membersResult?.hasErrors ||
      response.invitationsToAddResult?.hasErrors ||
      response.invitationsToRemoveResult?.hasErrors
    ) {
      return;
    }

    handleCheckout(response.members.length + response.invitations.length);
  };

  const usersContinue = () => {
    setDialogPage('planSelect');
    setSelectedPlan('team');
  };

  return (
    <div className='w-full'>
      {dialogPage === 'upgradeCtaProject' && (
        <UpgradeCTAModal
          close={cancel}
          onContinue={() => setDialogPage('planSelect')}
        />
      )}
      {dialogPage === 'upgradeCtaUsers' && (
        <UpgradeCTAUsersModal
          close={cancel}
          onContinue={usersContinue}
          atLimit={freemiumProjectCount - projectCount <= 0}
        />
      )}
      {dialogPage === 'planSelect' && (
        <PlanSelect
          selectedPlan={selectedPlan}
          selectPlan={setSelectedPlan}
          close={cancel}
          next={closePlanSelect}
          planValidation={planValidation}
          loading={loading}
        />
      )}
      {dialogPage === 'invite' && (
        <Invite
          users={users}
          setUsers={setUsers}
          close={() => setDialogPage('planSelect')}
          next={onConfirm}
          loading={loading}
          result={updateMembersResponse?.invitationsToAddResult}
        />
      )}
      {dialogPage === 'canceled' && (
        <CheckoutCanceled
          onContinue={continueCheckout}
          close={cancel}
        />
      )}
    </div>
  );
};
type Props = {
  close: () => void;
  startingPage?: string;
  projectCount?: number;
  freemiumProjectCount?: number;
};

export default Subscription;
