import { ActionPreviewCard } from '../ActionPreviewCard';

interface Props {
  result: any;
}

export const ScheduleVoicemailPreview = (props: Props) => {
  const { script } = props.result as {
    script?: string;
  };
  return (
    <ActionPreviewCard>
      <div className='relative flex h-full max-h-[40rem] flex-col gap-1 overflow-y-scroll p-8 md:w-96'>
        <p className='whitespace-pre-wrap text-left text-sm'>
          {script?.toString()}
        </p>
      </div>
    </ActionPreviewCard>
  );
};
