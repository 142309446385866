import { PrimaryButton, SecondaryButton } from '@boostpoint/ui';
import iconsBeta from '../assets/iconsBeta';

const PlanSelect = (props: Props) => {
  const { selectedPlan, selectPlan, close, next, planValidation, loading } =
    props;
  return (
    <div className='w-full'>
      <h2 className='mt-8 text-[24px] font-semibold md:text-[32px]'>
        Choose your subscription type
      </h2>
      <div className='my-4 mt-4 flex w-full gap-4'>
        <button
          className={`flex w-full cursor-pointer flex-col rounded-2xl border-2 border-solid bg-white py-[16px] pl-[42px] pr-[20px] ${
            selectedPlan === 'singleUser' ? 'border-bp-green' : 'border-bp-gray'
          }`}
          role='button'
          onClick={() => selectPlan('singleUser')}
        >
          <div className='flex w-full flex-row justify-between'>
            <div className='my-4 flex items-end justify-end gap-2'>
              <img
                src={iconsBeta.SingleUserPlan}
                alt='single user plan'
                className='h-[30px] w-[30px]'
              />
              <p className='text-left text-[20px] font-semibold'>
                Single User Plan
              </p>
            </div>
            <div className='flex items-start justify-start'>
              <div className='mr-[4px] mt-[4px]'>
                <div
                  className={`rounded-full ${
                    selectedPlan === 'singleUser'
                      ? 'bg-bp-green before:outline-bp-green'
                      : 'bp-transparent before:outline-bp-gray'
                  } h-[12px] w-[12px] before:block before:h-[12px] before:w-[12px] before:rounded-full before:outline before:outline-2 before:outline-offset-2 before:content-['']`}
                />
              </div>
            </div>
          </div>
        </button>
        <button
          className={`flex w-full cursor-pointer flex-col rounded-2xl border-2 border-solid bg-white py-[16px] pl-[42px] pr-[20px] ${
            selectedPlan === 'team' ? 'border-bp-green' : 'border-bp-gray'
          }`}
          role='button'
          onClick={() => selectPlan('team')}
        >
          <div className='flex w-full flex-row justify-between'>
            <div className='my-4 flex items-end justify-end gap-2'>
              <img
                src={iconsBeta.TeamPlan}
                alt='single user plan'
                className='h-[30px] w-[30px]'
              />
              <p className='text-left text-[20px] font-semibold'>Team Plan</p>
            </div>
            <div className='flex items-start justify-start'>
              <div className='mr-[4px] mt-[4px]'>
                <div
                  className={`rounded-full ${
                    selectedPlan === 'team'
                      ? 'bg-bp-green before:outline-bp-green'
                      : 'bp-transparent before:outline-bp-gray'
                  } h-[12px] w-[12px] before:block before:h-[12px] before:w-[12px] before:rounded-full before:outline before:outline-2 before:outline-offset-2 before:content-['']`}
                />
              </div>
            </div>
          </div>
        </button>
      </div>
      {!!planValidation && (
        <p className='text-xs text-bp-pink md:text-sm'>{planValidation}</p>
      )}
      <div className='mt-12 flex w-full gap-2'>
        <SecondaryButton onPress={close}>Cancel</SecondaryButton>
        <PrimaryButton
          onPress={next}
          isLoading={loading}
          isDisabled={!selectedPlan}
        >
          {selectedPlan === 'singleUser' ? 'Confirm' : 'Next'}
        </PrimaryButton>
      </div>
    </div>
  );
};

type Props = {
  selectedPlan: string;
  selectPlan: (item: string) => void;
  close: () => void;
  next: () => void;
  planValidation?: string;
  loading?: boolean;
};

export default PlanSelect;
