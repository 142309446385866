import {
  CompanyInvitationBoInterface,
  InvitationBoInterface,
  InvitationStatus,
} from '@boostpoint/types';
import React, { useEffect, useState } from 'react';
import { useApi } from '../ApiProvider';
import { useCompany } from '../CompanyProvider';
import { useUser } from '../UserProvider';
import { InvitationContext } from './context';

const InvitationProvider = (props: { children: React.ReactNode }) => {
  const { children } = props;
  const { client } = useApi();
  const { user } = useUser();
  const { company } = useCompany();

  const [companyInvitations, setCompanyInvitations] = useState<
    CompanyInvitationBoInterface[]
  >([]);

  const fetchInvitationById = async (
    id: string,
  ): Promise<CompanyInvitationBoInterface | null> => {
    try {
      const companyInvitation = await client.invitation.findById(id);

      if (
        companyInvitation &&
        companyInvitation?.invitation?.status === InvitationStatus.Sent &&
        companyInvitation?.invitation?.email === user?.email
      ) {
        setCompanyInvitations([companyInvitation]);
      }

      return companyInvitation;
    } catch (e: any) {
      if (e.response?.status === 404) {
        setCompanyInvitations([]);
        return null;
      }

      console.error(e);
      throw e;
    }
  };

  const fetchInvitationsByEmail = async (
    email: string,
  ): Promise<CompanyInvitationBoInterface[]> => {
    try {
      const companyInvitations = await client.invitation.findByEmail(email);

      if (companyInvitations) {
        setCompanyInvitations(companyInvitations);
      }

      return companyInvitations;
    } catch (e: any) {
      if (e.statusCode === 404) {
        setCompanyInvitations([]);
        return [];
      }

      console.error(e);
      throw e;
    }
  };

  const fetchSentInvitations = async (): Promise<InvitationBoInterface[]> => {
    try {
      return await client.invitation.findByQuery({
        companyId: company?.id,
        statuses: [InvitationStatus.Sent, InvitationStatus.Pending],
      });
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  const acceptInvitation = async (
    invitationId: string,
    reassignProjects: boolean,
  ): Promise<InvitationBoInterface> => {
    try {
      const companyInvitation = companyInvitations.find(
        companyInvitation => companyInvitation.invitation.id === invitationId,
      );

      if (!companyInvitation?.invitation?.id) {
        throw new Error('No invitation to accept.');
      }

      const response = await client.invitation.acceptInvitation(
        companyInvitation.invitation.id,
        { reassignProjects },
      );
      if (response) {
        setCompanyInvitations(
          companyInvitations.filter(
            invitation => invitation.invitation.id !== invitationId,
          ),
        );
      }

      return response;
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  const declineInvitation = async (
    invitationId: string,
  ): Promise<InvitationBoInterface> => {
    const companyInvitation = companyInvitations.find(
      companyInvitation => companyInvitation.invitation.id === invitationId,
    );

    try {
      if (!companyInvitation?.invitation?.id) {
        throw new Error('No invitation to decline.');
      }

      const response = await client.invitation.declineInvitation(
        companyInvitation.invitation.id,
      );
      if (response) {
        setCompanyInvitations(
          companyInvitations.filter(
            invitation => invitation.invitation.id !== invitationId,
          ),
        );
      }

      return response;
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  useEffect(() => {
    if (user?.email) {
      fetchInvitationsByEmail(user.email);
    } else {
      setCompanyInvitations([]);
    }
  }, [user]);

  const wrapped = {
    fetchSentInvitations,
    fetchInvitationById,
    fetchInvitationsByEmail,
    acceptInvitation,
    declineInvitation,
    companyInvitations,
  };

  return (
    <InvitationContext.Provider value={wrapped}>
      {children}
    </InvitationContext.Provider>
  );
};
export default InvitationProvider;
