import { useEffect, useRef, useState } from 'react';
import { AriaSearchFieldProps, useSearchField } from 'react-aria';
import { useSearchFieldState } from 'react-stately';
import { Button } from '.';
import { BiSearch, BiXCircle } from 'react-icons/bi';

interface Props extends AriaSearchFieldProps {
  isOpen?: boolean;
  allowToggle?: boolean;
  allowClear?: boolean;
  onToggle?(isOpen: boolean): void;
}

export const SearchField = (props: Props) => {
  const {
    isOpen = false,
    allowToggle = true,
    allowClear = true,
    label,
    onToggle,
    onClear,
  } = props;

  const [_isOpen, setIsOpen] = useState(isOpen);

  const state = useSearchFieldState(props);
  const ref = useRef<HTMLInputElement>(null);
  const { labelProps, inputProps } = useSearchField(props, state, ref);

  const handleToggle = () => {
    if (allowToggle) {
      setIsOpen(!_isOpen);
      onToggle && onToggle(!_isOpen);
    }
  };

  const handleClear = () => {
    if (allowClear) {
      onClear && onClear();
    }
  };

  useEffect(() => {
    if (_isOpen) {
      ref.current?.focus();
    }
  }, [_isOpen]);

  return (
    <>
      {_isOpen ? (
        <div className='min-w-64 flex flex-col'>
          <label {...labelProps}>{label}</label>
          <div className='flex rounded-full bg-bp-lGray px-4 py-2'>
            <input
              {...inputProps}
              ref={ref}
              className='flex-1 appearance-none border-none bg-transparent text-sm outline-none'
            />

            {(!allowClear || !state.value) && (
              <Button
                className='text-sm text-bp-indigo ring-transparent'
                onPress={handleToggle}
              >
                <BiSearch className='stroke-[.25]' />
              </Button>
            )}

            {/* TODO: There seems to be a minor bug with this on clearing mobile input. Seems like event handler is getting lost or something. Circle back when time permits. Not necessary for mobile anyway. */}
            {allowClear && state.value && (
              <Button
                className='text-sm text-bp-indigo ring-transparent'
                onPress={handleClear}
              >
                <BiXCircle className='stroke-[.25]' />
              </Button>
            )}
          </div>
        </div>
      ) : (
        <Button
          onPress={handleToggle}
          className='bg-bp-lGray p-2 text-sm ring-transparent'
        >
          <BiSearch className='stroke-[.25]' />
        </Button>
      )}
    </>
  );
};
