import {
  BulkCompanyMemberUpsertResponseBoInterface,
  CompanyMemberBoInterface,
  InvitationBoInterface,
  MemberRole,
  MemberStatus,
} from '@boostpoint/types';
import {
  ActionMenuButton,
  PrimaryButton,
  SecondaryButton,
  Table,
  TertiaryButton,
  TextButton,
  TextField,
} from '@boostpoint/ui';
import clsx from 'clsx';
import React, { Key, useCallback, useEffect, useState } from 'react';
import { HiStar } from 'react-icons/hi';
import {
  Cell,
  Column,
  Item,
  Row,
  Section,
  TableBody,
  TableHeader,
} from 'react-stately';
import { useCompany } from '../providers/CompanyProvider';
import { useInvitation } from '../providers/InvitationProvider';
import { useUser } from '../providers/UserProvider';
import AuthorizedPage from '../routes/components/AuthorizedPage';
import { useDialog } from '../providers/DialogProvider';
import Subscription from '../subscription/Subscription';
import UpdatingSubscription from './UpdatingSubscription';
import { useSubscription } from '../providers/SubscriptionProvider/context';
import { useNavigate } from 'react-router-dom';
import { useProjects } from '../providers/ProjectProvider';
import { useApi } from '../providers/ApiProvider';

const CompanyMembers = () => {
  const { user } = useUser();
  const { client } = useApi();
  const { company, fetchMembers, changeRole, hasRole } = useCompany();
  const { setDialog, setDialogOpen } = useDialog();
  const { subscription, isLoading } = useSubscription();
  const navigate = useNavigate();
  const [isMemberActionInProgress, setIsMemberActionInProgress] =
    useState(false);
  const [members, setMembers] = useState<CompanyMemberBoInterface[]>([]);
  const [invitations, setInvitations] = useState<InvitationBoInterface[]>([]);
  const [editingUsers, setEditingUsers] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [addedUsers, setAddedUsers] = useState<string[]>([]);
  const [removedUsers, setRemovedUsers] = useState<string[]>([]); // user Ids
  const [invitationsToRemove, setInvitationsToRemove] = useState<string[]>([]); // invitation ids
  const [results, setResults] =
    useState<BulkCompanyMemberUpsertResponseBoInterface | null>(null);
  const [showNewUser, setShowNewUser] = useState(false);
  const { fetchSentInvitations } = useInvitation();
  const { projects } = useProjects();

  const emailErrors = (email: string, status: string) => {
    const errors = [];

    const toAddItem = results?.invitationsToAddResult?.items?.find(
      item => item?.data === email,
    );

    if (toAddItem && status === 'Pending invitation') {
      errors.push(...toAddItem.errors);
    }

    const toRemoveItem = results?.invitationsToRemoveResult?.items?.find(
      item => item?.data === email,
    );

    if (toRemoveItem && status === 'Pending removal') {
      errors.push(...toRemoveItem.errors);
    }

    return errors.join('\n');
  };

  const refetchMembers = async () => {
    if (company?.id) {
      const members = await fetchMembers();
      setMembers(members);
    }
  };

  const refetchInvitations = async () => {
    const invitations = await fetchSentInvitations();
    setInvitations(invitations);
  };

  useEffect(() => {
    refetchMembers();
    refetchInvitations();
  }, [company]);

  useEffect(() => {
    if (
      !isLoading &&
      !subscription?.subscriptionExists &&
      !subscription?.isActive
    ) {
      openSubscriptionDialog('upgradeCtaUsers');
    } else if (!isMemberActionInProgress || !editingUsers) {
      console.log('closing dialog');
      setDialogOpen(false);
    }
  }, [subscription, isLoading]);

  const handleRevokeInvitation = async (invitationId: string) => {
    setInvitationsToRemove(prev => [...prev, invitationId]);
    setEditingUsers(true);
  };

  const handleAction = async (key: Key, id: string) => {
    // TODO: Perhaps add confirmation dialog?
    switch (key) {
      case 'remove':
        await handleRemoveUser(id);
        break;
      case 'make_admin':
        await handleMakeAdmin(id);
        break;
      case 'remove_admin':
        await handleRemoveAdmin(id);
        break;
      case 'revoke':
        await handleRevokeInvitation(id);
        break;
    }
  };

  const handleRemoveUser = async (memberId: string) => {
    setEditingUsers(true);
    setRemovedUsers(prev => [...prev, memberId]);
  };

  const handleMakeAdmin = async (memberId: string) => {
    setIsMemberActionInProgress(true);

    try {
      await changeRole(memberId, MemberRole.Admin);
      await refetchMembers();
    } catch (e: any) {
      console.error(e);
    }

    setIsMemberActionInProgress(false);
  };

  const handleRemoveAdmin = async (memberId: string) => {
    setIsMemberActionInProgress(true);

    try {
      await changeRole(memberId, MemberRole.Member);
      await refetchMembers();
    } catch (e: any) {
      console.error(e);
    }

    setIsMemberActionInProgress(false);
  };

  type MemberRow = {
    id: string;
    name: string;
    email: string;
    role: MemberRole;
    status: string;
    userId?: string;
    invitationId?: string;
  };

  const actionItems = useCallback((member: MemberRow) => {
    // It's you!
    if (member.userId && user?.id === member.userId) {
      return [];
    }

    if (member.status === 'ACTIVE') {
      return [
        hasRole(MemberRole.Admin) &&
          member.role === MemberRole.Member && {
            key: 'make_admin',
            label: 'Make Admin',
          },
        hasRole(MemberRole.Admin) &&
          member.role === MemberRole.Admin && {
            key: 'remove_admin',
            label: 'Remove Admin',
          },
        // Owners can disable admins. Admins can disable members.
        ((hasRole(MemberRole.Owner) && member.role === MemberRole.Admin) ||
          (hasRole(MemberRole.Admin) && member.role === MemberRole.Member)) && {
          key: 'remove',
          label: 'Remove',
        },
      ].filter(Boolean) as { key: string; label: string }[];
    } else if (member.status === 'DISABLED') {
      return [
        {
          key: 'reinvite',
          label: 'Invite',
        },
      ].filter(Boolean) as { key: string; label: string }[];
    } else if (
      member.status === 'Pending invitation' &&
      member.email === member.id
    ) {
      return [
        {
          key: 'undo',
          label: 'Undo',
        },
      ];
    } else if (
      member.status === 'INVITED' ||
      (member.status === 'Pending invitation' && member.email !== member.id)
    ) {
      return [
        {
          key: 'revoke',
          label: 'Revoke',
        },
      ].filter(Boolean) as { key: string; label: string }[];
    }

    return [];
  }, []);

  const invitedEmails = invitations.map(invitation => invitation.email);

  const tableData: MemberRow[] = [
    ...(showNewUser
      ? [
          {
            name: '',
            email: '',
            role: MemberRole.Member,
            status: '',
            id: 'new',
          },
        ]
      : []),
    ...members
      .filter(m => !invitedEmails.includes(m.email)) // Exclude 'Disabled' members that have been invited
      .map(member => ({
        name: member.name,
        email: member.email,
        role: member.role,
        status: removedUsers.includes(member.userId)
          ? 'Pending removal'
          : member.status === MemberStatus.Active
            ? 'ACTIVE'
            : 'DISABLED',
        id: member.userId,
        userId: member.userId,
      })),
    ...invitations.map(invitation => ({
      name: '',
      email: invitation.email,
      role: MemberRole.Member,
      status: invitationsToRemove.includes(invitation.id)
        ? 'Pending removal'
        : invitation.status === 'SENT'
          ? 'INVITED'
          : 'Pending invitation',
      id: invitation.id,
      invitationId: invitation.id,
    })),
    ...addedUsers.map(email => ({
      name: '',
      email,
      role: MemberRole.Member,
      status: 'Pending invitation',
      id: email,
    })),
  ];

  const removeUser = (memberId: string) => {
    const matchingMember = members.find(m => m.userId === memberId);
    if (matchingMember && removedUsers.includes(memberId)) return;
    if (matchingMember) {
      setRemovedUsers(prev => [...prev, memberId]);
    } else {
      const matchingInvitation = invitations.find(i => i.id === memberId);
      if (matchingInvitation && invitationsToRemove.includes(memberId)) return;
      setInvitationsToRemove(prev => [...prev, memberId]);
    }
  };

  const undoRemoveUser = (memberId: string) => {
    const matchingUser = members.find(m => m.userId === memberId);
    if (matchingUser) {
      console.log('matching', matchingUser);
      const newRemove = removedUsers.filter(e => e !== memberId);
      setRemovedUsers(newRemove);
      if (
        newRemove.length < 1 &&
        addedUsers.length < 1 &&
        invitationsToRemove.length < 1 &&
        !showNewUser &&
        !newEmail
      ) {
        setDialogOpen(false);
        setEditingUsers(false);
      }
    } else {
      const matchingInvitation = invitations.find(i => i.id === memberId);
      if (matchingInvitation) {
        const newRemove = invitationsToRemove.filter(e => e !== memberId);
        setInvitationsToRemove(newRemove);
        if (
          newRemove.length < 1 &&
          addedUsers.length < 1 &&
          invitationsToRemove.length < 1 &&
          !showNewUser &&
          !newEmail
        ) {
          setDialogOpen(false);
          setEditingUsers(false);
        }
      }
    }
  };

  const addUser = () => {
    setAddedUsers([...addedUsers, newEmail]);
    setNewEmail('');
  };

  const undoAddUser = (email: string) => {
    setAddedUsers(prev => prev.filter(e => e !== email));
  };

  const onEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (newEmail) addUser();
    }
  };

  const handleNewUserClick = () => {
    setEditingUsers(true);
    setShowNewUser(true);
  };

  const handleResultsUpdated = (
    results: BulkCompanyMemberUpsertResponseBoInterface,
  ) => {
    setResults(results);
  };

  const cancelEditing = (confirmed?: boolean) => {
    if (!confirmed && (addedUsers.length || removedUsers.length)) {
      setDialog(
        <div
          className={`block w-full flex-col items-start justify-between gap-12 xl:flex`}
        >
          <div>
            <h2 className='text-[24px] font-semibold md:text-[32px]'>
              Are you sure?
            </h2>
            <p className=''>
              Are you sure you want to discard the changes you made?
            </p>
          </div>
          <div className='mt-8 flex gap-4'>
            <SecondaryButton onPress={() => setDialogOpen(false)}>
              Go back
            </SecondaryButton>
            <TertiaryButton
              onPress={() => {
                cancelEditing(true);
              }}
            >
              Discard changes
            </TertiaryButton>
          </div>
        </div>,
      );
      setDialogOpen(true);
    } else {
      setDialogOpen(false);
      setEditingUsers(false);
      setShowNewUser(false);
      setNewEmail('');
      setAddedUsers([]);
      setRemovedUsers([]);
      setInvitationsToRemove([]);
      setResults(null);
    }
  };

  const previewChanges = async () => {
    const response: BulkCompanyMemberUpsertResponseBoInterface =
      await client?.company.updateMembers(company.id, {
        membersToRemove: removedUsers,
        invitationsToAdd: addedUsers,
        invitationsToRemove,
      });
    console.log('bulk update response', response);

    handleResultsUpdated(response);

    if (
      !response.membersResult?.hasErrors &&
      !response.invitationsToAddResult?.hasErrors &&
      !response.invitationsToRemoveResult?.hasErrors
    ) {
      setIsMemberActionInProgress(true);
      setDialog(
        <UpdatingSubscription
          memberCount={members.length + invitations.length}
          addedUsers={addedUsers}
          removedUsers={removedUsers}
          invitationsToRemove={invitationsToRemove}
          members={members}
          invitations={invitations}
          cancel={() => {
            setDialogOpen(false);
            setIsMemberActionInProgress(false);
          }}
          close={handleSubscriptionUpdated}
          onResultsUpdated={handleResultsUpdated}
        />,
      );
      setDialogOpen(true);
    }
  };

  const handleSubscriptionUpdated = (confirmed?: boolean) => {
    cancelEditing(confirmed);
    refetchInvitations();
    refetchMembers();
    setAddedUsers([]);
    setRemovedUsers([]);
    setInvitationsToRemove([]);
    setIsMemberActionInProgress(false);
  };

  const openSubscriptionDialog = (page?: string) => {
    setDialog(
      <Subscription
        startingPage={page}
        close={closeSubscriptionDialog}
        projectCount={projects.length}
        freemiumProjectCount={company?.freemiumProjectCount ?? 8}
      />,
    );
    setDialogOpen(true);
  };

  const closeSubscriptionDialog = () => {
    setDialogOpen(false);
    if (!subscription?.subscriptionExists && !subscription?.isActive) {
      navigate('/settings/profile');
    }
  };

  return (
    <AuthorizedPage requiredRole={MemberRole.Admin}>
      <div className='mb-4 mt-8 flex w-full flex-row items-end justify-between'>
        <div className='mb-1'>
          <h2 className='text-[20px] font-semibold'>Users</h2>
          <p>Manage your team of users by adding/removing them below.</p>
        </div>
        <div className='mb-1 flex gap-12'>
          {subscription?.subscriptionExists && !subscription?.cancelAt && (
            <PrimaryButton
              onPress={handleNewUserClick}
              id='add-users-button'
            >
              + User
            </PrimaryButton>
          )}
        </div>
      </div>

      <Table aria-label='user list'>
        <TableHeader>
          <Column>Name</Column>
          <Column>Email</Column>
          <Column>Role</Column>
          <Column>Status</Column>
          <Column>{''}</Column>
        </TableHeader>
        <TableBody>
          {tableData.map(row => (
            <Row key={row.id}>
              <Cell>
                <div className='flex flex-row items-center gap-2'>
                  {(row.role === MemberRole.Admin ||
                    row.role === MemberRole.Owner) && (
                    <div className='flex h-4 w-4 items-center justify-center rounded-full bg-bp-pink'>
                      <HiStar className='h-3 w-3 text-white' />
                    </div>
                  )}
                  {row.name}
                </div>
              </Cell>
              <Cell>
                {editingUsers && !row.email ? (
                  <div>
                    <TextField
                      value={newEmail}
                      onChange={(val: string) => setNewEmail(val)}
                      onKeyDown={onEnter}
                      aria-label='email'
                      id='email-input'
                    />
                  </div>
                ) : (
                  <>
                    <span>{row.email}</span>
                    <div
                      className='pt-1 text-bp-pink'
                      id={`email-error-${row.id.replace(/[@+.]/g, '-')}`}
                    >
                      {emailErrors(row.email, row.status)}
                    </div>
                  </>
                )}
              </Cell>
              <Cell>
                {editingUsers && row.id === 'new'
                  ? ''
                  : row.role.charAt(0) + row.role.slice(1).toLowerCase()}
              </Cell>
              <Cell>
                <p
                  className={clsx(
                    [row.status === 'INVITED' && 'text-bp-yellow'],
                    [row.status === 'ACTIVE' && 'text-bp-green'],
                    [row.status === 'DISABLED' && 'text-bp-red'],
                    [row.status === 'Pending removal' && 'italic text-bp-red'],
                    [
                      row.status === 'Pending invitation' &&
                        'italic text-bp-yellow',
                    ],
                    [
                      row.status === 'Pending removal' &&
                        'italic text-bp-yellow',
                    ],
                  )}
                >
                  {row.status.charAt(0) + row.status.slice(1).toLowerCase()}
                </p>
              </Cell>
              <Cell>
                {!editingUsers ? (
                  <ActionMenuButton
                    onAction={key => handleAction(key, row.id)}
                    isLoading={isMemberActionInProgress}
                    isDisabled={actionItems(row).length === 0}
                    id={`member-action-button-${row.email.replace(/[@+.]/g, '-')}`}
                  >
                    <Section>
                      {actionItems(row).map(item => (
                        <Item
                          key={item.key}
                          textValue={item.label}
                        >
                          <p id={`member-action-${item.key}`}>{item.label}</p>
                        </Item>
                      ))}
                    </Section>
                  </ActionMenuButton>
                ) : row.status === 'Pending removal' ? (
                  <div className='flex justify-end'>
                    <TextButton onPress={() => undoRemoveUser(row.id)}>
                      Undo
                    </TextButton>
                  </div>
                ) : row.status === 'Pending invitation' &&
                  row.id === row.email ? (
                  <div className='flex justify-end'>
                    <TextButton onPress={() => undoAddUser(row.email)}>
                      Undo
                    </TextButton>
                  </div>
                ) : row.status === 'Pending invitation' &&
                  row.id !== row.email ? (
                  <div className='flex justify-end'>
                    <TextButton
                      onPress={() =>
                        setInvitationsToRemove([...invitationsToRemove, row.id])
                      }
                    >
                      Revoke
                    </TextButton>
                  </div>
                ) : row.id !== 'new' ? (
                  <div className='flex justify-end'>
                    <TextButton
                      className={
                        row.role === MemberRole.Owner ||
                        row.email === user?.email
                          ? ''
                          : `text-bp-red`
                      }
                      onPress={() => removeUser(row.id)}
                      isDisabled={
                        row.role === MemberRole.Owner ||
                        row.email === user?.email
                      }
                    >
                      Remove
                    </TextButton>
                  </div>
                ) : (
                  <div className='flex flex-row justify-end gap-3'>
                    <TextButton
                      onPress={addUser}
                      isDisabled={newEmail ? false : true}
                      id='add-user-button'
                    >
                      Add
                    </TextButton>
                    <TextButton
                      className={newEmail ? 'text-bp-red' : 'text-bp-gray'}
                      onPress={() => setNewEmail('')}
                      isDisabled={newEmail ? false : true}
                      id='cancel-add-user-button'
                    >
                      Cancel
                    </TextButton>
                  </div>
                )}
              </Cell>
            </Row>
          ))}
        </TableBody>
      </Table>

      {!!editingUsers && (
        <div className='flex flex-row gap-4'>
          <SecondaryButton onPress={() => cancelEditing()}>
            Cancel
          </SecondaryButton>
          <PrimaryButton
            onPress={previewChanges}
            id='save-users'
          >
            Save
          </PrimaryButton>
        </div>
      )}
    </AuthorizedPage>
  );
};

export default CompanyMembers;
