import {
  AiOutlineHeart,
  AiOutlineMessage,
  AiOutlineSend,
} from 'react-icons/ai';
import { BsBookmark } from 'react-icons/bs';
import { ActionPreviewCard } from '../ActionPreviewCard';

interface Props {
  companyName?: string;
  result: any;
}

export const OrganicInstagramPreview = (props: Props) => {
  const { companyName } = props;
  const { post, hashtags } = props.result as {
    post?: string;
    hashtags?: string[];
  };
  return (
    <ActionPreviewCard>
      <div className='flex h-full flex-col gap-3 overflow-y-scroll p-8 md:w-96'>
        <div className='flex flex-row justify-between'>
          <div className='flex flex-row gap-2'>
            <AiOutlineHeart className='text-2xl text-gray-400' />
            <AiOutlineMessage className='text-2xl text-gray-400' />
            <AiOutlineSend className='text-2xl text-gray-400' />
          </div>
          <BsBookmark className='text-2xl text-gray-400' />
        </div>
        <div className='flex flex-row items-center gap-2'>
          <div className='bg-sidebar h-4 w-4 rounded-full bg-bp-gray bg-cover bg-center' />
          <h3 className='text-sm text-gray-400'>
            liked by <b>{companyName}</b> and others
          </h3>
        </div>
        <p className='text-left text-sm'>{post}</p>
        <div className='flex flex-wrap'>
          {hashtags?.map(tag => (
            <p
              key={tag}
              className='mr-1 text-left text-sm text-bp-indigo'
            >
              {tag}
            </p>
          ))}
        </div>
      </div>
    </ActionPreviewCard>
  );
};
