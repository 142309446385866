import { createElement, type ReactNode } from 'react';
import { useTableRowGroup } from 'react-aria';

export const RowGroup = (props: {
  type: 'thead' | 'tbody';
  children: ReactNode;
}) => {
  const { type, children } = props;
  const { rowGroupProps } = useTableRowGroup();
  // react-aria table requires the type to be thead or tbody, so we are using the legacy createElement API here
  // https://react.dev/reference/react/createElement
  return createElement(
    type,
    {
      ...rowGroupProps,
      style:
        type === 'thead'
          ? { borderBottom: '2px solid var(--spectrum-global-color-gray-800)' }
          : null,
    },
    children,
  );
};
