import { TertiaryButton } from '@boostpoint/ui';
import { useApi } from '../providers/ApiProvider';

const SubscriptionInactiveOwner = () => {
  const { client } = useApi();

  const onUpdate = async () => {
    const url = await client.subscription.getCustomerPortal();
    if (url.url) {
      window.location.replace(url.url);
    }
  };

  return (
    <div className='w-full'>
      <div
        className={`block w-full flex-col items-end justify-between gap-8 xl:flex`}
      >
        <div>
          <h2 className='mt-8 text-[24px] font-semibold md:text-[32px]'>
            Update billing information to continue
          </h2>
          <p className='my-3'>
            Uh oh! It looks like something went wrong with your billing
            information.
          </p>
          <p className='my-3'>
            To continue letting CARL generate incredible recruitment content,{' '}
            <strong>update your information now!</strong>
          </p>
        </div>
        <div className='mt-4 flex w-full gap-2'>
          <TertiaryButton onPress={onUpdate}>Update Now</TertiaryButton>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionInactiveOwner;
