import { useEffect, useState } from 'react';
import { useApi } from '../providers/ApiProvider';

export interface Props {
  url?: string;
  debug?: boolean;
}

const useSseEvent = <T>(props: Props) => {
  const { url, debug = false } = props;

  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<Event | null>(null);

  const { environment } = useApi();

  useEffect(() => {
    // Something like http://localhost:6001/path/to/sse/events
    if (url) {
      const eventSource = new EventSource(`${environment}${url}`);

      eventSource.onmessage = messageEvent => {
        if (debug) {
          console.debug('SSE Message: ', messageEvent);
        }

        const data = JSON.parse(messageEvent.data) as T;

        setData(data);
        setError(null);
      };

      eventSource.onerror = error => {
        if (debug) {
          console.debug('SSE Error: ', error);
        }

        setError(error);
        setData(null);
      };

      return () => eventSource.close();
    }
  }, [url]);

  return { data, error };
};
export default useSseEvent;
