import {
  CompanyBoInterface,
  CompanyInvitationBoInterface,
  MemberRole,
  SubscriptionBoInterface,
  UserBoInterface,
} from '@boostpoint/types';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import Logo from '../assets/boostpoint-logo.png';
import CreateAi from '../assets/createai.svg';
import LogoInit from '../assets/darkLogo.svg';
import MobileBg from '../assets/menuBorderMobile.png';
import { CompanySlideout } from './CompanySlideout';

export const Header = (props: Props) => {
  const {
    register,
    company,
    user,
    companyMenu,
    invitations,
    subscription,
    setCompanyMenu,
    hasRole,
    getBillingPortal,
  } = props;

  return (
    <div
      className={clsx(
        'h-[90px] md:h-[110px]',
        'w-full',
        'bg-white bg-contain bg-top bg-no-repeat md:!bg-none',
        'border-b-solid border-b-[1px] border-b-[#EDEBEB]',
        'relative flex flex-row items-center justify-between',
        `z-10 p-[24px] md:p-0 md:py-[34px]`,
        !register
          ? 'md:justify-center md:pl-[345px]'
          : 'md:justify-start md:pl-[40px]',
      )}
      style={{
        backgroundImage: `url('${MobileBg}')`,
      }}
    >
      <div className={register ? 'hidden md:block' : 'hidden'}>
        <Link to='/'>
          <img
            src={Logo}
            alt='Boostpoint'
            className='w-[166px]'
            id='header-logo'
          />
        </Link>
      </div>
      <Link to='/'>
        <img
          src={LogoInit}
          alt='B'
          className='md:hidden'
        />
      </Link>
      <Link to='/'>
        <img
          src={CreateAi}
          className={register ? 'md:hidden' : 'md:mt-[26px]'}
          alt='Boostpoint logo'
        />
      </Link>
      <div
        className={`bottom-0 right-8 top-0 flex items-center justify-end md:absolute md:w-[166px] ${
          register && 'md:hidden'
        }`}
        id='header-profile-menu'
      >
        {company && setCompanyMenu ? (
          <span
            className='flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full border-[1px] border-solid border-bp-dkGray text-[20px] font-semibold md:h-[50px] md:w-[50px]'
            onClick={() => setCompanyMenu(!companyMenu)}
            id='settings-menu'
          >
            {`${user?.firstName.charAt(0).toUpperCase() || ''}${
              user?.lastName.charAt(0).toUpperCase() || ''
            }`}
          </span>
        ) : (
          <></>
        )}
      </div>
      {company && setCompanyMenu && companyMenu ? (
        <CompanySlideout
          setOpen={setCompanyMenu}
          open={companyMenu}
          company={company}
          subscription={subscription}
          user={user}
          hasRole={hasRole}
          invitations={invitations}
          notifications={[]}
          getBillingPortal={getBillingPortal}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

type Props = {
  register?: boolean;
  companyMenu?: boolean;
  setCompanyMenu?: (value: boolean) => void;
  company?: CompanyBoInterface;
  user?: UserBoInterface;
  subscription?: SubscriptionBoInterface;
  invitations?: CompanyInvitationBoInterface[];
  hasRole: (role: MemberRole) => boolean;
  getBillingPortal: () => Promise<string>;
};
