import {
  ActionBoInterface,
  ActionTag,
  ActionType,
  ActionTypeMetaInterface,
} from '@boostpoint/types';
import { useCallback, useMemo, useState } from 'react';
import { ActionCard } from '.';
import { ActionGroup } from './ActionGroup';
import { SearchField } from './SearchField';
import { TagFilterButton } from './TagFilterButton';

interface Props {
  actions?: ActionBoInterface[];
  persistedActionTypes: ActionType[]; //filter from list
  actionTypeMetaMap?: Map<ActionType, ActionTypeMetaInterface>;

  selectedActionTypes: ActionType[]; //change state
  setSelectedActionTypes: React.Dispatch<React.SetStateAction<ActionType[]>>;
}

export const AddProjectActions = (props: Props) => {
  const filterTags = [
    { label: 'All', value: ActionTag.All },
    { label: 'Social Media', value: ActionTag.SocialMedia },
    { label: 'Job Boards', value: ActionTag.JobBoards },
    { label: 'Messaging', value: ActionTag.Messaging },
    { label: 'Email', value: ActionTag.Email },
    { label: 'Scripts', value: ActionTag.Scripts },
  ];

  const {
    actions = [],
    persistedActionTypes = [],
    selectedActionTypes = [],
    actionTypeMetaMap,
    setSelectedActionTypes,
  } = props;

  const [selectedFilterTag, setSelectedFilterTag] = useState<ActionTag>(
    ActionTag.All,
  );
  const [filterText, setFilterText] = useState('');

  // Expand action BO to have meta info as well
  const addMeta = useCallback(
    (action: ActionBoInterface) => {
      return {
        action,
        meta: actionTypeMetaMap?.get(action.type),
      };
    },
    [actionTypeMetaMap],
  );

  const filteredActions = useMemo(() => {
    return (
      actions
        .map(addMeta)
        ?.filter(action => !persistedActionTypes.includes(action.action.type))
        // Apply text filter
        ?.filter(({ action, meta }) => {
          // Does filter match action name?
          if (action.name.toLowerCase().includes(filterText.toLowerCase())) {
            return true;
          }

          // Does filter match action description?
          if (
            action.description.toLowerCase().includes(filterText.toLowerCase())
          ) {
            return true;
          }

          // Does filter match action tag?
          if (meta?.tag.toLowerCase().includes(filterText.toLowerCase())) {
            return true;
          }

          // If not, then action does not match filter. We should filter it out.
          return false;
        })
        // Apply tag filter
        ?.filter(({ meta }) => {
          // If no tag filter is selected or we're filtering by ALL, then keep all results
          if (!selectedFilterTag || selectedFilterTag === ActionTag.All) {
            return true;
          }

          // Otherwise, check if action tag matches the selected tag to filter by
          return meta?.tag === selectedFilterTag;
        })
        .map(({ action }) => action) || []
    );
  }, [actions, addMeta, filterText, selectedFilterTag]);

  const filterActionsByTag = useCallback(
    (tag: ActionTag): ActionBoInterface[] => {
      return filteredActions
        .map(addMeta)
        .filter(({ meta }) => meta?.tag === tag)
        .map(({ action }) => action);
    },
    [addMeta, filteredActions],
  );

  const isSelected = useCallback(
    (action: ActionBoInterface) => {
      return selectedActionTypes?.includes(action.type);
    },
    [selectedActionTypes],
  );

  const handleTagFilterChanged = (value: ActionTag, isSelected: boolean) => {
    if (isSelected) {
      setSelectedFilterTag(value);
      return;
    }

    setSelectedFilterTag(ActionTag.All);
  };

  const handleActionClicked = (action: ActionBoInterface) => {
    // If action is already selected, deselect it
    if (isSelected(action)) {
      setSelectedActionTypes(
        selectedActionTypes.filter(a => a !== action.type),
      );
    } else {
      // Otherwise, add it to the selected action IDs
      setSelectedActionTypes([...selectedActionTypes, action.type]);
    }
  };

  return (
    <div className='flex flex-1 flex-col overflow-auto p-4 md:p-16'>
      <div className='flex flex-col md:flex-row md:items-center'>
        <h2 className='flex-1 text-xl font-semibold text-bp-black'>
          Add actions to your project.
        </h2>
        <div className='pt-2 md:hidden'>
          <SearchField
            value={filterText}
            onChange={setFilterText}
            onClear={() => {
              alert('Clearrr');
              setFilterText('');
            }}
            allowToggle={false}
            allowClear={false}
            isOpen
          />
        </div>
        <div className='hidden md:flex md:justify-self-end'>
          <SearchField
            value={filterText}
            onChange={setFilterText}
            onClear={() => setFilterText('')}
          />
        </div>
      </div>
      <div className='mt-6 flex justify-center'>
        <div className='flex flex-col md:w-5/6'>
          <div className='hidden md:flex md:justify-end md:gap-2'>
            {filterTags.map(tag => (
              <TagFilterButton
                key={tag.value}
                label={tag.label}
                isSelected={tag.value === selectedFilterTag}
                onChange={isSelected => {
                  handleTagFilterChanged(tag.value, isSelected);
                }}
              />
            ))}
          </div>

          {/* Loop over tags, but exclude first tag which is "ALL" so that we don't have a section for that */}
          {filterTags.slice(1).map(tag =>
            // If there are no actions for group based on applied filters, don't show group label
            filterActionsByTag(tag.value).length ? (
              // Otherwise, create group for tag and populate actions
              <ActionGroup
                key={tag.value}
                title={tag.label}
              >
                {filterActionsByTag(tag.value)
                  .map(addMeta)
                  .sort((a, b) => {
                    if (a.action.name > b.action.name) {
                      return 1;
                    } else {
                      return -1;
                    }
                  })
                  .map(({ action, meta }) => (
                    <ActionCard
                      key={action.id}
                      action={action}
                      img={meta?.img || ''}
                      selected={isSelected(action)}
                      onSelect={() => handleActionClicked(action)}
                    />
                  ))}
              </ActionGroup>
            ) : (
              ''
            ),
          )}
        </div>
      </div>
    </div>
  );
};
