import clsx from 'clsx';
import { AriaToggleButtonProps } from 'react-aria';
import { ToggleButton } from '.';

interface Props extends AriaToggleButtonProps {
  label?: string;
}

export const TagFilterButton = (props: Props) => {
  const { label, isSelected } = props;

  return (
    <ToggleButton
      {...props}
      className={clsx(
        'rounded-full border-2 border-bp-indigo px-2 py-1 text-xs font-medium text-bp-indigo',
        isSelected && 'bg-bp-indigo text-white',
      )}
    >
      {label}
    </ToggleButton>
  );
};
