import { ActionBoInterface } from '@boostpoint/types';
import { useApi } from '../providers/ApiProvider';
import { useEffect, useState } from 'react';

const useActions = () => {
  const [actions, setActions] = useState<ActionBoInterface[]>([]);
  const { client, ready } = useApi();

  useEffect(() => {
    (async () => {
      if (ready) {
        try {
          const actionsResponse = await client.action.getAll();
          setActions(actionsResponse);
        } catch (err) {
          let message = 'Unknown error';
          if (err instanceof Error) message = err.message;
          console.error('error getting actions', message);
        }
      }
    })();
  }, [client, ready]);

  return { actions };
};

export default useActions;
