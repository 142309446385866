import { SecondaryButton, TertiaryButton } from '@boostpoint/ui';

const CheckoutCanceled = (props: {
  onContinue: () => void;
  close: () => void;
}) => (
  <div
    className={`block w-full flex-col items-end justify-between gap-8 xl:flex`}
  >
    <div className='w-full'>
      <h2 className='mt-8 text-[24px] font-semibold md:text-[32px]'>
        Complete checkout to continue
      </h2>
      <p className='my-3'>
        Uh oh! It looks like the checkout process has not been completed.
      </p>
      <p className='my-3'>
        <strong>Finish checking out to continue.</strong>
      </p>
    </div>
    <div className='mt-4 flex w-full gap-2'>
      <SecondaryButton onPress={props.close}>Cancel</SecondaryButton>
      <TertiaryButton onPress={props.onContinue}>
        Continue Checkout
      </TertiaryButton>
    </div>
  </div>
);

export default CheckoutCanceled;
