import { ProjectBoInterface, PromptType } from '@boostpoint/types';
import { useEffect } from 'react';
import ProjectWizardActionsStep from './components/ProjectWizardActionsStep';
import ProjectWizardDetailsStep from './components/ProjectWizardConfigurationStep';
import ProjectWizardNameStep from './components/ProjectWizardNameStep';
import { useProjects } from '../../providers/ProjectProvider';
import { useNavigate, useParams } from 'react-router-dom';
import { useCompany } from '../../providers/CompanyProvider';
import { useDialog } from '../../providers/DialogProvider';
import Subscription from '../../subscription/Subscription';
import { useSubscription } from '../../providers/SubscriptionProvider/context';

const ProjectWizard = () => {
  const { projectId, step } = useParams();
  const navigate = useNavigate();
  const { setDialog, setDialogOpen } = useDialog();
  const {
    createProject,
    updateProject,
    activateProject,
    archiveProject,
    projects,
  } = useProjects();
  const { company } = useCompany();
  const { subscription } = useSubscription();

  useEffect(() => {
    if (!subscription?.isActive && company?.freemiumProjectCount) {
      if (
        company?.freemiumProjectCount <= projects.length &&
        (!projectId || projects.find(p => p.id === projectId) === undefined)
      ) {
        setDialog(
          <Subscription
            startingPage='upgradeCtaProject'
            close={closeSubscriptionDialog}
          />,
        );
        setDialogOpen(true);
      }
    }
  }, [
    projects.length,
    company?.freemiumProjectCount,
    projectId,
    projects,
    subscription,
  ]);

  const closeSubscriptionDialog = () => {
    setDialogOpen(false);
    navigate('/');
  };

  const draftProject = projects.find(proj => proj.id === projectId);

  useEffect(() => {
    if (!projectId && step !== 'name') {
      navigate('/project/new/name');
    }
  }, [projectId]);

  const handleNameSelected = async (name: string, promptType?: PromptType) => {
    let dp;
    if (draftProject && draftProject.id) {
      dp = await updateProject(draftProject.id, { name, promptType });
      navigate('/project/new/actions/' + draftProject.id);
    } else {
      dp = await createProject({ name, promptType });
      navigate('/project/new/actions/' + dp?.id);
    }
  };

  const handleCreate = async (configuration: Partial<ProjectBoInterface>) => {
    if (projectId) {
      const savedProject = await updateProject(projectId, configuration);
      if (!savedProject) {
        throw new Error('Project failed to save.');
      }
      const success = await activateProject(projectId);
      if (success) {
        navigate(`/project/results/${projectId}`, {
          state: { showPopup: true },
        });
      }
    }
  };

  const handleArchive = async () => {
    if (projectId) {
      const isSuccess = await archiveProject(projectId);

      if (!isSuccess) {
        throw new Error('Failed to archive project.');
      }

      navigate(`/create`);
    }
  };

  return (
    <>
      {step === 'name' && (
        <ProjectWizardNameStep
          name={draftProject?.name}
          promptType={draftProject?.promptType}
          onContinue={handleNameSelected}
          onArchive={handleArchive}
        />
      )}

      {step === 'actions' && (
        <ProjectWizardActionsStep
          draftProject={draftProject}
          onBack={() => navigate('/project/new/name/' + projectId)}
          onContinue={() => navigate('/project/new/configuration/' + projectId)}
          onRename={handleNameSelected}
          onArchive={handleArchive}
        />
      )}

      {step === 'configuration' && (
        <ProjectWizardDetailsStep
          draftProject={draftProject}
          onBack={() => navigate('/project/new/actions/' + projectId)}
          onCreate={handleCreate}
          onRename={handleNameSelected}
          onArchive={handleArchive}
        />
      )}
    </>
  );
};

export default ProjectWizard;
