import React from 'react';

export const CardWrapper = (props: Props) => {
  const { children, className = '' } = props;
  return (
    <div
      className={`border-bg-gray flex w-full flex-col rounded-2xl border-[2px] border-solid bg-white px-[42px] py-[40px] ${className}`}
    >
      {children}
    </div>
  );
};

type Props = {
  children: React.ReactNode;
  className?: string;
};
