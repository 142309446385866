import {
  DefaultModal,
  ModalButtons,
  ModalText,
  ModalTitle,
  PrimaryButton,
  SecondaryButton,
  TextField,
} from '@boostpoint/ui';
import { useEffect, useState } from 'react';
import { BiSolidBellRing } from 'react-icons/bi';
import { useCompany } from '../providers/CompanyProvider';
import { useInvitation } from '../providers/InvitationProvider';
import { useUser } from '../providers/UserProvider';
import { useOverlayTriggerState } from 'react-stately';
import { MemberRole } from '@boostpoint/types';
import { BoostpointCreateAiError } from '@boostpoint/client-sdk';

const CompanyProfile = () => {
  const { company, updateCompany, hasRole } = useCompany();
  const { user, updateUser } = useUser();
  const { companyInvitations, acceptInvitation, declineInvitation } =
    useInvitation();

  const [companyName, setCompanyName] = useState(company?.name ?? '');
  const [first, setFirst] = useState(user?.firstName ?? '');
  const [last, setLast] = useState(user?.lastName ?? '');
  const [email, setEmail] = useState(user?.email ?? '');
  const [error, setError] = useState<string | null>(null);
  const [selectedInvitationId, setSelectedInvitationId] = useState<
    string | null
  >(null);
  const [isAcceptingInvitation, setIsAcceptingInvitation] = useState(false);
  const [isDecliningInvitation, setIsDecliningInvitation] = useState(false);

  // TODO: Whenever password reset is implemented, be sure to add a call to firebase for linking and email/password credential in case the original user was created via SSO
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const acceptInvitationDialogState = useOverlayTriggerState({});

  useEffect(() => {
    if (user) {
      setFirst(user.firstName);
      setLast(user.lastName);
      setEmail(user.email);
    }
  }, [user]);

  useEffect(() => {
    setCompanyName(company?.name ?? '');
  }, [company]);

  useEffect(() => {
    if (!success) return;
    const timer = setTimeout(() => {
      setSuccess(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [success]);

  // Cleanup invitation state after accepting or declining
  useEffect(() => {
    // if (!companyInvitation) {
    setSelectedInvitationId(null);
    setIsAcceptingInvitation(false);
    setIsDecliningInvitation(false);
    // }
  }, [companyInvitations]);

  const resetState = () => {
    setFirst(user?.firstName ?? '');
    setLast(user?.lastName ?? '');
    setEmail(user?.email ?? '');
    setCompanyName(company?.name ?? '');
  };

  const handleSave = async () => {
    setError(null);
    let success = true;
    setLoading(true);
    const userUpdate = {
      //TODO: Uncomment when email verification is implemented
      // ...(email !== user?.email && { email }),
      ...(first !== user?.firstName && { firstName: first }),
      ...(last !== user?.lastName && { lastName: last }),
    };
    if (Object.keys(userUpdate).length > 0) {
      try {
        await updateUser(userUpdate);
      } catch (e: unknown) {
        const error = e as BoostpointCreateAiError & { body: any };
        console.log(error.body);
        if (error?.body?.message?.[0]) {
          setError(error?.body?.message?.[0]);
        } else {
          setError('An unknown error occurred.');
        }
        success = false;
      }
    }
    const companyUpdate = {
      ...(companyName !== company?.name && { name: companyName }),
    };
    if (Object.keys(companyUpdate).length > 0) {
      updateCompany(companyUpdate);
    }
    setLoading(false);
    if (success) {
      setSuccess(true);
    } else {
      resetState();
    }
  };

  const handlePromptAcceptInvitation = (invitationId: string) => {
    setSelectedInvitationId(invitationId);
    acceptInvitationDialogState.open();
  };

  const handleDeclineInvitation = async (invitationId: string) => {
    try {
      setIsDecliningInvitation(true);
      await declineInvitation(invitationId);
    } catch (e) {
      console.error(e);
    }
  };

  const handleAcceptInvitation = async (reassignProjects: boolean) => {
    try {
      if (!selectedInvitationId) {
        throw new Error('No invitation to accept.');
      }

      acceptInvitationDialogState.close();
      setIsAcceptingInvitation(true);
      await acceptInvitation(selectedInvitationId, reassignProjects);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      <div className='flex w-full gap-16'>
        <div className='flex grow-[1] flex-col gap-2'>
          {/* TODO: Extract into component */}
          {companyInvitations.map(companyInvitation => (
            <div className='my-4'>
              <div className='flex items-center gap-2 py-4'>
                <BiSolidBellRing />
                <h2 className='font-semibold text-bp-pink'>
                  You have been invited to join {companyInvitation.companyName}
                  's workspace.
                </h2>
              </div>
              <div className='flex gap-3'>
                <SecondaryButton
                  onPress={() =>
                    handleDeclineInvitation(companyInvitation?.invitation?.id)
                  }
                  isLoading={isDecliningInvitation}
                  isDisabled={isAcceptingInvitation}
                >
                  Decline
                </SecondaryButton>
                <PrimaryButton
                  onPress={() =>
                    handlePromptAcceptInvitation(
                      companyInvitation?.invitation?.id,
                    )
                  }
                  isLoading={isAcceptingInvitation}
                  isDisabled={isDecliningInvitation}
                >
                  Accept
                </PrimaryButton>
              </div>
            </div>
          ))}
          {/* END */}

          <h2 className='my-4 text-[20px] font-semibold'>Basic Information</h2>
          <div>
            <label
              htmlFor='company-name'
              className='my-2 block'
            >
              Company
            </label>
            <TextField
              isDisabled={!hasRole(MemberRole.Admin)}
              name='company-name'
              aria-label='Company'
              value={companyName}
              onChange={setCompanyName}
              id='company-name'
            />
          </div>
          <div>
            <label
              htmlFor='first-name'
              className='my-2 block'
            >
              First Name
            </label>
            <TextField
              name='first-name'
              aria-label='first name'
              value={first}
              onChange={setFirst}
              id='first-name'
            />
          </div>
          <div>
            <label
              htmlFor='last-name'
              className='my-2 block'
            >
              Last Name
            </label>
            <TextField
              name='last-name'
              aria-label='last name'
              value={last}
              onChange={setLast}
              id='last-name'
            />
          </div>
          <div>
            <label
              htmlFor='email'
              className='my-2 block'
            >
              Email
            </label>
            <TextField
              //TODO: remove isDisabled when email verification is implemented
              isDisabled
              name='email'
              aria-label='email'
              value={email}
              onChange={setEmail}
              id='email'
            />
          </div>
          {error && <p className='italic text-bp-red'>{error}</p>}
          <div className='mt-6'>
            <PrimaryButton
              onPress={handleSave}
              id='save-btn'
              isLoading={loading}
              isDisabled={
                !user ||
                (first === user?.firstName &&
                  last === user?.lastName &&
                  email === user?.email &&
                  companyName === company?.name &&
                  !success)
              }
            >
              {success ? 'Success!' : 'Save'}
            </PrimaryButton>
          </div>
        </div>
        <div className='relative flex grow-[1] flex-col gap-2'></div>
        {acceptInvitationDialogState.isOpen && (
          <DefaultModal
            state={acceptInvitationDialogState}
            isDismissable
          >
            <ModalTitle>Migrate Projects?</ModalTitle>
            <ModalText>
              Do you want to bring your projects with you to your new workspace?
            </ModalText>
            <ModalButtons>
              <SecondaryButton onPress={() => handleAcceptInvitation(false)}>
                No
              </SecondaryButton>
              <PrimaryButton onPress={() => handleAcceptInvitation(true)}>
                Yes
              </PrimaryButton>
            </ModalButtons>
          </DefaultModal>
        )}
      </div>
    </div>
  );
};

export default CompanyProfile;
