import { FaCopy } from 'react-icons/fa';
import { LuRedo2 } from 'react-icons/lu';
import { Button, Checkmark, TextArea, TextField } from '.';
import { useState } from 'react';

interface Props {
  type?: 'text' | 'textarea';
  label?: string;
  text?: string;
  isLoading?: boolean;
  onChange?(text: string): void;
  onRewrite?(): void;
  onCopy?(): void;
}

export const ActionResultSection = (props: Props) => {
  const {
    type = 'textarea',
    label,
    text,
    onChange,
    onRewrite,
    onCopy,
    isLoading,
  } = props;

  const [check, setCheck] = useState(false);

  const handleOnCopy = () => {
    if (onCopy && !check) {
      onCopy();
      setCheck(true);
      setTimeout(() => {
        setCheck(false);
      }, 3000);
    }
  };

  return (
    <div className='my-6 rounded-lg border-[1px] px-6 pb-6 pt-4'>
      <div className='flex h-8 items-center'>
        <h3 className='flex-1 text-sm font-semibold'>{label}</h3>
        <div className='flex gap-2 text-bp-black'>
          {onRewrite && (
            <Button
              title='rewrite'
              isDisabled={isLoading}
              isLoading={isLoading}
              onPress={onRewrite}
            >
              <LuRedo2 className='stroke-[3]' />
            </Button>
          )}
          {onCopy && (
            <div className='relative'>
              {check ? <Checkmark /> : <></>}
              <Button
                title='copy'
                className={`transition-opacity duration-1000 ease-in-out ${
                  check ? 'opacity-0' : 'opacity-100'
                }`}
                onPress={handleOnCopy}
              >
                <FaCopy />
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className='mt-4'>
        {type === 'text' && (
          <TextField
            aria-label={label}
            className='text-sm'
            value={text}
            onChange={onChange}
          />
        )}
        {type === 'textarea' && (
          <TextArea
            aria-label={label}
            className='text-sm'
            value={text}
            onChange={onChange}
          />
        )}
      </div>
    </div>
  );
};
