const MenuDots = (props: { open: boolean }) => (
  <div className='relative h-[33px] w-[23px] p-[7px]'>
    <div
      className={`h-[8px] w-[8px] border-[2px] border-solid border-black ${
        !props.open ? 'md:bg-white group-hover:md:bg-black' : 'md:bg-black'
      } absolute left-[8px] rounded-full bg-black`}
    ></div>
    <div
      className={`h-[8px] w-[8px] border-[2px] border-solid border-black ${
        !props.open ? 'md:bg-white group-hover:md:bg-black' : 'md:bg-black'
      } absolute bottom-[5px] left-0 rounded-full bg-black`}
    ></div>
    <div
      className={`absolute bottom-[5px] right-0 h-[8px] w-[8px] rounded-full border-[2px] border-solid border-black bg-black`}
    ></div>
  </div>
);

export default MenuDots;
