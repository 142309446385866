import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export const ModalTitle = ({ children }: Props) => {
  return (
    <h1 className='mt-12 text-[24px] font-semibold md:text-[32px]'>
      {children}
    </h1>
  );
};
