import {
  ActionRequestBoInterface,
  ActionRequestConfigUpsertDtoInterface,
  ActionRequestUpsertDtoInterface,
} from '@boostpoint/types';
import { useState, useEffect } from 'react';
import { useApi } from '../providers/ApiProvider';

const useActionRequest = ({
  projectId,
  requestId,
}: {
  projectId?: string;
  requestId?: string;
}) => {
  const [actionRequests, setActionRequests] = useState<
    ActionRequestBoInterface[]
  >([]);
  const [request, setRequest] = useState<ActionRequestBoInterface | null>(null);
  const { client, ready } = useApi();

  useEffect(() => {
    refreshRequests();
  }, [projectId, ready]);

  useEffect(() => {
    if (requestId && ready) getById(requestId);
  }, [requestId, ready]);

  const refreshRequests = async () => {
    if (projectId && ready) {
      const actionRequests = await client.project.getProjectRequests(projectId);
      setActionRequests(actionRequests);
    }
  };

  const createRequest = async (
    requestBody: ActionRequestUpsertDtoInterface,
  ): Promise<ActionRequestBoInterface> => {
    const newRequest = await client.request.create(requestBody);
    setRequest(newRequest);
    return newRequest;
  };

  const createBulkRequest = async (
    requestBody: ActionRequestUpsertDtoInterface[],
  ): Promise<ActionRequestBoInterface[]> => {
    const newRequest = await client.request.createBulk(requestBody);
    refreshRequests();
    return newRequest;
  };

  const deleteRequest = async (id: string) => {
    await client.request.delete(id);
    refreshRequests();
  };

  const getById = async (
    requestId: string,
  ): Promise<ActionRequestBoInterface> => {
    const response = await client.request.getById(requestId);
    setRequest(response);
    return response;
  };

  const updateBulkRequest = async (
    requestBody: ActionRequestConfigUpsertDtoInterface[],
  ): Promise<ActionRequestBoInterface[]> => {
    const response = await client.request.updateBulk(requestBody);
    refreshRequests();
    return response;
  };

  return {
    request,
    actionRequests,
    createRequest,
    createBulkRequest,
    updateBulkRequest,
    getById,
    deleteRequest,
  };
};

export default useActionRequest;
