import {
  BulkCompanyMemberUpsertDtoInterface,
  BulkCompanyMemberUpsertResponseBoInterface,
  CompanyBoInterface,
  CompanyMemberBoInterface,
  CompanyUpsertDtoInterface,
  MemberRole,
} from '@boostpoint/types';
import React, { useCallback, useEffect, useState } from 'react';
import { useApi } from '../ApiProvider';
import { useUser } from '../UserProvider';
import { CompanyContext } from './context';

const CompanyProvider = (props: { children: React.ReactNode }) => {
  const { children } = props;
  const [company, setCompany] = useState<CompanyBoInterface | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [role, setRole] = useState<MemberRole | null>(null);
  const [isLoadingRole, setIsLoadingRole] = useState(true);
  const { client } = useApi();
  const { user } = useUser();

  const refreshCompany = async () => {
    try {
      setIsLoading(true);
      if (!user?.companyId) {
        throw new Error('User does not have a company');
      }

      const u = await client?.company.findById(user?.companyId);
      if (u) {
        setCompany(u);
      }
    } catch (e) {
      console.log(e);
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchRole = async () => {
    try {
      if (!company?.id) {
        setRole(null);
        return;
      }

      setIsLoadingRole(true);

      const response = await client?.company.findUserRole(company.id);
      setRole(response.role ?? null);

      setIsLoadingRole(false);
    } catch (e) {
      console.error(e);
    }
  };

  const updateCompany = async (companyUpdate: CompanyUpsertDtoInterface) => {
    try {
      if (!company) {
        throw new Error('Company not found');
      }

      const u = await client?.company.update(company?.id, companyUpdate);
      if (u) {
        setCompany(u);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchMembers = async (): Promise<CompanyMemberBoInterface[]> => {
    if (!company?.id) {
      throw new Error('No company loaded');
    }
    try {
      const response = await client?.company.findMembersById(company.id);
      return response.members;
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  const updateMembers = async (
    data: BulkCompanyMemberUpsertDtoInterface,
  ): Promise<BulkCompanyMemberUpsertResponseBoInterface> => {
    if (!company?.id) {
      throw new Error('No company loaded');
    }
    try {
      return await client.company.updateMembers(company.id, data);
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  const changeRole = async (
    userId: string,
    role: MemberRole,
  ): Promise<CompanyMemberBoInterface> => {
    if (!company?.id) {
      throw new Error('No company loaded');
    }
    try {
      return await client.company.changeRole(company.id, userId, { role });
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  const hasRole = useCallback(
    (requiredRole: MemberRole): boolean => {
      if (!role) {
        return false;
      }

      switch (requiredRole) {
        case MemberRole.Owner:
          return ([MemberRole.Owner] as MemberRole[]).includes(role);
        case MemberRole.Admin:
          return (
            [MemberRole.Owner, MemberRole.Admin] as MemberRole[]
          ).includes(role);
        case MemberRole.Member:
          return (
            [
              MemberRole.Owner,
              MemberRole.Admin,
              MemberRole.Member,
            ] as MemberRole[]
          ).includes(role);
        default:
          return false;
      }
    },
    [role],
  );

  useEffect(() => {
    if (user?.companyId) {
      refreshCompany();
      console.log('refreshing company');
    } else {
      setCompany(null);
    }
  }, [user?.companyId]);

  useEffect(() => {
    if (company) {
      fetchRole();
    } else {
      setRole(null);
    }
  }, [company]);

  const wrapped = {
    refreshCompany,
    updateCompany,
    fetchMembers,
    changeRole,
    updateMembers,
    fetchRole,
    hasRole,
    company,
    role,
    isLoadingRole,
    isLoading,
  };

  return (
    <CompanyContext.Provider value={wrapped}>
      {children}
    </CompanyContext.Provider>
  );
};
export default CompanyProvider;
