import { HiCurrencyDollar } from 'react-icons/hi';
import { IoLocationSharp } from 'react-icons/io5';
import { ActionPreviewCard } from '../ActionPreviewCard';

interface Props {
  result: any;
}

export const WebJobPreview = (props: Props) => {
  const {
    position_title,
    location,
    salary,
    description,
    benefits,
    responsibilities,
    requirements,
    call_to_action,
  } = props.result as {
    position_title?: string;
    location?: string;
    salary?: string;
    description?: string;
    benefits?: string[];
    responsibilities?: string[];
    requirements?: string[];
    call_to_action?: string;
  };
  return (
    <ActionPreviewCard>
      <div className='relative m-[4px] flex max-h-[40rem] flex-col gap-1 overflow-x-hidden overflow-y-scroll p-8 pt-0 md:w-96'>
        <div className='sticky top-0 flex flex-col gap-1 border-b-2 border-gray-300 bg-white pb-4 pt-8'>
          <h3 className='text-left font-medium text-indigo-900'>
            {position_title}
          </h3>
          <div className='flex flex-wrap gap-1'>
            <div className='flex w-full flex-row items-center gap-1'>
              <span>
                <IoLocationSharp className='text-sm text-gray-600' />
              </span>
              <p className='overflow-hidden text-ellipsis whitespace-nowrap text-sm text-gray-600'>
                {location}
              </p>
            </div>
            <div className='flex w-full flex-row items-center gap-1'>
              <span>
                <HiCurrencyDollar className='text-sm text-gray-600' />
              </span>
              <p className='overflow-hidden text-ellipsis whitespace-nowrap text-sm text-gray-600'>
                {salary}
              </p>
            </div>
          </div>
        </div>
        <p className='mt-2 text-left text-sm'>{description}</p>
        <div>
          <p className='mb-1 text-left text-sm font-semibold'>Benefits:</p>
          <div className='flex flex-col'>
            {benefits?.map(b => (
              <p
                className='mr-1 text-left text-sm'
                key={b}
              >{`• ${b}`}</p>
            ))}
          </div>
        </div>
        <div>
          <p className='mb-1 text-left text-sm font-semibold'>
            Responsibilities:
          </p>
          <div className='flex flex-col'>
            {responsibilities?.map(b => (
              <p
                className='mr-1 text-left text-sm'
                key={b}
              >{`• ${b}`}</p>
            ))}
          </div>
        </div>
        <div>
          <p className='mb-1 text-left text-sm font-semibold'>Requirements:</p>
          <div className='flex flex-col'>
            {requirements?.map(b => (
              <p
                className='mr-1 text-left text-sm'
                key={b}
              >{`• ${b}`}</p>
            ))}
          </div>
        </div>
        <p className='text-left text-sm'>{call_to_action}</p>
        <br />
      </div>
    </ActionPreviewCard>
  );
};
