import {
  SubscriptionBoInterface,
  SubscriptionCheckoutBoInterface,
  SubscriptionDtoInterface,
  SubscriptionInvoicePreviewBoInterface,
} from '@boostpoint/types';
import { createContext, useContext } from 'react';

export const SubscriptionContext = createContext<any>(null);

export const useSubscription = () => {
  const subscription = useContext<{
    subscription: SubscriptionBoInterface | null;
    refreshSubscription: () => Promise<void>;
    getCheckout: (
      s: SubscriptionDtoInterface,
    ) => Promise<SubscriptionCheckoutBoInterface>;
    getSubscriptionInvoicePreview: (
      s: SubscriptionDtoInterface,
    ) => Promise<SubscriptionInvoicePreviewBoInterface>;
    updateSubscription: (s: SubscriptionDtoInterface) => Promise<boolean>;
    isLoading: boolean;
  }>(SubscriptionContext);
  return subscription;
};
