import { SecondaryButton, TertiaryButton } from '@boostpoint/ui';

const UpgradeCTAModal = (props: Props) => {
  const { close, onContinue } = props;
  return (
    <div
      className={`block w-full flex-col items-end justify-between gap-8 xl:flex`}
    >
      <div>
        <h2 className='mt-8 text-[24px] font-semibold md:text-[32px]'>
          Upgrade to continue
        </h2>
        <p className='my-3'>
          It looks like you have reached your free trial's limit of 8 projects.
        </p>
        <p className='my-3'>
          To continue letting CARL generate incredible recruitment content,
          upgrade your subscription now!
        </p>
      </div>
      <div className='mt-4 flex w-full gap-2'>
        <SecondaryButton onPress={close}>Cancel</SecondaryButton>
        <TertiaryButton onPress={onContinue}>Upgrade Plan</TertiaryButton>
      </div>
    </div>
  );
};

type Props = {
  close: () => void;
  onContinue: () => void;
};

export default UpgradeCTAModal;
