import { TertiaryButton } from '@boostpoint/ui';
import { BiLeftArrowAlt } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import CarlAnimated from '../../assets/CARL-animated.gif';

const Landing = () => {
  return (
    <div className='flex h-full w-full flex-col items-center justify-center md:pb-[200px]'>
      <img
        src={CarlAnimated}
        alt='Carl'
        className='w-[200px] md:w-[300px]'
      />
      <p className='mt-8 hidden flex-row items-center gap-1 text-bp-purple md:flex'>
        <BiLeftArrowAlt fontSize='22px' /> Start a New Project to get the gears
        rolling!
      </p>
      <p className='mt-6 text-center md:hidden'>
        <TertiaryButton>
          <Link to='/project/new/name'>Create Project</Link>
        </TertiaryButton>
      </p>
    </div>
  );
};

export default Landing;
