import {
  ActionResultBoInterface,
  ActionResultRewritePostDtoInterface,
  ActionResultFeedbackPostDtoInterface,
} from '@boostpoint/types';
import { useApi } from '../providers/ApiProvider';

const useActionResult = () => {
  const { client } = useApi();

  const getResults = async (
    requestId: string,
  ): Promise<ActionResultBoInterface[]> => {
    const response = await client.request.getResults(requestId);
    return response;
  };

  const requestRewrite = async (
    resultId: string,
    request: ActionResultRewritePostDtoInterface,
  ) => {
    const response = await client.result.requestRewrite(resultId, request);
    return response;
  };

  const sendFeedback = async (
    resultId: string,
    request: ActionResultFeedbackPostDtoInterface,
  ) => {
    const response = await client.result.sendFeedback(resultId, request);
    return response;
  };

  return { getResults, requestRewrite, sendFeedback };
};

export default useActionResult;
