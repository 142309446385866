const SubscriptionInactiveMember = () => (
  <div className='w-full'>
    <div
      className={`block w-full flex-col items-end justify-between gap-8 xl:flex`}
    >
      <div>
        <h2 className='mt-8 text-[24px] font-semibold md:text-[32px]'>
          Account locked due to billing errors
        </h2>
        <p className='my-3'>
          Uh oh! It looks like something went wrong with your billing
          information.
        </p>
        <p className='my-3'>
          <strong>
            You will not be able to move forward until this has been resolved.
          </strong>{' '}
          Contact your account owner to update your billing information today.
        </p>
      </div>
    </div>
  </div>
);

export default SubscriptionInactiveMember;
