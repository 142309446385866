// ALPHA COMPONENT
import { useParams } from 'react-router-dom';
import poopEmoji from '../../assets/Poop-Emoji.png';
import border from '../../assets/layout/menuBorderDesktop.png';
import background from '../../assets/layout/signup-bg.png';
import chatBubble from '../../assets/layout/testimonial-bubble.svg';
import logo from '../../assets/lightLogo.svg';
import { useAuth } from '../../providers/AuthProvider';
import { SsoProvider } from '../../types/sso-provider.enum';
import Registration from './components/RegistrationForm';

const Login = () => {
  const { logIn, registerUser, signInWithSso } = useAuth();
  const { register } = useParams();
  const reg = register === 'register';

  const submit = (email: string, password: string) => {
    if (reg) {
      registerUser(email, password);
    } else {
      logIn(email, password);
    }
  };

  // These work for both "sign up" and "sign in". If user is "signing in", then they have a user. If "signing up", then a user will be created and they will be logged in.
  const handleSignInWithGoogle = () => {
    signInWithSso(SsoProvider.GOOGLE);
  };

  const handleSignInWithMicrosoft = () => {
    signInWithSso(SsoProvider.MICROSOFT);
  };

  return (
    <div>
      <div
        className='absolute z-0 h-[100dvh] w-full bg-cover bg-center bg-no-repeat'
        style={{ backgroundImage: `url('${background}')` }}
      />
      <div className='triangle-container fixed -bottom-[439px] -left-[55px] top-[716px] hidden h-[1035px] w-[3000px] -rotate-[9deg] bg-white xl:block lgh:hidden' />
      <div className='fixed left-0 z-20 hidden xl:block'>
        <img
          src={border}
          className='h-[100dvh]'
        />
      </div>
      <div className='relative z-10 bg-contain bg-[-50px_center] bg-no-repeat xl:bg-left'>
        <div className='absolute z-10 hidden xl:ml-[14px] xl:block'>
          <img
            src={logo}
            alt='Boostpoint'
            className='relative left-[34px] top-[25px] '
          />
        </div>
        <div className='z-10 flex h-[100dvh] flex-col gap-8 overflow-y-scroll px-[5px] xl:flex-row xl:justify-center xl:gap-[100px] xl:px-0 xl:pt-[30px]'>
          <div className='p-8 pb-1 xl:hidden'>
            <img
              src={logo}
              alt='Boostpoint'
            />
          </div>
          <div
            className={`my-auto hidden pb-80 xl:block ${
              !reg && '-mr-[38rem] opacity-0'
            } -z-10 transition-all duration-500 ease-in-out`}
          >
            <div className='mt-[80px] text-[70px] font-black uppercase italic leading-[62px] text-white'>
              Hire More.
              <br /> Write Less.
            </div>
            <p className='mt-[22px] text-[18px] text-white'>
              With an AI assistant that does all the writing for you.
            </p>
            <div
              style={{ backgroundImage: `url('${chatBubble}')` }}
              className='bp-cover mb-[40px] mt-[29px] flex items-center justify-center bg-transparent bg-no-repeat p-[20px] pb-[54px] xl:bg-contain'
            >
              <p className='align-center relative w-[390px] pt-[5px] text-[18px] text-white'>
                &ldquo;Signed up. This might be the first AI tool in our space
                that has not left me sarcastic and thinking it is{' '}
                <img
                  src={poopEmoji}
                  alt='poop emoji'
                  className='inline-block h-[20px]'
                />
                &rdquo;
              </p>
            </div>
            <div className='relative -top-[52px] left-[101px] z-10 text-white'>
              <p className='text-[16px] font-semibold'>
                Paul <span className='blur-sm'>Besdntigteie</span>
              </p>
              <p className='text-[14px] italic'>Current Create.AI User</p>
            </div>
          </div>
          <div className='flex flex-col items-center xl:pb-[30px]'>
            <Registration
              register={reg}
              onSubmit={submit}
              onSignInWithGoogle={handleSignInWithGoogle}
              onSignInWithMicrosoft={handleSignInWithMicrosoft}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
