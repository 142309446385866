// ALPHA COMPONENT
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useAuth } from '../../providers/AuthProvider';
import { Button, Loading, TextField } from '@boostpoint/ui';
import border from '../../assets/layout/menuBorderDesktop.png';
import background from '../../assets/layout/signup-bg.png';
import logo from '../../assets/lightLogo.svg';

const PasswordReset = () => {
  const { passwordReset, authMessage, authError, resetMessages } = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const sendEmail = async () => {
    setLoading(true);
    await passwordReset(email);
    setLoading(false);
  };

  const onEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      sendEmail();
    }
  };

  const reset = () => {
    resetMessages();
    navigate(`/login`);
  };

  return (
    <div>
      <div
        className='absolute z-0 h-[100dvh] w-full bg-cover bg-center bg-no-repeat'
        style={{ backgroundImage: `url('${background}')` }}
      />
      <div className='triangle-container fixed -bottom-[439px] -left-[55px] top-[716px] hidden h-[1035px] w-[3000px] -rotate-[9deg] bg-white xl:block lgh:hidden' />
      <div className='fixed left-0 z-20 hidden xl:block'>
        <img
          src={border}
          className='h-[100dvh]'
        />
      </div>
      <div className='relative z-10 bg-contain bg-[-50px_center] bg-no-repeat xl:bg-left'>
        <div className='absolute z-10 hidden xl:ml-[14px] xl:block'>
          <img
            src={logo}
            alt='Boostpoint'
            className='relative left-[34px] top-[25px] '
          />
        </div>
        <div className='z-10 flex h-[100dvh] flex-col gap-8 overflow-y-scroll px-[5px] xl:flex-row xl:justify-center xl:gap-[100px] xl:px-0 xl:pt-[30px]'>
          <div className='p-8 pb-1 xl:hidden'>
            <img
              src={logo}
              alt='Boostpoint'
            />
          </div>
          <div className='xl:pb-[30px]'>
            <div className='text-center'>
              <h3 className={`mb-4 text-3xl font-semibold text-bp-indigo`}>
                Password Reset
              </h3>
              <p className='w-80'>
                Updating your password will change your password for all
                Boostpoint products.
              </p>
            </div>
            <TextField
              label='Email'
              aria-label='email'
              id='email'
              type='email'
              value={email}
              name='email'
              onChange={setEmail}
              onKeyDown={onEnter}
            />
            <Loading loading={loading} />
            <div
              className={`flex w-80 flex-row items-center justify-center rounded-md bg-bp-green ${
                !authMessage ? 'max-h-0 opacity-0' : 'max-h-100  p-2'
              } transition-all duration-700`}
            >
              <p className='font-bold text-white '>{authMessage}</p>
            </div>
            <div className='mt-4 flex flex-row items-center justify-center gap-4'>
              <Button
                aria-label='sign in'
                onPress={reset}
              >
                Sign In
              </Button>
              <Button
                aria-label='reset password'
                isDisabled={email ? false : true}
                onPress={sendEmail}
              >
                Reset Password
              </Button>
              <div
                className={`absolute bottom-0 flex w-full flex-row items-center justify-center bg-bp-pink p-2 ${
                  !authError ? 'max-h-0 opacity-0' : 'max-h-10'
                } w-full transition-all duration-700`}
              >
                <p className='font-bold text-white '>{authError}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
