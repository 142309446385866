import { AriaTableProps, useTable } from 'react-aria';
import { TableStateProps, useTableState } from 'react-stately';
import { useRef } from 'react';
import { RowGroup } from './RowGroup';
import { HeaderRow } from './HeaderRow';
import { ColumnHeader } from './ColumnHeader';
import { TableRow } from './TableRow';
import { TableCell } from './TableCell';
import clsx from 'clsx';

export const Table = (
  props: TableStateProps<object> &
    AriaTableProps<object> & { className?: string },
) => {
  const state = useTableState({
    ...props,
  });

  const ref = useRef(null);
  const { collection } = state;
  const { gridProps } = useTable(props, state, ref);

  return (
    <table
      {...gridProps}
      ref={ref}
      className={clsx('w-full table-auto border-collapse', props.className)}
    >
      <RowGroup type='thead'>
        {collection.headerRows.map(headerRow => (
          <HeaderRow
            key={headerRow.key}
            item={headerRow}
            state={state}
            className='bg-[#F2F2F2]'
          >
            {[...headerRow.childNodes].map(column => (
              <ColumnHeader
                key={column.key}
                column={column}
                state={state}
              />
            ))}
          </HeaderRow>
        ))}
      </RowGroup>
      <RowGroup type='tbody'>
        {[...collection.body.childNodes].map(row => (
          <TableRow
            key={row.key}
            item={row}
            state={state}
          >
            {[...row.childNodes].map(cell => (
              <TableCell
                key={cell.key}
                cell={cell}
                state={state}
              />
            ))}
          </TableRow>
        ))}
      </RowGroup>
    </table>
  );
};
