import './checkmark.css';
export const Checkmark = () => {
  return (
    <div className={`pointer-events-none absolute -left-[2px] -top-[3px] w-5`}>
      <svg
        className={`checkmark`}
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 52 52'
      >
        <circle
          className={`checkmark_circle`}
          cx='26'
          cy='26'
          r='25'
          fill='none'
        />
        <path
          className={`checkmark_check`}
          fill='none'
          d='M14.1 27.2l7.1 7.2 16.7-16.8'
        />
      </svg>
    </div>
  );
};
