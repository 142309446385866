import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import ReactGA from 'react-ga4';

import App from './App.tsx';
import './index.css';
import { initializeApp } from 'firebase/app';
import { BoostpointCreateAiEnvironment } from '@boostpoint/client-sdk';

ReactGA.initialize("G-B65CFFQZC1");

const firebaseConfig = {
  apiKey: 'AIzaSyA7uUJ3q2du0YcLSJEQuWHozUxmbLCePl8',
  authDomain: 'auth.boostpoint.com',
  databaseURL: 'https://campaign-191617.firebaseio.com',
  projectId: 'campaign-191617',
  storageBucket: 'campaign-191617.appspot.com',
};
initializeApp(firebaseConfig);

Sentry.init({
  dsn: "https://ddf6d6bdffba48bdb1ca3292baed010f@o210079.ingest.sentry.io/4505167009939456",
  release: APP_VERSION,
  environment: import.meta.env.NODE_ENV,
  integrations: [
    new Sentry.Replay({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [
        BoostpointCreateAiEnvironment.Production,
        BoostpointCreateAiEnvironment.Staging,
        BoostpointCreateAiEnvironment.Development,
      ],
    }),
    new Sentry.BrowserTracing({}),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});

declare global {
  interface Window {
    Intercom?: any;
  }
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
