import { useRef } from 'react';
import { AriaToggleButtonProps, useToggleButton } from 'react-aria';
import { useToggleState } from 'react-stately';

interface Props extends AriaToggleButtonProps {
  className?: string;
}

export const ToggleButton = (props: Props) => {
  const { className, children } = props;

  const ref = useRef(null);
  const state = useToggleState(props);
  const { buttonProps } = useToggleButton(props, state, ref);

  return (
    <button
      {...buttonProps}
      className={className}
      ref={ref}
    >
      {children}
    </button>
  );
};
