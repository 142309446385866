import { ReactNode, useRef } from 'react';
import { mergeProps, useFocusRing, useTableRow } from 'react-aria';
import type { TableState } from 'react-stately';
import type { GridNode } from '@react-types/grid';

export const TableRow = (props: {
  item: GridNode<unknown>;
  children: ReactNode;
  state: TableState<unknown>;
}) => {
  const { item, children, state } = props;
  const ref = useRef(null);
  const isSelected = state.selectionManager.isSelected(item.key);
  const { rowProps, isPressed } = useTableRow(
    {
      node: item,
    },
    state,
    ref,
  );
  const { isFocusVisible, focusProps } = useFocusRing();

  return (
    <tr
      className='border-b-[1px] border-bp-gray last:border-b-0'
      style={{
        background: isSelected
          ? 'blueviolet'
          : isPressed
            ? 'var(--spectrum-global-color-gray-400)'
            : item?.index && item.index % 2
              ? 'var(--spectrum-alias-highlight-hover)'
              : 'none',
        color: isSelected ? 'white' : undefined,
        outline: 'none',
        boxShadow: isFocusVisible ? 'inset 0 0 0 2px orange' : 'none',
        cursor: 'default',
      }}
      {...mergeProps(rowProps, focusProps)}
      ref={ref}
    >
      {children}
    </tr>
  );
};
