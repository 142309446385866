import { ReactNode } from 'react';

interface Props {
  title?: string;
  children?: ReactNode;
}

export const ActionGroup = (props: Props) => {
  const { title, children } = props;

  return (
    <div className='flex flex-col pb-8'>
      <h4 className='pb-2 text-sm text-bp-indigo'>{title}</h4>
      <div className='flex flex-col gap-2 md:grid md:grid-cols-3 md:gap-8'>
        {children}
      </div>
    </div>
  );
};
