import { CardWrapper, PrimaryButton, Select, TextField } from '@boostpoint/ui';
import { Key, useEffect, useState } from 'react';
import { Item } from 'react-stately';
import { useAuth } from '../../../providers/AuthProvider';
import { useCompany } from '../../../providers/CompanyProvider';
import { useUser } from '../../../providers/UserProvider';

const RegistrationQuestions = ({ onClose }: { onClose: () => void }) => {
  const { currentUser } = useAuth();
  const { user, createUser, refreshUser } = useUser();
  const { company } = useCompany();

  const [firstName, setFirstName] = useState(user?.firstName ?? '');
  const [lastName, setLastName] = useState(user?.lastName ?? '');
  const [jobTitle, setJobTitle] = useState('');
  const [companyName, setCompanyName] = useState(company?.name ?? '');
  const [howDidYouHear, setHowDidYouHear] = useState('');
  const [loading, setLoading] = useState(false);

  const isValid =
    currentUser &&
    firstName &&
    lastName &&
    companyName &&
    jobTitle &&
    howDidYouHear;

  useEffect(() => {
    if (user?.firstName) {
      setFirstName(user.firstName);
    }
    if (user?.lastName) {
      setLastName(user.lastName);
    }
    if (company?.name) {
      setCompanyName(company.name);
    }
  }, [user, company]);

  const handleSelectionChange = (key: Key) => {
    if (howDidYouHear === key) {
      setHowDidYouHear('');
      return;
    }
    setHowDidYouHear(key as string);
  };

  const onSubmit = async () => {
    if (isValid) {
      setLoading(true);
      await createUser({
        email: currentUser?.email,
        firstName,
        lastName,
        jobTitle,
        companyName,
        howDidYouHear,
      });
      await refreshUser();
      onClose();
    }
  };

  return (
    <CardWrapper className='max-w-[600px] bg-white'>
      <h1 className='max-w-36 mx-auto mt-4 flex flex-col md:items-end'>
        <span className='block text-center text-[1.7em] font-semibold text-bp-purple md:whitespace-nowrap'>
          Boostpoint&reg; <span className='text-bp-pink'>Create.AI</span>
        </span>
      </h1>
      <p className='mb-4 text-center md:mb-8'>Complete your profile.</p>
      <TextField
        label='First Name'
        value={firstName}
        onChange={setFirstName}
        className='mb-4 md:mb-4'
      />
      <TextField
        label='Last Name'
        value={lastName}
        onChange={setLastName}
        className='mb-4 md:mb-4'
      />
      <TextField
        label='Company'
        value={companyName}
        onChange={setCompanyName}
        className='mb-4 md:mb-4'
      />
      <TextField
        label='Job Title'
        value={jobTitle}
        onChange={setJobTitle}
        className='mb-4 md:mb-6'
      />
      <Select
        label='How did you hear about Boostpoint® Create.AI?'
        items={[
          { id: 'website', name: 'Boostpoint Website' },
          { id: 'email', name: 'Email' },
          { id: 'friend', name: 'Friend/Colleague' },
          { id: 'social', name: 'Social Media' },
          { id: 'webinar', name: 'Webinar' },
          { id: 'conference', name: 'Conference' },
        ]}
        selectedKey={howDidYouHear}
        onSelectionChange={handleSelectionChange}
      >
        {item => <Item>{item.name}</Item>}
      </Select>
      <div className='mt-4 flex flex-row-reverse justify-center gap-8 md:mt-6'>
        <PrimaryButton
          isLoading={loading}
          isDisabled={!isValid}
          onPress={onSubmit}
        >
          Submit
        </PrimaryButton>
      </div>
    </CardWrapper>
  );
};

export default RegistrationQuestions;
