import { Page } from '@boostpoint/ui';
import { useEffect, useState } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';
import useViewport from '../../../hooks/useViewport';
import { useCompany } from '../../../providers/CompanyProvider';
import { useInvitation } from '../../../providers/InvitationProvider';
import { useProjects } from '../../../providers/ProjectProvider';
import { useUser } from '../../../providers/UserProvider';
import IntercomLauncher from '../IntercomLauncher';
import { useAuth } from '../../../providers/AuthProvider';
import RegistrationQuestions from '../../../pages/Landing/components/RegistrationQuestions';
import { useDialog } from '../../../providers/DialogProvider';
import Subscription from '../../../subscription/Subscription';
import FreemiumNotice from '../FreemiumNotice';
import { useSubscription } from '../../../providers/SubscriptionProvider/context';
import { useApi } from '../../../providers/ApiProvider';
import { MemberRole } from '@boostpoint/types';
import SubscriptionInactiveOwner from '../../../subscription/SubscriptionInactiveOwner';
import SubscriptionInactiveMember from '../../../subscription/SubscriptionInactiveMember';

const PageWrapper = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { width } = useViewport();
  const { projects } = useProjects();
  const { company, hasRole } = useCompany();
  const { subscription, isLoading } = useSubscription();
  const { companyInvitations } = useInvitation();
  const [searchParams] = useSearchParams();
  const q = searchParams.get('q');
  const { currentUser } = useAuth();
  const {
    user,
    hasLoaded: hasUserLoaded,
    isLoading: isUserLoading,
  } = useUser();
  const [viewQuestions, setViewQuestions] = useState(false);
  const { setDialog, setDialogOpen } = useDialog();
  const { client } = useApi();

  useEffect(() => {
    if (width > 640) setMenuOpen(true);
  }, [width]);
  useEffect(() => {
    // User is logged in, loading is finished, and user is not found
    // This means we need to show registration questions to setup the user
    if (currentUser && hasUserLoaded && !isUserLoading && !user) {
      setViewQuestions(true);
    } else {
      setViewQuestions(false);
    }
  }, [currentUser, user, isUserLoading]);

  useEffect(() => {
    if (subscription?.subscriptionExists && !subscription?.isActive) {
      const isOwner = hasRole(MemberRole.Owner);
      if (isOwner) {
        setDialog(<SubscriptionInactiveOwner />);
        setDialogOpen(true);
      } else {
        setDialog(<SubscriptionInactiveMember />);
        setDialogOpen(true);
      }
    }
  }, [subscription, hasRole]);

  useEffect(() => {
    if (q && q === 'checkoutCanceled') {
      setDialog(
        <Subscription
          startingPage='canceled'
          close={() => setDialogOpen(false)}
        />,
      );
      setDialogOpen(true);
    }
  }, [q]);

  const handleUpgrade = () => {
    setDialog(
      <Subscription
        startingPage='planSelect'
        close={() => setDialogOpen(false)}
      />,
    );
    setDialogOpen(true);
  };

  const getCustomerPortal = async () => {
    const url = await client.subscription.getCustomerPortal();
    return url.url;
  };

  return (
    <Page
      showCarl={
        !['/create', '/settings/profile', '/settings/users', '/404'].includes(
          location.pathname,
        )
      }
      fullHeight={
        location.pathname === '/create' || location.pathname === '/404'
      }
      projectList={projects.filter(p => ['ACTIVE', 'DRAFT'].includes(p.status))}
      menuIsOpen={menuOpen}
      toggleMenu={() => {
        setMenuOpen(!menuOpen);
      }}
      getBillingPortal={getCustomerPortal}
      subscription={subscription ?? undefined}
      user={user}
      company={company}
      hasRole={hasRole}
      invitations={companyInvitations}
      menuChildren={
        !subscription?.subscriptionExists &&
        !subscription?.isActive &&
        !isLoading ? (
          <FreemiumNotice
            projectCount={projects.length}
            freemiumProjectCount={company?.freemiumProjectCount ?? 0}
            handleUpgrade={handleUpgrade}
          />
        ) : (
          <span></span>
        )
      }
    >
      <Outlet />
      <IntercomLauncher />
      <div
        className={`${
          viewQuestions ? 'flex' : 'hidden'
        } fixed bottom-0 left-0 right-0 top-0 z-10 items-center justify-center p-8 backdrop-blur-lg`}
      >
        <RegistrationQuestions
          onClose={() => {
            setViewQuestions(false);
            window.Intercom('startTour', '500247');
          }}
        />
      </div>
    </Page>
  );
};

export default PageWrapper;
