import clsx from 'clsx';
import { useRef } from 'react';
import { useMenuItem } from 'react-aria';
import { Node, TreeState } from 'react-stately';
import { Key } from '@react-types/shared';

interface Props<T> {
  item: Node<T>;
  state: TreeState<T>;
  onAction: (key: Key) => void;
  onClose: () => void;
}

export const ActionMenuItem = <T extends object>({
  item,
  state,
  onAction,
  onClose,
}: Props<T>) => {
  // Get props for the menu item element
  const ref = useRef(null);
  const { menuItemProps } = useMenuItem(
    {
      key: item.key,
      onAction,
      onClose,
    },
    state,
    ref,
  );

  // Handle focus events so we can apply highlighted
  // style to the focused menu item
  const isFocused = state.selectionManager.focusedKey === item.key;

  return (
    <li
      {...menuItemProps}
      ref={ref}
      className={clsx(
        `relative mx-1 cursor-pointer select-none rounded py-2 pl-3 pr-9 text-sm font-medium text-bp-black focus:outline-none`,
        isFocused && 'bg-bp-lGray text-bp-purple',
      )}
    >
      {item.rendered}
    </li>
  );
};
