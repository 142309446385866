import * as Sentry from '@sentry/react';
import {
  Auth,
  EmailAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  User,
  UserInfo,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  getAuth,
  linkWithCredential,
  linkWithPopup,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  unlink,
} from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { SsoProvider } from '../../types/sso-provider.enum';
import { AuthContext } from './context';

const AuthProvider = (props: { children: React.ReactNode }) => {
  const { children } = props;
  const googleAuthProvider = new GoogleAuthProvider();
  const microsoftAuthProvider = new OAuthProvider(
    SsoProvider.MICROSOFT.toString(),
  );
  microsoftAuthProvider.setCustomParameters({
    tenant: 'common',
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [auth, _] = useState<Auth>(getAuth());
  const [authError, setAuthError] = useState('');
  const [authMessage, setAuthMessage] = useState('');
  const [authLoading, setAuthLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState<User | null>(auth.currentUser);

  const logOut = async () => {
    if (auth) {
      await auth.signOut();
    }
    resetMessages();
    setCurrentUser(null);
    window.Intercom('shutdown');
    window.Intercom('boot', {
      app_id: 'g0e1eyg3',
      custom_launcher_selector: '#custom_intercom',
      create_user: true,
    });
    Sentry.setUser(null);
  };

  const checkForExistingAccount = async (userEmail: string) =>
    (await fetchSignInMethodsForEmail(auth, userEmail)).length > 0;

  useEffect(() => {
    onAuthStateChanged(auth, async fbUser => {
      if (fbUser && fbUser?.uid !== currentUser?.uid) {
        Sentry.setUser({
          id: fbUser.uid,
          name: fbUser.displayName || undefined,
          email: fbUser.email || undefined,
        });
        window.Intercom('boot', {
          app_id: 'g0e1eyg3',
          custom_launcher_selector: '#custom_intercom',
          email: fbUser.email || undefined,
          name: fbUser.displayName || undefined,
          user_id: fbUser.uid,
          create_user: true,
        });
        resetMessages();
        setCurrentUser(fbUser);
      }
      setAuthLoading(false);
    });
  }, [auth, currentUser?.uid]);

  const logIn = async (userEmail: string, password: string) => {
    resetMessages();
    if (auth) {
      try {
        // setAuthLoading(true);
        await signInWithEmailAndPassword(auth, userEmail, password);
      } catch (e: any) {
        if (
          e.code === 'auth/wrong-password' ||
          e.code === 'auth/user-not-found'
        ) {
          setAuthError('Invalid login');
        } else if (e.code === 'auth/invalid-email') {
          setAuthError('Invalid email');
        } else {
          setAuthError(`Unknown login error: ${e.message}`);
        }
      }
    }
  };

  const getAuthProvider = (ssoProvider: SsoProvider) => {
    switch (ssoProvider) {
      case SsoProvider.GOOGLE:
        return googleAuthProvider;
      case SsoProvider.MICROSOFT:
        return microsoftAuthProvider;
    }
  };

  const getSsoProviderLabel = (ssoProvider: SsoProvider) => {
    switch (ssoProvider) {
      case SsoProvider.GOOGLE:
        return 'Google';
      case SsoProvider.MICROSOFT:
        return 'Microsoft';
    }
  };

  const signInWithSso = async (ssoProvider: SsoProvider) => {
    const authProvider = getAuthProvider(ssoProvider);
    const platformLabel = getSsoProviderLabel(ssoProvider);

    resetMessages();

    try {
      await signInWithPopup(auth, authProvider);
    } catch (e: any) {
      console.error(`An error occurred signing in with ${platformLabel}`, e);
      if (e.code === 'auth/account-exists-with-different-credential') {
        setAuthError(
          `An account already exists for this email. Please login with existing account and link your ${platformLabel} account.`,
        );
      }
    }
  };

  const linkSsoAccount = async (ssoProvider: SsoProvider) => {
    const authProvider = getAuthProvider(ssoProvider);
    const platformLabel = getSsoProviderLabel(ssoProvider);

    resetMessages();

    try {
      if (auth.currentUser) {
        await linkWithPopup(auth?.currentUser, authProvider);
        return;
      }

      throw new Error(
        `Failed to link ${platformLabel} account. Current user not found.`,
      );
    } catch (e: any) {
      console.error(`An error occurred linking ${platformLabel} account`, e);
      if (e.code === 'auth/credential-already-in-use') {
        setAuthError(
          `An error occurred linking your ${platformLabel} account. Your ${platformLabel} account is already in use.`,
        );
      } else {
        setAuthError(
          `An error occurred linking your ${platformLabel} account.`,
        );
      }
    }
  };

  const linkEmailAndPassword = async (email: string, password: string) => {
    try {
      resetMessages();
      const credential = EmailAuthProvider.credential(email, password);

      if (auth.currentUser) {
        await linkWithCredential(auth?.currentUser, credential);
        return;
      }

      throw new Error(
        'Failed to link email and password. Current user not found.',
      );
    } catch (e: any) {
      console.error('An error occurred linking email and password', e);
    }
  };

  const getLinkedSsoAccount = (ssoProvider: SsoProvider): UserInfo | null => {
    return (
      currentUser?.providerData.find(
        provider => provider.providerId === ssoProvider,
      ) ?? null
    );
  };

  const unlinkSsoAccount = async (ssoProvider: SsoProvider) => {
    const platformLabel = getSsoProviderLabel(ssoProvider);

    resetMessages();

    try {
      if (auth.currentUser) {
        if (auth.currentUser.providerData.length === 1) {
          setAuthError(
            'Failed to unlink account. User must maintain at least one method to sign in. Please set email/password to unlink.',
          );
          return;
        }

        await unlink(auth.currentUser, ssoProvider);
        return;
      }

      throw new Error(
        `Failed to unlink ${platformLabel} account. Current user not found.`,
      );
    } catch (e: any) {
      console.error(`An error occurred unlinking ${platformLabel} account`, e);
    }
  };

  const registerUser = async (email: string, password: string) => {
    resetMessages();
    if (auth) {
      try {
        await createUserWithEmailAndPassword(auth, email, password);
      } catch (e: any) {
        if (
          e.code === 'auth/wrong-password' ||
          e.code === 'auth/user-not-found'
        ) {
          setAuthError('Invalid login');
        } else {
          setAuthError(`Unknown login error: ${e}`);
        }
      }
    }
  };

  const passwordReset = async (userEmail: string) => {
    resetMessages();
    try {
      await sendPasswordResetEmail(auth, userEmail);
      setAuthMessage(
        'A password reset email has been sent. Please check your email and follow the instructions. When complete, you can return to the login page to log into your account.',
      );
    } catch (err: any) {
      if (
        err.code === 'auth/invalid-email' ||
        err.code === 'auth/missing-email'
      ) {
        setAuthError('Invalid email');
        setAuthMessage('');
      } else {
        // We should never show the user messages like "user not found" for the reset page. This is a security issue.
        // "Incorrectly implemented error messages in the case of authentication functionality can be used for the purposes of user ID and password enumeration. An application should respond (both HTTP and HTML) in a generic manner."
        // https://github.com/OWASP/CheatSheetSeries/blob/master/cheatsheets/Authentication_Cheat_Sheet.md#authentication-and-error-messages
        setAuthMessage(
          'A password reset email has been sent. Please check your email and follow the instructions. When complete, you can return to the login page to log into your account.',
        );
        setAuthError('');
        return;
      }
    }
  };

  const resetMessages = () => {
    setAuthError('');
    setAuthMessage('');
  };

  const getToken = async () => {
    if (auth.currentUser) {
      return await auth.currentUser.getIdToken();
    } else return undefined;
  };

  const refreshToken = async () => {
    if (auth.currentUser) {
      await auth.currentUser.getIdToken(true);
    } else return undefined;
  };

  const wrapped = {
    ...auth,
    currentUser,
    logIn,
    logOut,
    registerUser,
    checkForExistingAccount,
    authError,
    authLoading,
    passwordReset,
    authMessage,
    resetMessages,
    signInWithSso,
    linkSsoAccount,
    linkEmailAndPassword,
    getLinkedSsoAccount,
    unlinkSsoAccount,
    getToken,
    refreshToken,
    // signInWithGoogle,
    // signInWithMicrosoft,
    // linkGoogleAccount,
    // linkMicrosoftAccount,
    // linkEmailAndPassword,
    // unlinkAccount,
  };

  return (
    <AuthContext.Provider value={wrapped}>{children}</AuthContext.Provider>
  );
};
export default AuthProvider;
