import { BiComment, BiLike, BiRepost } from 'react-icons/bi';
import { FiSend } from 'react-icons/fi';
import { ActionPreviewCard } from '../ActionPreviewCard';

interface Props {
  companyName?: string;
  result: any;
}

export const OrganicLinkedInPreview = (props: Props) => {
  const { companyName } = props;
  const { post, hashtags } = props.result as {
    post?: string;
    hashtags?: string[];
  };
  return (
    <ActionPreviewCard>
      <div className='flex h-full flex-col gap-2 overflow-y-scroll p-8 md:w-96'>
        <div className='flex flex-row items-center gap-2'>
          <div className='bg-sidebar h-12 w-12 rounded-full bg-bp-gray bg-cover bg-center' />
          <div className='flex flex-col'>
            <h3 className='text-lg font-medium text-indigo-900'>
              {companyName}
            </h3>
            <p className='-mt-2 text-sm text-gray-400'>1d</p>
          </div>
        </div>
        <p className='text-left text-sm'>{post}</p>
        <div className='flex flex-wrap overflow-hidden '>
          {hashtags?.map(tag => (
            <p className='mr-1 text-sm text-bp-indigo'>{tag}</p>
          ))}
        </div>
        <div className='flex flex-row justify-between'>
          <p className='flex flex-row items-center gap-1 text-xs text-gray-500'>
            <BiLike /> Josiah McCracken and 28 others
          </p>
          <p className='flex flex-row items-center gap-1 text-xs text-gray-500'>
            97 comments
          </p>
        </div>
        <div className='flex flex-row justify-between border-t-2 border-gray-400 px-2 pt-2'>
          <p className='flex flex-row items-center gap-1 text-sm text-gray-500'>
            <BiLike /> Like
          </p>
          <p className='flex flex-row items-center gap-1 text-sm text-gray-500'>
            <BiComment /> Comment
          </p>
          <p className='flex flex-row items-center gap-1 text-sm text-gray-500'>
            <BiRepost className='text-xl text-gray-500' /> Repost
          </p>
          <p className='flex flex-row items-center gap-1 text-sm text-gray-500'>
            <FiSend /> Send
          </p>
        </div>
      </div>
    </ActionPreviewCard>
  );
};
