import ApplicantFollowUpVoicemail from './ApplicantFollowUpVoicemail.svg';
import EmailApplicantFollowUp from './EmailApplicantFollowUp.svg';
import EmailHiringCampaign from './EmailHiringCampaign.svg';
import FacebookHiringAd from './FacebookHiringAd.svg';
import FacebookOrganicPost from './FacebookOrganicPost.svg';
import GoogleCareersListing from './GoogleCareersListing.svg';
import InstagramOrganicPost from './InstagramOrganicPost.svg';
import InterviewQuestions from './InterviewQuestions.svg';
import JobDescription from './JobDescription.svg';
import LinkedInDirectMessage from './LinkedInDirectMessage.svg';
import LinkedInOrganicPost from './LinkedInOrganicPost.svg';
import SMSApplicantFollowUp from './SMSApplicantFollowUp.svg';
import SMSEmployeeReferral from './SMSEmployeeReferral.svg';
import SMSHiringCampaign from './SMSHiringCampaign.svg';
import TikTokOrganicPost from './TikTokOrganicPost.svg';
import TwitterOrganicPost from './TwitterOrganicPost.svg';
import SingleUserPlan from './SingleUserPlan.svg';
import TeamPlan from './TeamPlan.svg';
export default {
  FollowupVoicemail: ApplicantFollowUpVoicemail,
  FollowupEmail: EmailApplicantFollowUp,
  HiringEmail: EmailHiringCampaign,
  HiringFacebook: FacebookHiringAd,
  OrganicFacebook: FacebookOrganicPost,
  GoogleAd: GoogleCareersListing,
  OrganicInstagram: InstagramOrganicPost,
  InterviewQuestions: InterviewQuestions,
  JobDesc: JobDescription,
  DmLinkedin: LinkedInDirectMessage,
  OrganicLinkedin: LinkedInOrganicPost,
  FollowupText: SMSApplicantFollowUp,
  ReferralText: SMSEmployeeReferral,
  HiringText: SMSHiringCampaign,
  OrganicTiktok: TikTokOrganicPost,
  OrganicTwitter: TwitterOrganicPost,
  SingleUserPlan: SingleUserPlan,
  TeamPlan: TeamPlan,
};
