import { ReactNode, useRef } from 'react';
import { useTableHeaderRow } from 'react-aria';
import type { TableState } from 'react-stately';
import type { GridNode } from '@react-types/grid';

export const HeaderRow = (props: {
  className?: string;
  item: GridNode<unknown>;
  state: TableState<unknown>;
  children: ReactNode;
}) => {
  const { item, state, children, className } = props;
  const ref = useRef(null);
  const { rowProps } = useTableHeaderRow({ node: item }, state, ref);

  return (
    <tr
      {...rowProps}
      ref={ref}
      className={className}
    >
      {children}
    </tr>
  );
};
