import { useEffect, useState } from 'react';
import SubChangesPreview from '../SubChangesPreview';
import {
  BulkCompanyMemberUpsertResponseBoInterface,
  CompanyMemberBoInterface,
  InvitationBoInterface,
  SubscriptionInvoicePreviewBoInterface,
} from '@boostpoint/types';
import SubscriptionUpdated from '../SubscriptionUpdated';
import { useSubscription } from '../providers/SubscriptionProvider/context';
import { Loading } from '@boostpoint/ui';

const UpdatingSubscription = (props: Props) => {
  const {
    memberCount,
    addedUsers,
    removedUsers,
    invitationsToRemove,
    members,
    invitations,
    cancel,
    close,
  } = props;
  const { subscription } = useSubscription();
  const [approved, setApproved] = useState(false);
  const [page, setPage] = useState('preview');
  const [previewErrors, setPreviewErrors] = useState('');
  const { getSubscriptionInvoicePreview, updateSubscription } =
    useSubscription();
  const addedCount = addedUsers.length;
  const removedCount = removedUsers.length;
  const removedInvitations = invitationsToRemove.length;
  const totalNewUsers =
    memberCount + addedCount - removedCount - removedInvitations;
  const [subPreview, setSubPreview] =
    useState<SubscriptionInvoicePreviewBoInterface>();
  const [loadingSubPreview, setLoadingSubPreview] = useState(true);
  const [previewConfirmLoading, setPreviewConfirmLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (totalNewUsers && !subscription?.cancelAt) {
        setLoadingSubPreview(true);
        const subResponse = await getSubscriptionInvoicePreview({
          quantity: totalNewUsers,
        });
        setSubPreview(subResponse);
        setLoadingSubPreview(false);
      }
      if (subscription?.cancelAt) {
        setApproved(true);
        previewConfirm();
      }
    })();
  }, [totalNewUsers, subscription]);

  const previewConfirm = async () => {
    setPreviewConfirmLoading(true);
    try {
      if (!approved) {
        setPreviewErrors('You must confirm the changes before updating.');
        setPreviewConfirmLoading(false);
        return;
      }

      const updateResponse = await updateSubscription({
        quantity: totalNewUsers,
      });
      console.log('updateResponse', updateResponse);
      if (updateResponse) {
        setPage('success');
      }
    } catch (err) {
      let message = 'Unknown error';
      if (err instanceof Error) message = err.message;
      console.log('error updating members', message);
    }
    setPreviewConfirmLoading(false);
  };

  const updateApproved = (newVal: boolean) => {
    setApproved(newVal);
    setPreviewErrors('');
  };

  const handleClose = () => {
    console.log('closing');
    setApproved(false);
    setSubPreview(undefined);
    setPage('preview');
    close(true);
  };

  return (
    <div className='w-full'>
      {loadingSubPreview && !subscription?.cancelAt && (
        <div className='my-8 flex min-h-[200px] w-full items-center justify-center'>
          <Loading
            loading={loadingSubPreview}
            classes='bg-transparent h-[200px] w-[100px]'
          />
        </div>
      )}
      {page === 'preview' && subPreview && (
        <SubChangesPreview
          previousUserCount={subPreview?.previousUserCount}
          newUserCount={subPreview?.newUserCount}
          previousPrice={subPreview?.previousPrice}
          newPrice={subPreview?.currentPrice}
          proratedPrice={subPreview?.proratedPrice}
          renewalDate={subPreview?.renewalDate}
          approved={approved}
          setApproved={updateApproved}
          errors={previewErrors}
          loading={previewConfirmLoading}
          cancel={() => cancel()}
          onContinue={previewConfirm}
        />
      )}
      {page === 'success' && (
        <SubscriptionUpdated
          addedUsers={addedUsers}
          removedUsers={[
            ...members
              .filter(m => removedUsers.includes(m.userId))
              .map(m => m.email),
            ...invitations
              .filter(i => invitationsToRemove.includes(i.id))
              .map(i => i.email),
          ]}
          close={handleClose}
        />
      )}
    </div>
  );
};

type Props = {
  memberCount: number;
  addedUsers: string[];
  removedUsers: string[];
  invitationsToRemove: string[];
  members: CompanyMemberBoInterface[];
  invitations: InvitationBoInterface[];
  cancel: () => void;
  close: (confirmed?: boolean) => void;
  onResultsUpdated(results: BulkCompanyMemberUpsertResponseBoInterface): void;
};
export default UpdatingSubscription;
