import clsx from 'clsx';
import { ReactNode, useRef } from 'react';
import { AriaTextFieldProps, useTextField } from 'react-aria';
import { IoIosWarning } from 'react-icons/io';

interface Props extends AriaTextFieldProps {
  className?: string;
  inputClassName?: string; // For classes adjusting text area directly
  rows?: number;
  icon?: ReactNode;
  button?: ReactNode;
  showWarning?: boolean;
  errorMessage?: string;
}

export const TextArea = (props: Props) => {
  const {
    className,
    inputClassName,
    rows = 8,
    icon,
    button,
    showWarning,
    label,
    description,
    errorMessage,
    isRequired,
    isDisabled,
  } = props;

  const ref = useRef(null);
  const { labelProps, inputProps, descriptionProps, errorMessageProps } =
    useTextField({ ...props, inputElementType: 'textarea' }, ref);

  return (
    <div className={clsx('w-full flex-1', label && 'mt-4', className)}>
      <label
        {...labelProps}
        className='text-sm text-bp-indigo'
      >
        {label}
      </label>

      {description && <div {...descriptionProps}>{description}</div>}

      {/* Override text box boundaries to create our own */}
      <div
        className={clsx(
          'font-weight-bold flex flex-row content-center justify-center gap-2 rounded-md border-[1px] bg-white p-2 px-2',
          !isDisabled &&
            !errorMessage &&
            !showWarning &&
            'border-bp-purple bg-white focus-within:border-purple-800',
          isDisabled && 'bg-bp-lGray',
          errorMessage &&
            'text-border-bp-pink bg-red-50 focus-within:border-bp-pink',
          showWarning &&
            'border-bp-yellow bg-yellow-50 focus-within:border-bp-yellow',
        )}
      >
        <div
          className={clsx(
            'self-center',
            !errorMessage && !showWarning && 'text-slate-300',
            errorMessage && 'text-bp-pink',
            showWarning && 'text-yellow-600',
          )}
        >
          {icon}
        </div>

        <textarea
          {...inputProps}
          rows={rows}
          className={clsx(
            'w-full resize-none bg-transparent !outline-none',
            inputClassName,
          )}
          ref={ref}
          required={isRequired}
        />

        {button}
      </div>

      {errorMessage && (
        <div
          className='flex flex-row items-center justify-end px-4'
          {...errorMessageProps}
        >
          <p className='text-xs text-bp-pink md:text-sm'>
            <IoIosWarning />
          </p>
          <p className='text-xs text-bp-pink md:text-sm'>{errorMessage}</p>
        </div>
      )}
    </div>
  );
};
