import { ReactNode } from 'react';
import { Select, TextArea, TextField } from '.';
import { Item } from 'react-stately';
import { ActionSettingBoInterface, ActionType } from '@boostpoint/types';
import type { Schema } from 'jsonschema';
import clsx from 'clsx';
type Props = {
  actionSettings: ActionSettingBoInterface[];
  requestConfig: any;
  updateConfig: (key: string, val: any) => void;
  actionName: string;
  img?: string;
  className?: string;
  actionType?: ActionType;
};
export const ActionConfig = (props: Props) => {
  const {
    actionSettings,
    requestConfig,
    updateConfig,
    actionName,
    img,
    className,
    actionType,
  } = props;

  const createLayout = () => {
    const configLayout: ReactNode[][] = [[]];
    let itemsInRow = 0;
    for (let i = 0; i < actionSettings.length; i++) {
      const aconf = actionSettings[i];
      const schema = aconf.schema as Schema & { default: any }; //default is a part of the JsonSchema spec, but hasn't been implemented in the js lib
      if (aconf.inputType === 'TEXT_AREA') {
        configLayout.push(
          [
            <div className='flex w-full flex-col'>
              <h4
                className='mb-1 text-left text-lg font-semibold'
                id={`project-wizard-action-config-${actionType?.toLowerCase()}-${aconf.key.toLowerCase()}`}
              >
                {aconf.label}
              </h4>
              <TextArea
                key={aconf.key}
                aria-label={aconf.label}
                isRequired={!!schema?.required}
                defaultValue={requestConfig?.[aconf.key] ?? schema?.default}
                onChange={val => updateConfig(aconf.key, val)}
              />
            </div>,
          ],
          [],
        );
        itemsInRow = 0;
      } else {
        if (itemsInRow >= 2) {
          configLayout.push([]);
          itemsInRow = 0;
        }
        if (aconf.inputType === 'TEXT_FIELD') {
          configLayout[configLayout.length - 1].push(
            <div className='flex w-full flex-col '>
              <h4
                className='mb-1 text-left text-lg font-semibold'
                id={`project-wizard-action-config-${actionType?.toLowerCase()}-${aconf.key.toLowerCase()}`}
              >
                {aconf.label}
              </h4>
              <TextField
                key={aconf.key}
                aria-label={aconf.label}
                isRequired={!!schema?.required}
                defaultValue={requestConfig?.[aconf.key] ?? schema?.default}
                onChange={val => updateConfig(aconf.key, val)}
              />
            </div>,
          );
        } else if (aconf.inputType === 'SINGLE_SELECT' && schema.enum) {
          configLayout[configLayout.length - 1].push(
            <div className='flex w-full flex-col'>
              <h4
                className='-mb-1 text-left text-lg font-semibold'
                id={`project-wizard-action-config-${actionType?.toLowerCase()}-${aconf.key.toLowerCase()}`}
              >
                {aconf.label}
              </h4>
              <Select
                key={aconf.key}
                aria-label={aconf.label}
                isRequired={!!schema?.required}
                defaultSelectedKey={
                  requestConfig?.[aconf.key] ?? schema?.default
                }
                onSelectionChange={val => updateConfig(aconf.key, val)}
                items={[
                  ...schema.enum.map((val: any) => ({
                    id: val,
                    name: schema.type === 'string' ? val.toString() : val,
                  })),
                ]}
              >
                {item => <Item>{item.name.toString()}</Item>}
              </Select>
            </div>,
          );
        }
        itemsInRow++;
      }
    }
    return configLayout.flat();
  };

  const layout = createLayout();

  return (
    <div
      className={clsx(
        'flex min-h-[166px] w-full flex-col gap-6 rounded-2xl border-2 border-solid border-bp-gray bg-white p-[42px]',
        className,
      )}
    >
      <div className='mb-1 flex w-full flex-row items-center gap-4'>
        {!!img && (
          <img
            src={img}
            alt={actionName}
            className='h-[30px] w-[30px]'
          />
        )}
        <h3 className='font-semibold'>{actionName}</h3>
      </div>
      {layout.map((node, index) => (
        <div
          key={actionName + index}
          className='flex w-full flex-col items-center gap-8 md:flex-row'
        >
          {node}
        </div>
      ))}
    </div>
  );
};
