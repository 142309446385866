import {
  SubscriptionBoInterface,
  SubscriptionDtoInterface,
} from '@boostpoint/types';
import { useEffect, useState } from 'react';
import { SubscriptionContext } from './context';
import { useApi } from '../ApiProvider';
import { useCompany } from '../CompanyProvider';
import useSseEvent from '../../hooks/useSseEvent';

const SubscriptionProvider = (props: { children: React.ReactNode }) => {
  const { children } = props;
  const { company } = useCompany();
  const { client, ready } = useApi();
  const [subscription, setSubscription] =
    useState<SubscriptionBoInterface | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { data: subscriptionChangedData, error: subscriptionChangedError } =
    useSseEvent<any>({
      url: company ? `/stripe/${company.id}/sse` : '',
    });

  const fetchSubscription = async () => {
    try {
      setIsLoading(true);
      const sub = await client?.subscription.getSubscription();
      console.log('subscription', sub);
      if (sub) {
        setSubscription(sub);
      }
    } catch (err) {
      let message = 'Unknown error';
      if (err instanceof Error) message = err.message;
      console.log('error getting subscription', message);
    } finally {
      setIsLoading(false);
    }
  };

  const getCheckout = async (s: SubscriptionDtoInterface) => {
    try {
      const checkout = await client.subscription.createCheckout(s);
      if (checkout.url) {
        return checkout;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getSubscriptionInvoicePreview = async (s: SubscriptionDtoInterface) => {
    try {
      const invoice =
        await client.subscription.createSubscriptionInvoicePreview(s);
      if (invoice) {
        return invoice;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const updateSubscription = async (
    s: SubscriptionDtoInterface,
  ): Promise<boolean> => {
    try {
      setIsLoading(true);
      const response = await client.subscription.updateSubscriptionQuantity(s);
      setIsLoading(false);
      return response;
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      return false;
    }
  };

  useEffect(() => {
    if (ready && company) {
      fetchSubscription();
    }
  }, [ready, company]);

  useEffect(() => {
    if (subscriptionChangedData) {
      fetchSubscription();
    }

    if (subscriptionChangedError) {
      console.error('subscription changed error:', subscriptionChangedError);
    }
  }, [subscriptionChangedData, subscriptionChangedError]);

  const wrapped = {
    subscription,
    isLoading,
    fetchSubscription,
    getCheckout,
    getSubscriptionInvoicePreview,
    updateSubscription,
  };

  return (
    <SubscriptionContext.Provider value={wrapped}>
      {children}
    </SubscriptionContext.Provider>
  );
};

export default SubscriptionProvider;
