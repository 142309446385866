import {
  CompanyBoInterface,
  CompanyInvitationBoInterface,
  MemberRole,
  SubscriptionBoInterface,
  UserBoInterface,
} from '@boostpoint/types';
import { Loading, TextButton } from '@boostpoint/ui';
import { useState } from 'react';
import { BiSolidChevronUp } from 'react-icons/bi';
import { Link } from 'react-router-dom';
export const CompanySlideout = (props: Props) => {
  const {
    open,
    company,
    user,
    invitations,
    notifications,
    subscription,
    setOpen,
    hasRole,
    getBillingPortal,
  } = props;
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [loadingBilling, setLoadingBilling] = useState(false);

  const handleBilling = async () => {
    if (subscription && subscription?.subscriptionExists) {
      setLoadingBilling(true);
      const url = await getBillingPortal();
      window.open(url, '_self');
    }
  };

  return (
    <div
      className={`${
        !open && 'hidden'
      } border-bg-gray absolute right-[25px] top-[94px] z-30 h-[375px] w-[280px] overflow-hidden rounded-2xl border-[2px] border-solid bg-white`}
    >
      <div className='flex h-full flex-col p-8'>
        <div className='mb-4 flex flex-row gap-4'>
          <div className='flex h-[40px] min-w-[40px] items-center justify-center rounded-full border-[1px] border-solid border-bp-dkGray text-[20px] font-semibold md:h-[50px] md:min-w-[50px]'>
            {`${user?.firstName.charAt(0).toUpperCase() || ''}${
              user?.lastName.charAt(0).toUpperCase() || ''
            }`}
          </div>
          <div className='flex flex-col items-start justify-center text-[14px]'>
            <div className='cursor-pointer font-medium'>
              {`${user?.firstName || ''} ${user?.lastName || ''}`}{' '}
            </div>
            <div>{company?.name}</div>
          </div>
        </div>
        <div className='flex grow-[2] flex-col items-start justify-between'>
          <ul className='w-full text-[16px]'>
            <li
              className='my-2 flex w-full cursor-pointer justify-between'
              onClick={() => setNotificationsOpen(!notificationsOpen)}
            >
              <span>Notifications</span>
              <span className='inline-flex items-center gap-1 text-[16px]'>
                <span className='flex h-5 w-5 items-center justify-center rounded-md bg-bp-pink text-xs font-medium text-white'>
                  {(invitations?.length ?? 0) + notifications.length}
                </span>
                <BiSolidChevronUp
                  className={`${
                    notificationsOpen ? 'md:-rotate-90' : 'md:rotate-90'
                  } transition-500 mt-[4px] rotate-180 text-bp-green transition-all ease-in-out`}
                />
              </span>
            </li>
            <li className='my-2'>
              <Link
                to='settings/profile'
                onClick={() => setOpen(false)}
                id='profile-link'
              >
                Profile
              </Link>
            </li>
            {/* Admins only */}
            {hasRole(MemberRole.Admin) && (
              <li className='my-2'>
                <Link
                  to='settings/users'
                  onClick={() => setOpen(false)}
                  id='users-link'
                >
                  Users
                </Link>
              </li>
            )}
            {hasRole(MemberRole.Owner) && (
              <li
                className={`relative my-2 flex items-center gap-1 
                ${loadingBilling ? 'cursor-wait' : ''} ${
                  subscription && subscription?.subscriptionExists
                    ? 'cursor-pointer'
                    : 'cursor-not-allowed opacity-50'
                }`}
                onClick={handleBilling}
              >
                <span>Plans & Billing</span>
                {loadingBilling && (
                  <Loading
                    loading={true}
                    smaller={true}
                    classes='bg-transparent relative max-w-[30px] top-[10px]'
                  />
                )}
              </li>
            )}
          </ul>
          <Link
            to='/logout'
            onClick={() => setOpen(false)}
            id='logout'
          >
            <TextButton>Logout</TextButton>
          </Link>
        </div>
      </div>
      <div
        className={`absolute ${
          notificationsOpen ? 'left-0' : 'left-[280px]'
        } top-0 h-[375px] w-[280px] overflow-y-auto bg-white p-6 transition-[left] duration-[500ms] ease-in-out`}
      >
        <p
          className='-ml-[3px] mb-3 flex cursor-pointer flex-row items-center justify-start gap-2 font-medium'
          onClick={() => setNotificationsOpen(!notificationsOpen)}
        >
          <BiSolidChevronUp
            className={`${
              notificationsOpen ? 'md:-rotate-90' : 'md:rotate-90'
            } transition-500 mt-[4px] rotate-180 text-bp-green transition-all ease-in-out`}
          />{' '}
          <span>Notifications</span>
        </p>
        <div className='flex grow-[2] flex-col'>
          {/* Invitation */}
          {invitations?.map(invitation => (
            <div className='my-2 flex flex-col gap-1 border-b-[1px]'>
              <p className='text-sm font-semibold'>
                Invite: Join {invitation.companyName}'s Workspace
              </p>
              <p className='pb-4 text-sm text-bp-pink underline'>
                <Link
                  to='settings/profile'
                  onClick={() => setOpen(false)}
                >
                  View
                </Link>
              </p>
            </div>
          ))}

          {/* Other notifications */}
          {/* TODO: These aren't actually implemented yet */}
          {/* {notifications.map((notification, index) => (
            <div
              key={index}
              className='my-2 flex flex-col gap-1'
            >
              <p>{notification}</p>
              <p className='flex flex-row gap-2 text-[14px] text-bp-pink underline'>
                <Link to='#'>View</Link>
                <Link to='#'>Dismiss</Link>
              </p>
            </div>
          ))} */}
        </div>
      </div>
    </div>
  );
};

type Props = {
  open: boolean;
  setOpen: (b: boolean) => void;
  user?: UserBoInterface;
  company: CompanyBoInterface;
  invitations?: CompanyInvitationBoInterface[];
  notifications: any[];
  subscription?: SubscriptionBoInterface;
  hasRole: (role: MemberRole) => boolean;
  getBillingPortal: () => Promise<string>;
};
