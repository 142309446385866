import { ReactNode, useEffect, useRef, useState } from 'react';
import { MdOutlineClose } from 'react-icons/md';
import { MCDialogContext } from './context';
// import ClickOutsideWrapper from "../../containers/ClickOutsideWrapper/ClickOutsideWrapper";

const MCDialog = (props: MCDProps) => {
  const { content, open, setOpen } = props;
  const dialogRef = useRef<HTMLDialogElement>(null);
  useEffect(() => {
    if (open && !dialogRef.current?.open) {
      dialogRef.current?.showModal();
    } else if (!open) {
      dialogRef.current?.close();
    }
  }, [open]);

  return (
    <dialog
      className={`absolute top-[60dvh] h-[40dvh] w-full ${
        !open && 'hidden'
      } flex flex-col items-center gap-4 overflow-hidden rounded-t-xl bg-white backdrop:backdrop-brightness-75`}
      ref={dialogRef}
    >
      {/* TODO: click outside currently disables the scroll */}
      {/* <ClickOutsideWrapper
        onClickOutside={() => {
          setOpen(false);
        }}
      > */}
      <div className='relative mb-2 flex w-full flex-row items-center justify-between  gap-3 border-b-2 border-bp-gray '>
        <div className='mr-9 flex w-full flex-col pb-1'>
          {content?.header && (
            <h4 className='font-semibold'>{content?.header}</h4>
          )}
          {content?.subHeader && (
            <p className='truncate whitespace-nowrap text-sm'>
              {content.subHeader}
            </p>
          )}
        </div>
        <button
          className=' absolute right-0 top-0 flex h-7 w-7 items-center justify-center overflow-hidden rounded-full bg-bp-gray'
          onClick={() => {
            setOpen(false);
          }}
        >
          <MdOutlineClose />
        </button>
      </div>

      {content?.actionGroups && (
        <div className='flex w-full flex-col overflow-y-auto overflow-x-hidden'>
          {content.actionGroups.map((aGroup, i) => (
            <div
              key={i}
              className='flex w-full flex-col gap-1'
            >
              {aGroup.map((action, j) => (
                <button
                  key={action.label || `${i}${j}`}
                  className='w-full bg-bp-gray p-2 first:rounded-t-md last:rounded-b-md'
                  onClick={() => {
                    action.action();
                    setOpen(false);
                  }}
                >
                  {action.icon}
                  <p>{action.label}</p>
                </button>
              ))}
            </div>
          ))}
        </div>
      )}
      {/* </ClickOutsideWrapper> */}
    </dialog>
  );
};

const MobileContextProvider = (props: { children: ReactNode }) => {
  const { children } = props;
  const [open, setMCOpen] = useState(false);
  const [content, setContent] = useState<MCDContent>();

  const setMCDContent = async (c: MCDContent) => {
    setContent(c);
  };

  const wrapped = {
    open,
    setMCOpen,
    setMCDContent,
  };

  return (
    <MCDialogContext.Provider value={wrapped}>
      {children}
      <MCDialog
        open={open}
        setOpen={setMCOpen}
        content={content}
      />
    </MCDialogContext.Provider>
  );
};

type MCDProps = {
  content?: MCDContent;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

type MCDContent = {
  header?: string;
  subHeader?: string;
  actionGroups?: { label?: string; icon?: ReactNode; action: () => void }[][];
};

export default MobileContextProvider;
