import { Checkbox, PrimaryButton, SecondaryButton } from '@boostpoint/ui';

const SubChangesPreview = (props: Props) => {
  const {
    previousUserCount,
    newUserCount,
    previousPrice,
    proratedPrice,
    newPrice,
    renewalDate,
    approved,
    setApproved,
    onContinue,
    cancel,
    loading,
    errors,
  } = props;

  const userDelta = newUserCount - previousUserCount;
  return (
    <div
      className={`block w-full flex-col items-start justify-between gap-4 xl:flex`}
    >
      <div className='mt-8'>
        <h2 className='text-[24px] font-semibold md:text-[32px]'>
          Update subscription plan?
        </h2>
        <p className=''>
          These edits will result in the following subscription updates:
        </p>
      </div>
      <div className='flex gap-20'>
        <div>
          <h3 className='text-4 underline'>Current</h3>
          <p>
            <span className='font-semibold'>Users:</span> {previousUserCount}
          </p>
          <p>
            <span className='font-semibold'>Subscription:</span> $
            {previousPrice / 100}/month
          </p>
        </div>
        <div>
          <h3 className='text-4 underline'>Updated</h3>
          <p>
            <span className='font-semibold'>Users:</span>{' '}
            <span className='text-bp-pink'>{newUserCount}</span>
          </p>
          <p>
            <span className='font-semibold'>Subscription:</span>{' '}
            <span className='text-bp-pink'>${newPrice / 100}/month</span>
          </p>
        </div>
      </div>
      <p>
        {' '}
        The {userDelta > 0 ? 'addition' : 'removal'} of {Math.abs(userDelta)}{' '}
        user{Math.abs(userDelta) === 1 ? '' : 's'} will result in a{' '}
        <b>one time prorated {userDelta > 0 ? 'fee' : 'credit'}</b>. With this{' '}
        {userDelta > 0 ? 'fee' : 'credit'}, the total of your next bill on{' '}
        <b>{renewalDate?.toLocaleDateString()}</b> will be{' '}
        <b>${proratedPrice / 100}</b>.{' '}
      </p>
      <div className='mt-4 flex gap-2'>
        <Checkbox
          name='confirm'
          onChange={setApproved}
          isSelected={approved}
          id='subscription-approve'
        >
          <span>I approve these changes to my subscription</span>
        </Checkbox>
      </div>
      {!!errors && <p className='text-xs text-bp-pink md:text-sm'>{errors}</p>}
      <div className='mt-8 flex gap-4'>
        <SecondaryButton
          isDisabled={loading}
          onPress={cancel}
        >
          Cancel
        </SecondaryButton>
        <PrimaryButton
          isLoading={loading}
          onPress={onContinue}
          id='update-plan'
        >
          Update Plan
        </PrimaryButton>
      </div>
    </div>
  );
};

type Props = {
  previousUserCount: number;
  newUserCount: number;
  previousPrice: number;
  newPrice: number;
  proratedPrice: number;
  renewalDate: Date;
  approved: boolean;
  setApproved: (approved: boolean) => void;
  cancel: () => void;
  onContinue: () => void;
  loading: boolean;
  errors?: string;
};

export default SubChangesPreview;
