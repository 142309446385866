import { ReactNode, useRef } from 'react';
import { AriaOverlayProps, DismissButton, useOverlay } from 'react-aria';

interface Props extends AriaOverlayProps {
  buttons?: ReactNode;
  children?: ReactNode;
}

export const ActionOverlay = (props: Props) => {
  const { buttons, children, isOpen, onClose } = props;

  const ref = useRef(null);
  const { overlayProps, underlayProps } = useOverlay(
    { ...props, isDismissable: props.isDismissable ?? true },
    ref,
  );

  return (
    <>
      <div
        className={`${
          isOpen ? 'opacity-100' : 'opacity-0'
        } pointer-events-none absolute bottom-0 left-0 top-0 z-50 flex h-full w-full flex-col justify-end overflow-hidden bg-[#0108143C] transition-all delay-150 duration-700`}
      />
      <div
        className={`${
          isOpen ? 'top-[0px]' : 'top-[100dvh]'
        } absolute bottom-0 left-0 top-0 z-50 flex h-full w-full flex-col justify-end overflow-hidden transition-all duration-700`}
      >
        <div
          {...underlayProps}
          className='underlay'
        />
        <div
          {...overlayProps}
          ref={ref}
          className='z-50 mt-16 flex flex-1 flex-col overflow-hidden rounded-t-2xl border-[1px] bg-white'
        >
          <DismissButton onDismiss={onClose} />

          <div className='flex flex-1 flex-col overflow-y-auto md:flex-row md:overflow-hidden'>
            {children}
          </div>

          {buttons && (
            <div className='flex justify-center border-t-[1px] p-4 md:justify-start'>
              {buttons}
            </div>
          )}

          <DismissButton onDismiss={onClose} />
        </div>
      </div>
    </>
  );
};
