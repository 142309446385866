import { ReactNode, useEffect, useRef, useState } from 'react';
import { DialogContext } from './context';
import { Dialog } from '@boostpoint/ui';

const DialogElement = (props: DProps) => {
  const { children, open } = props;
  const dialogRef = useRef<HTMLDialogElement>(null);
  useEffect(() => {
    if (open && !dialogRef.current?.open) {
      dialogRef.current?.showModal();
    } else if (!open) {
      dialogRef.current?.close();
    }
  }, [open]);

  return (
    <Dialog
      ref={dialogRef}
      open={open}
    >
      {children}
    </Dialog>
  );
};

const DialogProvider = (props: { children: ReactNode }) => {
  const { children } = props;
  const [open, setOpen] = useState(false);
  const [dialogChild, setDialogChild] = useState<ReactNode>();

  const setDialog = async (dialog: ReactNode) => {
    setDialogChild(dialog);
  };

  const wrapped = {
    dialogOpen: open,
    setDialogOpen: setOpen,
    setDialog,
  };

  return (
    <DialogContext.Provider value={wrapped}>
      {children}
      <DialogElement open={open}>{dialogChild}</DialogElement>
    </DialogContext.Provider>
  );
};

type DProps = {
  children: ReactNode;
  open: boolean;
};

export default DialogProvider;
