import {
  BulkCompanyMemberUpsertDtoInterface,
  BulkCompanyMemberUpsertResponseBoInterface,
  CompanyBoInterface,
  CompanyMemberBoInterface,
  CompanyUpsertDtoInterface,
  MemberRole,
} from '@boostpoint/types';
import { createContext, useContext } from 'react';

export const CompanyContext = createContext<any>(null);

export const useCompany = () => {
  const company = useContext<{
    refreshCompany: () => Promise<void>;
    updateCompany: (companyUpdate: CompanyUpsertDtoInterface) => Promise<void>;
    fetchMembers: () => Promise<CompanyMemberBoInterface[]>;
    updateMembers: (
      data: BulkCompanyMemberUpsertDtoInterface,
    ) => Promise<BulkCompanyMemberUpsertResponseBoInterface>;
    changeRole: (
      userId: string,
      role: MemberRole,
    ) => Promise<CompanyMemberBoInterface>;
    hasRole: (role: MemberRole) => boolean;
    company: CompanyBoInterface;
    role: MemberRole | null;
    isLoadingRole: boolean;
    isLoading: boolean;
  }>(CompanyContext);
  if (!company) {
    throw new Error(
      `You must call useCompany() inside of an <CompanyProvider />`,
    );
  }
  return company;
};
