import { ActionPreviewCard } from '../ActionPreviewCard';

interface Props {
  companyName?: string;
  result: any;
}

export const OrganicTiktokPreview = (props: Props) => {
  const { companyName } = props;
  const { post, hashtags } = props.result as {
    post?: string;
    hashtags?: string[];
  };
  return (
    <ActionPreviewCard>
      <div className='flex h-full flex-row gap-2 overflow-y-scroll p-8 md:w-96'>
        <div className='flex flex-col'>
          <div className='bg-sidebar h-10 w-10 rounded-full bg-bp-gray bg-cover bg-center' />
        </div>
        <div className='flex flex-col gap-2'>
          <div className='flex flex-col'>
            <h3 className='text-gray-400'>
              <b>{companyName}</b> {companyName?.toLowerCase()}
            </h3>
            <p className='text-left text-sm'>{post}</p>
            <div className='flex flex-wrap overflow-hidden '>
              {hashtags?.map(tag => (
                <p className='mr-1 text-left text-sm text-bp-indigo'>{tag}</p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </ActionPreviewCard>
  );
};
