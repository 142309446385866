import clsx from 'clsx';
import { KeyboardEvent, useState } from 'react';
import { IoCheckmarkSharp, IoClose } from 'react-icons/io5';
import { TextField } from '.';

interface Props {
  value?: string;
  className?: string;
  onCancel?(): void;
  onSave?(value: string): void;
}

export const EditableTextV2 = (props: Props) => {
  const { value, className, onCancel, onSave } = props;

  const [_value, setValue] = useState(value || '');

  const handlePressSave = () => {
    onSave && onSave(_value);
  };

  const handlePressCancel = () => {
    setValue(value || '');
    onCancel && onCancel();
  };

  const handleKeyUp = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      handlePressSave();
    }

    if (event.key === 'Escape') {
      handlePressCancel();
    }
  };

  const handleChange = (value: string) => {
    setValue(value);
  };

  return (
    <div className={clsx('flex items-center', className)}>
      <TextField
        value={_value}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
      />

      <div className='pl-2 text-bp-green'>
        <IoCheckmarkSharp
          onClick={handlePressSave}
          className='cursor-pointer stroke-[.5]'
          fontSize='1.2em'
        />
      </div>

      <div className='pl-2 text-bp-red'>
        <IoClose
          className='cursor-pointer stroke-[.5]'
          fontSize='1.2em'
          onClick={handlePressCancel}
        />
      </div>
    </div>
  );
};
