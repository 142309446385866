import { BiComment, BiLike, BiShare } from 'react-icons/bi';
import { ActionPreviewCard } from '../ActionPreviewCard';

interface Props {
  companyName?: string;
  result: any;
}

export const AdCampaignPreview = (props: Props) => {
  const { companyName } = props;
  const { headline, opening, closing, benefits } = props.result as {
    headline?: string;
    opening?: string;
    closing?: string;
    benefits?: string[];
  };
  return (
    <ActionPreviewCard>
      <div className='flex h-full flex-col gap-2 overflow-y-scroll p-8 md:w-96'>
        <div className='flex flex-row items-center gap-2'>
          <div className='bg-sidebar h-12 w-12 rounded-full bg-bp-gray bg-cover bg-center' />
          <div className='flex flex-col'>
            <h3 className='text-lg font-medium text-indigo-900'>
              {companyName}
            </h3>
            <p className='-mt-2 text-sm text-gray-400'>Sponsored</p>
          </div>
        </div>
        <div className='flex flex-col gap-3'>
          <p className='text-left text-sm'>{opening}</p>
          <div className='flex flex-col gap-0'>
            {benefits &&
              Array.isArray(benefits) &&
              benefits.map((b: string) => (
                <p
                  key={b}
                  className='m-0 text-left  text-sm'
                >
                  ✅ {b}
                </p>
              ))}
          </div>
          <p className='text-left  text-sm'>{closing}</p>
        </div>
        <div className='flex flex-row justify-between gap-2'>
          <div className='flex flex-col'>
            <p className='text-left text-sm text-gray-400'>FORM ON FACEBOOK</p>
            <p className='text-left text-sm  font-bold'>{headline}</p>
          </div>
          <div className='my-auto flex items-center justify-center rounded-lg bg-gray-200 px-4 py-3'>
            <p className='whitespace-nowrap text-sm font-bold'>APPLY NOW</p>
          </div>
        </div>
        <div className='flex flex-row justify-between border-t-2 border-gray-400 px-4 pt-2'>
          <p className='flex flex-row items-center gap-1 text-sm text-gray-500'>
            <BiLike /> Like
          </p>
          <p className='flex flex-row items-center gap-1 text-sm text-gray-500'>
            <BiComment /> Comment
          </p>
          <p className='flex flex-row items-center gap-1 text-sm text-gray-500'>
            <BiShare /> Share
          </p>
        </div>
      </div>
    </ActionPreviewCard>
  );
};
