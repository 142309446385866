import { createBrowserRouter, redirect } from 'react-router-dom';
import Login from '../../../pages/Login';
import PasswordReset from '../../../pages/PasswordReset';

export const unauthenticatedRouter = createBrowserRouter(
  [
    {
      path: '/',
      loader: async () => {
        return redirect('/login');
      },
    },
    {
      path: 'login/:register?',
      element: <Login />,
    },
    {
      path: 'password-reset',
      element: <PasswordReset />,
    },
    {
      path: 'accept-invitation',
      element: <Login />,
    },
    {
      path: '*',
      element: <Login />,
    },
  ],

  {
    future: {
      // Normalize `useNavigation()`/`useFetcher()` `formMethod` to uppercase
      v7_normalizeFormMethod: true,
    },
  },
);
