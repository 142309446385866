import { Key, useState } from 'react';
import { Item, Section, useOverlayTriggerState } from 'react-stately';
import {
  ActionMenuButton,
  EditableTextV2,
  Modal,
  ModalDialog,
  PrimaryButton,
  SecondaryButton,
} from '.';

interface Props {
  name?: string;
  onRename?(name: string): void;
  onArchive?(): Promise<void>;
}

export const ProjectTitleV2 = (props: Props) => {
  const { name, onRename, onArchive } = props;

  const [isRenaming, setIsRenaming] = useState(false);
  const [isArchiving, setIsArchiving] = useState(false);
  const confirmArchiveDialogState = useOverlayTriggerState({});

  const actions: { key: string; label: string }[] = [
    onRename && {
      key: 'rename',
      label: 'Rename',
    },
    onArchive && {
      key: 'archive',
      label: 'Archive',
    },
  ].filter(Boolean) as { key: string; label: string }[];

  const handleAction = (key: Key) => {
    switch (key) {
      case 'rename':
        setIsRenaming(true);
        break;
      case 'archive':
        confirmArchiveDialogState.open();
        break;
    }
  };

  const handleSaveRename = (name: string) => {
    setIsRenaming(false);
    onRename && onRename(name);
  };

  const handleCancelRename = () => {
    setIsRenaming(false);
  };

  const handleConfirmArchive = async () => {
    setIsArchiving(true);
    onArchive && (await onArchive());
    confirmArchiveDialogState.close();
    setIsArchiving(false);
  };

  return (
    <>
      {!isRenaming && (
        <div className='flex items-center text-xl font-semibold'>
          <span className='flex-grow'>{name}</span>

          <ActionMenuButton
            buttonId='project-action-button'
            popoverId='project-action-popover'
            onAction={handleAction}
            isLoading={isArchiving} // If we add more actions, we'll need to update this
          >
            <Section>
              {actions.map(action => (
                <Item key={action.key}>
                  <p id={`project-action-${action.key}`}>{action.label}</p>
                </Item>
              ))}
            </Section>
          </ActionMenuButton>
        </div>
      )}

      {isRenaming && (
        <EditableTextV2
          value={name}
          onCancel={handleCancelRename}
          onSave={handleSaveRename}
        />
      )}

      {confirmArchiveDialogState.isOpen && (
        <Modal
          state={confirmArchiveDialogState}
          isDismissable
        >
          <ModalDialog>
            <div className='py-4'>
              <h2 className='text-[24px] font-semibold md:text-[32px]'>
                Are you sure?
              </h2>
              <p>This can't be undone!</p>
            </div>

            <div className='flex justify-end gap-2'>
              <SecondaryButton onPress={confirmArchiveDialogState.close}>
                Cancel
              </SecondaryButton>
              <PrimaryButton
                onPress={handleConfirmArchive}
                isLoading={isArchiving}
              >
                Confirm
              </PrimaryButton>
            </div>
          </ModalDialog>
        </Modal>
      )}
    </>
  );
};
