import { ReactNode } from 'react';

interface Props {
  children?: ReactNode;
}

export const ActionPreviewCard = (props: Props) => {
  const { children } = props;

  return (
    <div className='flex h-full overflow-hidden rounded-xl border-[1px] border-bp-gray bg-white md:w-96 md:flex-shrink-0 md:flex-grow-0'>
      {children}
    </div>
  );
};
