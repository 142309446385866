import { ActionPreviewCard } from '../ActionPreviewCard';

interface Props {
  result: any;
}

export const TitlesPreview = (props: Props) => {
  const { titles } = props.result as {
    titles?: string[];
  };
  return (
    <ActionPreviewCard>
      <div className='flex h-full max-h-[40rem] flex-col gap-2 overflow-y-scroll px-8 py-6 md:w-96'>
        {titles?.map(title => (
          <div className='flex flex-col gap-2 border-b-[1px] border-bp-gray py-2 last:border-b-0'>
            <h3 className='text-lg font-medium leading-5 text-indigo-900 '>
              {title}
            </h3>
          </div>
        ))}
      </div>
    </ActionPreviewCard>
  );
};
