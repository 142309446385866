import { BulkOperationResultBoInterface } from '@boostpoint/types';
import {
  PrimaryButton,
  SecondaryButton,
  TextButton,
  TextField,
} from '@boostpoint/ui';

const Invite = (props: Props) => {
  const { users, result, setUsers, close, next, loading } = props;

  const emailErrors = (email: string) => {
    if (!result?.hasErrors) {
      return [];
    }

    return (
      result.items.find(item => item.data === email)?.errors?.join('\n') ?? ''
    );
  };

  const addUser = () => {
    setUsers([...users, '']);
  };

  const editUser = (index: number, value: string) => {
    const newUsers = [...users];
    newUsers[index] = value;
    setUsers(newUsers);
  };

  const removeUser = (index: number) => {
    const newUsers = [...users];
    newUsers.splice(index, 1);
    setUsers(newUsers);
  };

  return (
    <div
      className={`block w-full flex-col items-start justify-between xl:flex`}
    >
      <div>
        <h2 className='text-[24px] font-semibold md:text-[32px]'>
          Add your team members
        </h2>
      </div>
      <div className='mb-4 w-full'>
        <div className='mb-6 flex w-full justify-end'>
          <PrimaryButton onPress={addUser}>+ User</PrimaryButton>
        </div>
        <div className='flex max-h-[400px] flex-col overflow-y-scroll py-4'>
          {users.map((user, i) => (
            <div className='border-t-bp-grey border-t-[1px] border-solid py-4'>
              <div className='flex flex-row justify-between gap-16'>
                <TextField
                  value={user}
                  placeholder='email@example.com'
                  onChange={(value: string) => editUser(i, value)}
                  className='max-w-[500px]'
                />
                <TextButton onPress={() => removeUser(i)}>Cancel</TextButton>
              </div>
              <div className='pt-1 text-bp-pink'>{emailErrors(user)}</div>
            </div>
          ))}
        </div>
      </div>
      <div className='mt-8 flex w-full gap-2'>
        <SecondaryButton onPress={close}>Back</SecondaryButton>
        <PrimaryButton
          onPress={next}
          isLoading={loading}
        >
          Confirm
        </PrimaryButton>
      </div>
    </div>
  );
};

type Props = {
  users: string[];
  setUsers: (users: string[]) => void;
  close: () => void;
  next: () => void;
  loading?: boolean;
  result?: BulkOperationResultBoInterface;
};

export default Invite;
