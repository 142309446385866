import type { ActionBoInterface } from '@boostpoint/types';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { IoCloseSharp } from 'react-icons/io5';
import { DotsBrick } from './DotsBrick';

export const ActionCard = (props: Props) => {
  const { action, img, selected, ready, onSelect } = props;

  const select = () => {
    onSelect(action);
  };

  return (
    <button
      className={`flex min-h-[166px] w-full flex-col rounded-2xl border-2 border-solid bg-white py-[16px] pl-[42px] pr-[20px] ${
        selected ? 'border-bp-green' : 'border-bp-gray'
      } ${ready === false ? 'cursor-progress' : 'cursor-pointer'}`}
      role='button'
      disabled={ready === false}
      onClick={select}
    >
      <div className='flex h-[44px] w-full flex-row justify-between'>
        <div className='flex items-end justify-end'>
          {!!img && (
            <img
              src={img}
              alt={action.name}
              className='h-[30px] w-[30px]'
            />
          )}
        </div>
        <div className='flex items-start justify-start'>
          {selected ? (
            <div className='mr-[4px] mt-[4px]'>
              <div
                className={`h-[12px] w-[12px] rounded-full bg-bp-green before:block before:h-[12px] before:w-[12px] before:rounded-full before:outline before:outline-2 before:outline-offset-2 before:outline-bp-green before:content-['']`}
              />
            </div>
          ) : selected !== undefined ? (
            <IoCloseSharp
              color='#DFD8E3'
              fontSize='1.5em'
            />
          ) : ready ? (
            <BsFillCheckCircleFill
              color='#64EDC2'
              fontSize='1.3em'
            />
          ) : (
            <div className='mr-[12px] mt-[8px]'>
              <DotsBrick />
            </div>
          )}
        </div>
      </div>
      <p className='text-left text-[20px] font-semibold'>{action.name}</p>
      <p className='text-left text-[16px]'>{action.description}</p>
    </button>
  );
};

type Props = {
  action: ActionBoInterface;
  selected?: boolean;
  ready?: boolean;
  img: string;
  onSelect: (action: ActionBoInterface) => void;
};
