import { PromptType } from '@boostpoint/types';
import {
  CardWrapper,
  PrimaryButton,
  Select,
  TextField,
  WizardStepTitle,
} from '@boostpoint/ui';
import CardTitle from '@boostpoint/ui/src/Layout/CardTitle';
import { useEffect, useState } from 'react';
import { MdNoteAdd, MdSpoke } from 'react-icons/md';
import { Item } from 'react-stately';

interface Props {
  name?: string;
  promptType?: PromptType;
  onContinue?(name: string, projectType: PromptType): void;
  onArchive?(): Promise<void>;
}

const ProjectWizardNameStep = (props: Props) => {
  const {
    name: initialName,
    promptType: initialPromptType,
    onContinue,
    onArchive,
  } = props;

  const [name, setName] = useState(initialName ?? '');
  const [promptType, setPromptType] = useState<PromptType | undefined>(
    initialPromptType,
  );
  const [nameError, setNameError] = useState<string | undefined>();
  const [scopeError, setScopeError] = useState<string | undefined>();

  useEffect(() => {
    setName(initialName ?? '');
  }, [initialName]);

  useEffect(() => {
    setPromptType(initialPromptType);
  }, [initialPromptType]);

  const handleContinue = () => {
    if (!name) {
      setNameError('Please enter a name before continuing.');
      return;
    }
    if (!promptType) {
      setScopeError("Please determine your project's scope.");
      return;
    }
    onContinue && onContinue(name, promptType);
  };

  const onChange = (value: string) => {
    setName(value);
    setNameError(undefined);
    setScopeError(undefined);
  };

  return (
    <>
      <div>
        <WizardStepTitle
          id='project-wizard-name-header'
          onArchive={onArchive}
        >
          Your project overview
        </WizardStepTitle>
        <div className='flex flex-col gap-8 lg:flex-row'>
          <CardWrapper className='mt-16 max-w-[1000px]'>
            <CardTitle
              icon={<MdNoteAdd fontSize='24px' />}
              title='Project Title'
              id='project-wizard-name-title'
              required
            />
            <p className='mb-4 mt-1'>Give your project a name.</p>
            <TextField
              value={name}
              onChange={onChange}
              aria-label='Project Title'
              errorMessage={nameError}
              isRequired
            />
          </CardWrapper>
          <CardWrapper className='mt-16 max-w-[1000px]'>
            <CardTitle
              title='Project Type'
              icon={<MdSpoke fontSize='24px' />}
              id='project-wizard-name-type'
              required
            />
            <p className='mb-4 mt-1'>
              Are you creating content for a specific position?
            </p>
            <Select
              errorMessage={scopeError}
              aria-label='Are you creating content for a specific position?'
              selectedKey={promptType}
              onSelectionChange={val =>
                setPromptType(val.toString() as PromptType)
              }
              items={[
                {
                  id: PromptType.OpenPosition,
                  name: 'Yes',
                },
                {
                  id: PromptType.General,
                  name: 'No',
                },
              ]}
            >
              {item => <Item>{item?.name}</Item>}
            </Select>
          </CardWrapper>
        </div>
      </div>
      <div className='mt-16'>
        <PrimaryButton onPress={handleContinue}>Continue</PrimaryButton>
      </div>
    </>
  );
};

export default ProjectWizardNameStep;
