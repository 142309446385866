import {
  ActionBoInterface,
  ActionJobBoInterface,
  ActionJobDoneEventBoInterface,
  JobStatus,
  ActionRequestBoInterface,
  ActionResultBoInterface,
  ActionResultFeedbackPostDtoInterface,
  ActionResultRewritePostDtoInterface,
  RewriteJobBoInterface,
} from '@boostpoint/types';
import {
  ActionCard,
  ActionOverlay,
  GenericOverlay,
  PrimaryButton,
  ProjectTitleV2,
} from '@boostpoint/ui';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useOverlayTriggerState } from 'react-stately';
import { useCompany } from '../providers/CompanyProvider';
import { useDialog } from '../providers/DialogProvider';
import { useProjects } from '../providers/ProjectProvider';
import { useUser } from '../providers/UserProvider';
import { actionTypeMetaMap } from '../constants/actionTypeMeta.map';
import useActionRequest from '../hooks/useActionRequest';
import useActionResult from '../hooks/useActionResult';
import useActions from '../hooks/useActions';
import useSseEvent from '../hooks/useSseEvent';

const ProjectView = () => {
  const { projectId, requestId } = useParams();
  const navigate = useNavigate();
  const { company } = useCompany();
  const { user } = useUser();
  const { state } = useLocation();
  const { setDialogOpen, setDialog } = useDialog();

  const { isOpen, open, close } = useOverlayTriggerState({
    defaultOpen: false,
  });
  const {
    projects,
    updateProject,
    archiveProject,
    getActionJobs,
    getRewriteJobs,
    isLoading,
  } = useProjects();
  const project = projects.find(project => project.id === projectId);

  const { actionRequests } = useActionRequest({ projectId });
  const { actions } = useActions();
  const [openActionRequest, setOpenActionRequest] =
    useState<ActionRequestBoInterface>();
  const [openAction, setOpenAction] = useState<{
    img?: string;
    action?: ActionBoInterface;
  }>();
  const [requestIds, setRequestIds] = useState<string[]>([]);
  const { getResults, requestRewrite, sendFeedback } = useActionResult();
  const [actionResults, setActionResults] = useState<ActionResultBoInterface[]>(
    [],
  );
  const [actionJobs, setActionJobs] = useState<ActionJobBoInterface[]>([]);
  const [rewriteJobs, setRewriteJobs] = useState<RewriteJobBoInterface[]>([]);

  const [resultIndex, setResultIndex] = useState(0);

  const { data: jobDoneData, error: jobDoneError } =
    useSseEvent<ActionJobDoneEventBoInterface>({
      url: company?.id ? `/jobs/actionJob/${company?.id}` : '',
    });

  const { data: rewriteJobDoneData, error: rewriteJobDoneError } =
    useSseEvent<ActionJobDoneEventBoInterface>({
      url: company?.id ? `/jobs/rewriteJob/${company?.id}` : '',
    });

  useEffect(() => {
    if (jobDoneData || rewriteJobDoneData) {
      refreshResults();
      fetchActionJobs();
      fetchRewriteJobs();
    }
    if (jobDoneError) {
      console.debug('Job Error: ', jobDoneError);
    }
    if (rewriteJobDoneError) {
      console.debug('Job Error: ', rewriteJobDoneError);
    }
  }, [jobDoneData, jobDoneError, rewriteJobDoneData, rewriteJobDoneError]);

  useEffect(() => {
    if (!project && !isLoading && company?.id) {
      navigate('/create');
    }
  }, [project, isLoading, company]);

  useEffect(() => {
    if (state?.showPopup) {
      setDialog(
        <div
          className={`block w-full flex-row items-end justify-between gap-12 xl:flex`}
        >
          <div>
            <h2 className='text-[24px] font-semibold md:text-[32px]'>
              C.A.R.L. is writing…
            </h2>
            <p className=''>
              You&#39;ll see a green checkmark when your content is ready. You
              can leave this page while he&#39;s working.
            </p>
          </div>
          <div className='mt-8 flex flex-row-reverse gap-4'>
            <PrimaryButton onPress={() => setDialogOpen(false)}>
              Close
            </PrimaryButton>
          </div>
        </div>,
      );
      setDialogOpen(true);
    }
  }, [state]);

  const actionRequestTypes = actionRequests.map(request => request.type);
  const projectActions = actions.filter(action =>
    actionRequestTypes.includes(action.type),
  );

  const refreshResults = async () => {
    if (requestIds.length > 0) {
      const results: ActionResultBoInterface[] = [];
      for (const requestId of requestIds) {
        const response = await getResults(requestId);
        results.push(...response);
      }
      setActionResults(results);
    }
  };

  const fetchActionJobs = async () => {
    if (projectId) {
      const actionJobs = await getActionJobs(projectId);
      if (actionJobs) {
        setActionJobs(actionJobs);
      }
    }
  };

  const fetchRewriteJobs = async () => {
    if (projectId) {
      const rewriteJobs = await getRewriteJobs(projectId);
      if (rewriteJobs && rewriteJobs.length > 0) {
        setRewriteJobs(rewriteJobs);
      }
    }
  };

  useEffect(() => {
    if (requestId) {
      if (openActionRequest?.id !== requestId) {
        const matching = actionRequests.find(
          request => request.id === requestId,
        );
        const a = actions.find(action => action.type === matching?.type);
        if (matching) {
          const meta = actionTypeMetaMap.get(matching?.type);
          setOpenActionRequest(matching);
          setOpenAction({ action: a, img: meta?.img });

          open();
        }
      }
    } else {
      setOpenActionRequest(undefined);
      setOpenAction(undefined);
    }
  }, [requestId]);

  useEffect(() => {
    if (user?.id && projectId) {
      fetchActionJobs();
      fetchRewriteJobs();
    }
  }, [user, projectId]);

  useEffect(() => {
    refreshResults();
  }, [requestIds]);

  useEffect(() => {
    if (actionRequests.length > 0) {
      setRequestIds(actionRequests.map(request => request.id));
    }
  }, [actionRequests]);

  const handleOnClose = () => {
    navigate(`/project/results/${projectId}/`);
    close();
  };

  const handleFeedback = async (
    resultId: string,
    request: ActionResultFeedbackPostDtoInterface,
  ) => {
    await sendFeedback(resultId, request);
    refreshResults();
  };

  const handleRewrite = async (
    resultId: string,
    request: ActionResultRewritePostDtoInterface,
  ) => {
    await requestRewrite(resultId, request);
    await fetchRewriteJobs();
  };

  const selectResult = (action: ActionBoInterface) => {
    const matchingRequest = actionRequests.find(
      request => request.type === action.type,
    );
    if (matchingRequest) {
      navigate(`/project/results/${projectId}/${matchingRequest.id}`);
    }
  };

  const handleRenameProject = async (name: string) => {
    if (projectId) {
      const savedProject = await updateProject(projectId, { name });
      if (!savedProject) {
        throw new Error('Project failed to save.');
      }
    }
  };

  // ? Do we need to set some kind of flag here to have a visual indication that the archiving process is occurring? Seems like we would want some kind of loading indicator here.
  const handleArchiveProject = async () => {
    if (projectId) {
      const isSuccess = await archiveProject(projectId);

      if (!isSuccess) {
        throw new Error('Failed to archive project.');
      }

      navigate(`/create`);
    }
  };

  const isJobInProgress = (requestId: string): boolean => {
    const inProgessActionJobs = actionJobs?.filter(
      job =>
        job.actionRequestId === requestId &&
        (job.status === JobStatus.Queued ||
          job.status === JobStatus.Processing ||
          job.status === JobStatus.Failed), // TODO: May want to remove Failed here since this isn't *really* in progress, we just aren't sure how to handle this yet. Waiting on design
    );

    if (inProgessActionJobs?.length) {
      return true;
    }

    const inProgessRewriteJobs = rewriteJobs?.filter(
      job =>
        job.actionRequestId === requestId &&
        (job.status === JobStatus.Queued ||
          job.status === JobStatus.Processing ||
          job.status === JobStatus.Failed), // TODO: May want to remove Failed here since this isn't *really* in progress, we just aren't sure how to handle this yet. Waiting on design
    );

    return Boolean(inProgessRewriteJobs?.length);
  };

  return (
    <div>
      <ProjectTitleV2
        name={project?.name ?? ''}
        onRename={handleRenameProject}
        onArchive={handleArchiveProject}
      />
      <p>{`A${
        project?.config?.tone
          ? ` ${project?.config?.tone.toLowerCase()} toned`
          : ''
      } project${
        project?.config?.jobTitle
          ? ` for a ${project?.config?.jobTitle.toLowerCase()} position`
          : ''
      }.`}</p>
      <div className='mt-16 grid gap-4 lg:grid-cols-2 xl:grid-cols-3'>
        {projectActions
          .sort((a, b) => {
            if (a.name > b.name) {
              return 1;
            } else {
              return -1;
            }
          })
          .map(action => {
            const meta = actionTypeMetaMap.get(action.type);
            const matchingRequest = actionRequests.find(
              request => request.type === action.type,
            )?.id;

            return (
              <ActionCard
                key={action.id}
                action={action}
                img={meta?.img ?? ''}
                ready={
                  matchingRequest ? !isJobInProgress(matchingRequest) : false
                }
                onSelect={() => selectResult(action)}
              />
            );
          })}

        <ActionOverlay
          isOpen={isOpen}
          onClose={handleOnClose}
          buttons={<PrimaryButton onPress={handleOnClose}>Close</PrimaryButton>}
        >
          <GenericOverlay
            action={openAction?.action}
            img={openAction?.img}
            results={actionResults.filter(
              result => result?.actionRequestId === openActionRequest?.id,
            )}
            rewriteJobs={rewriteJobs.filter(
              job => job.actionRequestId === openActionRequest?.id,
            )}
            resultIndex={resultIndex}
            setResultIndex={setResultIndex}
            userId={user?.id}
            onFeedback={handleFeedback}
            onRewrite={handleRewrite}
          />
        </ActionOverlay>
      </div>
    </div>
  );
};

export default ProjectView;
