import type {
  BoostpointCreateAiClient,
  BoostpointCreateAiEnvironment,
} from '@boostpoint/client-sdk';
import { createContext, useContext } from 'react';

export const ApiContext = createContext<any>(null);

export const useApi = () => {
  const api = useContext<{
    client: BoostpointCreateAiClient;
    ready: boolean;
    environment: BoostpointCreateAiEnvironment;
  }>(ApiContext);
  if (!api) {
    throw new Error(`You must call useApi() inside of an <ApiProvider />`);
  }
  return api;
};
