import { MemberRole } from '@boostpoint/types';
import { ReactNode, useEffect, useMemo } from 'react';
import { useCompany } from '../../providers/CompanyProvider';
import { useNavigate } from 'react-router-dom';

export interface Props {
  requiredRole: MemberRole;
  children: ReactNode;
}

// Wrapper to check user for authorized role and if not, redirect to unauthorized page
const AuthorizedPage = ({ requiredRole, children }: Props) => {
  const { role, isLoadingRole, hasRole } = useCompany();
  const navigate = useNavigate();

  const isAuthorized = useMemo(
    () => hasRole(requiredRole),
    [role, requiredRole, hasRole],
  );

  useEffect(() => {
    // Role is loaded and user does not have required role.
    if (!isLoadingRole && !isAuthorized) {
      // Redirect to 404 page for now
      // TODO: May want to create an 'unauthorized' page. Need to discuss with team and get design.
      navigate('/404');
    }
  }, [isLoadingRole, isAuthorized]);

  return <>{!isLoadingRole && isAuthorized && children}</>;
};

export default AuthorizedPage;
