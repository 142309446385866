import { useRef } from 'react';
import { mergeProps, useFocusRing, useTableCell } from 'react-aria';
import type { TableState } from 'react-stately';
import type { GridNode } from '@react-types/grid';

export const TableCell = (props: {
  cell: GridNode<unknown>;
  state: TableState<unknown>;
}) => {
  const { cell, state } = props;
  const ref = useRef(null);
  const { gridCellProps } = useTableCell({ node: cell }, state, ref);
  const { isFocusVisible, focusProps } = useFocusRing();

  return (
    <td
      className='px-4 py-8'
      {...mergeProps(gridCellProps, focusProps)}
      style={{
        outline: 'none',
        boxShadow: isFocusVisible ? 'inset 0 0 0 2px orange' : 'none',
      }}
      ref={ref}
    >
      {cell.rendered}
    </td>
  );
};
