import { PrimaryButton } from '@boostpoint/ui';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { IoCloseCircle } from 'react-icons/io5';

const SubscriptionUpdated = (props: Props) => {
  const { addedUsers, removedUsers, close } = props;
  return (
    <div
      className={`block w-full flex-col items-start justify-between gap-4 xl:flex`}
    >
      <div className='mt-8'>
        <h2
          className='text-[24px] font-semibold md:text-[32px]'
          id='subscription-updated-header'
        >
          Your subscription has been updated!
        </h2>
      </div>
      <div>
        {addedUsers.length > 0 && (
          <div>
            <p>
              An email has been sent to the following user
              {addedUsers.length > 1 && 's'}
            </p>
            <ul>
              {addedUsers.map(user => (
                <li
                  key={user}
                  className='my-2 flex items-center justify-start gap-4'
                >
                  <BsFillCheckCircleFill
                    color='#64EDC2'
                    fontSize='1.3em'
                  />
                  {user}
                </li>
              ))}
            </ul>
          </div>
        )}
        {removedUsers.length > 0 && (
          <div>
            <p className='mt-4'>
              The following user{addedUsers.length > 1 && 's'}{' '}
              {addedUsers.length > 1 ? 'have' : 'has'} been removed
            </p>
            <ul>
              {removedUsers.map(user => (
                <li
                  key={user}
                  className='my-2 flex items-center justify-start gap-4'
                >
                  <IoCloseCircle
                    color='#EA6262'
                    fontSize='1.5em'
                  />{' '}
                  {user}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div className='mt-4 flex gap-4'>
        <PrimaryButton onPress={close}>Close</PrimaryButton>
      </div>
    </div>
  );
};

type Props = {
  addedUsers: string[];
  removedUsers: string[];
  close: () => void;
};

export default SubscriptionUpdated;
