import { AriaProgressBarProps, useProgressBar } from 'react-aria';

interface Props extends AriaProgressBarProps {
  showLabel?: boolean;
}

export const ProgressBar = (props: Props) => {
  const { value = 0, minValue = 0, maxValue = 8 } = props;

  const percentage = (value - minValue) / (maxValue - minValue);
  const { progressBarProps } = useProgressBar({
    ...props,
    ...(!props.showLabel && {
      'aria-label': `${value} out of ${maxValue} used`,
    }),
  });
  const barWidth = `${Math.round(percentage * 100)}%`;

  return (
    <div>
      <div
        {...progressBarProps}
        className='h-[7px] w-full rounded-full bg-bp-gray'
      >
        <div
          className='h-[7px] max-w-full rounded-full bg-bp-pink'
          style={{ width: barWidth }}
          aria-label={`${value} out of ${maxValue} used`}
        />
      </div>
    </div>
  );
};
