import { ActionPreviewCard } from '../ActionPreviewCard';

interface Props {
  companyName?: string;
  result: any;
}

export const LinkedInCampaignPreview = (props: Props) => {
  const { companyName } = props;
  const { messages } = props.result as {
    messages?: string[];
  };
  return (
    <ActionPreviewCard>
      <div className='relative flex h-full max-h-[40rem] flex-col gap-1 overflow-y-scroll px-6 py-8 md:w-96'>
        {messages &&
          Array.isArray(messages) &&
          messages.map((b: string, index) => (
            <div
              className='flex flex-col'
              key={index}
            >
              {index > 0 && (
                <div
                  className={`before:contents-[''] after:contents-[''] flex flex-row items-center justify-center gap-2 py-6 before:flex-1 before:border-b-2 after:flex-1 after:border-b-2`}
                >
                  <div>
                    <p className='text-sm text-bp-black'>Message {index + 1}</p>
                  </div>
                </div>
              )}
              <div className='flex flex-row gap-2'>
                {/* <div className='flex flex-col'>
                  <div className='w-10 h-10 rounded-full bg-sidebar bg-cover bg-center' />
                </div> */}
                <div className='flex flex-col gap-2'>
                  <div className='mx-5 flex flex-col gap-1'>
                    <h3 className='font-semibold text-bp-black'>
                      {companyName}
                    </h3>
                    <p className='text-left text-sm'>{b}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        <br />
      </div>
    </ActionPreviewCard>
  );
};
