import { Navigate, useSearchParams } from 'react-router-dom';
import { useInvitation } from '../providers/InvitationProvider';
import { useEffect } from 'react';

const AcceptInvitation = () => {
  const [params] = useSearchParams();
  const { fetchInvitationById } = useInvitation();

  const invitationId = params.get('invitationId');
  useEffect(() => {
    if (invitationId) {
      fetchInvitationById(invitationId);
    }
  }, []);

  if (!invitationId) return <Navigate to='/settings/profile' />;
  return <Navigate to={`/settings/profile?invitationId=${invitationId}`} />;
};

export default AcceptInvitation;
