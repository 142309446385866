import {
  ActionResultFeedbackBoInterface,
  ActionResultFeedbackPostDtoInterface,
  ActionResultRewritePostDtoInterface,
} from '@boostpoint/types';
import { ReactNode } from 'react';
import { HiThumbDown, HiThumbUp } from 'react-icons/hi';
import { Button } from '.';

export interface Props {
  isLoading?: boolean;
  resultKeys: string[];
  children?: ReactNode;
  feedback?: ActionResultFeedbackBoInterface[];
  actionResultId?: string;
  userId?: string;
  onFeedback?(
    actionResultId: string,
    feedback: ActionResultFeedbackPostDtoInterface,
  ): void;
  onRewrite?(
    actionResultId: string,
    request: ActionResultRewritePostDtoInterface,
  ): void;
}

export const ActionPreview = (props: Props) => {
  const {
    isLoading,
    children,
    feedback,
    onFeedback,
    onRewrite,
    userId,
    actionResultId,
    resultKeys,
  } = props;
  const mostRecentUserFeedback = feedback
    ?.filter(fb => (fb.userId === userId && fb.dislike) || fb.like)
    .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())?.[0];
  const like = mostRecentUserFeedback?.like
    ? 'like'
    : mostRecentUserFeedback?.dislike
      ? 'dislike'
      : '';

  const handleLike = () => {
    if (actionResultId && onFeedback)
      onFeedback(actionResultId, {
        like: true,
      });
  };

  const handleDislike = () => {
    if (actionResultId && onFeedback)
      onFeedback(actionResultId, {
        dislike: true,
      });
  };

  const handleRewrite = () => {
    const rewrite: ActionResultRewritePostDtoInterface = { rewriteMap: {} };
    resultKeys.forEach(key => {
      rewrite.rewriteMap[key] = true;
    });
    if (actionResultId && onRewrite) onRewrite(actionResultId, rewrite);
  };

  return (
    <div className='flex flex-1 flex-col bg-bp-lGray px-4 py-16 md:overflow-hidden'>
      {/* Preview Header here. Pretty simple */}
      <h1 className='flex self-center font-medium text-bp-black'>Preview</h1>

      {/* Here is where preview cards will go. I want a container here probably because they should stack horizontally and scroll horizontally. */}
      <div
        className={`m-4 flex flex-row gap-10 overflow-x-auto p-4 ${
          Array.isArray(children) && children?.length > 1
            ? ''
            : 'justify-center'
        }`}
      >
        {children}
      </div>

      {/* Buttons from alpha. Will be deprecated eventually in favor of icons. */}
      <div className='flex justify-center gap-8'>
        <Button
          className={`group flex h-10 w-10 ${
            like === 'dislike'
              ? 'bg-bp-red'
              : `bg-bp-gray ${!isLoading && 'hover:bg-bp-red'}`
          } items-center justify-center rounded-full transition-colors duration-300`}
          isDisabled={isLoading}
          onPress={handleDislike}
        >
          <HiThumbDown
            className={`mt-1 text-2xl ${
              like === 'dislike'
                ? 'text-white'
                : `text-bp-black ${!isLoading && 'group-hover:text-white'}`
            } transition-colors duration-300`}
          />
        </Button>

        <Button
          className={`pointer-events-auto rounded-full border-2 border-bp-purple px-2 text-bp-purple opacity-100 transition-all duration-700`}
          aria-label='rewrite'
          onPress={handleRewrite}
          isLoading={isLoading}
        >
          Rewrite
        </Button>

        <Button
          className={`group flex h-10 w-10 ${
            like === 'like'
              ? 'bg-bp-green'
              : `bg-bp-gray ${!isLoading && 'hover:bg-bp-green'}`
          } items-center justify-center rounded-full transition-colors duration-300`}
          isDisabled={isLoading}
          onPress={handleLike}
        >
          <HiThumbUp
            className={`mb-1 text-2xl ${
              like === 'like'
                ? 'text-white'
                : `text-bp-black ${!isLoading && 'group-hover:text-white'}`
            } transition-colors duration-300`}
          />
        </Button>
      </div>
    </div>
  );
};
