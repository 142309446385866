import React, { useEffect, useState } from 'react';
import {
  BoostpointCreateAiClient,
  BoostpointCreateAiEnvironment,
} from '@boostpoint/client-sdk';
import { ApiContext } from './context';
import { useAuth } from '../AuthProvider';

const ApiProvider = (props: { children: React.ReactNode }) => {
  const { children } = props;
  const [client, setClient] = useState<BoostpointCreateAiClient>(
    new BoostpointCreateAiClient({
      environment: BoostpointCreateAiEnvironment.Development,
      token: '',
    }),
  );
  const [ready, setReady] = useState(false);
  const mode = import.meta.env.MODE;
  const { getToken, currentUser } = useAuth();
  let environment: string;

  switch (mode) {
    case 'production':
      environment = BoostpointCreateAiEnvironment.Production;
      break;
    case 'staging':
      environment = BoostpointCreateAiEnvironment.Staging;
      break;
    default:
      environment = BoostpointCreateAiEnvironment.Development;
  }

  useEffect(() => {
    if (currentUser?.uid) {
      const c = new BoostpointCreateAiClient({
        environment,
        token: async () => {
          return await getToken();
        },
      });
      setClient(c);
      setReady(true);
    } else {
      setReady(false);
    }
  }, [environment, currentUser?.uid]);

  const wrapped = {
    client,
    ready,
    environment,
  };

  return <ApiContext.Provider value={wrapped}>{children}</ApiContext.Provider>;
};
export default ApiProvider;
