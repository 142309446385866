import {
  ActionJobBoInterface,
  ProjectBoInterface,
  ProjectStatus,
  ProjectUpsertDtoInterface,
  RewriteJobBoInterface,
} from '@boostpoint/types';
import React, { useEffect, useState } from 'react';
import { useApi } from '../ApiProvider';
import { useUser } from '../UserProvider';
import { ProjectContext } from './context';

const ProjectProvider = (props: { children: React.ReactNode }) => {
  const { children } = props;
  const [projects, setProjects] = useState<ProjectBoInterface[]>([]);
  const { client } = useApi();
  const { user } = useUser();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (user?.companyId) {
      refreshProjects();
    }
  }, [user?.companyId]);

  const refreshProjects = async () => {
    try {
      // We need to filter by companyId, but we don't have it yet
      if (!user?.companyId) {
        return;
      }

      const u = await client?.project.getProjectsByQuery({
        companyId: user?.companyId,
      });
      if (u) {
        setProjects(u);
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  const createProject = async (
    request: ProjectUpsertDtoInterface,
  ): Promise<ProjectBoInterface | undefined> => {
    try {
      const project = await client.project.createProject(request);

      console.debug('Project created: ', project);

      setProjects([project, ...projects]);

      return project;
    } catch (e) {
      console.error(e);
    }
  };

  const updateProject = async (
    projectId: string,
    request: ProjectUpsertDtoInterface,
  ): Promise<ProjectBoInterface | undefined> => {
    try {
      const updated = await client.project.updateProject(projectId, request);

      console.debug('Project updated: ', updated);

      setProjects(prevProjects =>
        prevProjects.map(project =>
          project.id === projectId ? updated : project,
        ),
      );

      return updated;
    } catch (e) {
      console.error(e);
    }
  };

  const archiveProject = async (projectId: string) => {
    try {
      const isSuccess = await client.project.deleteProject(projectId);

      if (isSuccess) {
        console.debug('Project archived');
        refreshProjects();
      }

      return isSuccess;
    } catch (e) {
      console.error(e);
    }
  };

  const activateProject = async (
    projectId: string,
  ): Promise<boolean | undefined> => {
    try {
      const success = await client.project.activateProject(projectId);
      if (success) {
        setProjects(prevProjects =>
          prevProjects.map(project =>
            project.id === projectId
              ? { ...project, status: ProjectStatus.Active }
              : project,
          ),
        );
      }
      return success;
    } catch (e) {
      console.error(e);
    }
  };

  const getActionJobs = async (
    id: string,
  ): Promise<ActionJobBoInterface[] | undefined> => {
    try {
      return await client.project.getActionJobs(id);
    } catch (e) {
      console.error(e);
    }
  };

  const getRewriteJobs = async (
    id: string,
  ): Promise<RewriteJobBoInterface[] | undefined> => {
    try {
      return await client.project.getRewriteJobs(id);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (user?.id) {
      setIsLoading(false);
      refreshProjects();
    } else {
      setProjects([]);
    }
  }, [user?.id]);

  const wrapped = {
    refreshProjects,
    createProject,
    updateProject,
    archiveProject,
    activateProject,
    getActionJobs,
    getRewriteJobs,
    projects,
    isLoading,
  };

  return (
    <ProjectContext.Provider value={wrapped}>
      {children}
    </ProjectContext.Provider>
  );
};
export default ProjectProvider;
