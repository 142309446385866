import {
  UserBoInterface,
  UserCreateDtoInterface,
  UserUpsertDtoInterface,
} from '@boostpoint/types';
import React, { useEffect, useState } from 'react';
import useSseEvent from '../../hooks/useSseEvent';
import { useApi } from '../ApiProvider';
import { useAuth } from '../AuthProvider';
import { UserContext } from './context';
import { BoostpointCreateAiError } from '@boostpoint/client-sdk';

const ApiProvider = (props: { children: React.ReactNode }) => {
  const { children } = props;
  const [user, setUser] = useState<UserBoInterface | undefined | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // Has the user been loaded at least once?
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const { client, ready } = useApi();
  const { currentUser, refreshToken } = useAuth();

  const { data: userSessionChangedData, error: userSessionChangedError } =
    useSseEvent<any>({
      url: user ? `/user/${user.id}/session` : '',
    });

  const refreshUser = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const u = await client?.user.getUserByFirebaseId(currentUser?.uid);
      if (u) {
        setUser(u);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
      setHasLoaded(true);
    }
  };

  const createUser = async (user: UserCreateDtoInterface): Promise<void> => {
    try {
      setIsLoading(true);
      await client?.user.createUser(user);
      await refreshToken();
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const updateUser = async (
    userUpdate: UserUpsertDtoInterface,
  ): Promise<void> => {
    try {
      setIsLoading(true);
      if (!user) {
        throw new Error('User not found');
      }

      const u = await client?.user.updateUser(user?.id, userUpdate);
      if (u) {
        setUser(u);
      }
    } catch (e: unknown) {
      setIsLoading(false);
      throw new BoostpointCreateAiError(e as BoostpointCreateAiError);
    } finally {
      setIsLoading(false);
    }
  };

  const sessionRefresh = async (): Promise<void> => {
    await refreshToken();
    refreshUser();
  };

  useEffect(() => {
    if (currentUser?.uid && ready) {
      refreshUser();
    } else {
      setUser(null);
    }
  }, [currentUser, ready]);

  useEffect(() => {
    if (userSessionChangedData) {
      sessionRefresh();
    }

    if (userSessionChangedError) {
      console.error('user session changed error:', userSessionChangedError);
    }
  }, [userSessionChangedData, userSessionChangedError]);

  const wrapped = {
    createUser,
    updateUser,
    refreshUser,
    user,
    isLoading,
    hasLoaded,
  };

  return (
    <UserContext.Provider value={wrapped}>{children}</UserContext.Provider>
  );
};
export default ApiProvider;
