import { ReactNode } from 'react';
import { ProjectTitleV2 } from '.';

interface Props {
  children?: ReactNode;
  projectName?: string;
  id: string;
  onRename?(name: string): void;
  onArchive?(): Promise<void>;
}

export const WizardStepTitle = (props: Props) => {
  const { id, children, projectName, onRename, onArchive } = props;

  return (
    <div className=''>
      <ProjectTitleV2
        name={projectName ?? 'Getting Started'}
        onRename={onRename}
        onArchive={onArchive}
      />

      <h1
        className='pt-2 text-[32px] font-semibold'
        id={id}
      >
        {children}
      </h1>
    </div>
  );
};
