import { useEffect } from 'react';
import ApiProvider from './providers/ApiProvider';
import AppRefsProvider from './providers/AppRefsProvider';
import AuthProvider from './providers/AuthProvider/AuthProvider';
import CompanyProvider from './providers/CompanyProvider';
import DialogProvider from './providers/DialogProvider/DialogProvider';
import InvitationProvider from './providers/InvitationProvider';
import MobileContextProvider from './providers/MobileContextProvider/MobileContextProvider';
import ProjectProvider from './providers/ProjectProvider/ProjectProvider';
import UserProvider from './providers/UserProvider';
import Routes from './routes/Routes';
import SubscriptionProvider from './providers/SubscriptionProvider/SubscriptionProvider';

function App() {
  useEffect(() => {
    window.Intercom('boot', {
      app_id: 'g0e1eyg3',
      custom_launcher_selector: '#custom_intercom',
      create_user: true,
    });
  }, []);
  return (
    <AuthProvider>
      <ApiProvider>
        <UserProvider>
          <CompanyProvider>
            <SubscriptionProvider>
              <InvitationProvider>
                <ProjectProvider>
                  <MobileContextProvider>
                    <DialogProvider>
                      <AppRefsProvider>
                        <Routes />
                      </AppRefsProvider>
                    </DialogProvider>
                  </MobileContextProvider>
                </ProjectProvider>
              </InvitationProvider>
            </SubscriptionProvider>
          </CompanyProvider>
        </UserProvider>
      </ApiProvider>
    </AuthProvider>
  );
}

export default App;
