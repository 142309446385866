import { Loading } from '@boostpoint/ui';
import { createBrowserRouter, Navigate, redirect } from 'react-router-dom';
import AcceptInvitation from '../../../pages/AcceptInvitation';
import CompanyMembers from '../../../pages/CompanyMembers';
import CompanyProfile from '../../../pages/CompanyProfile';
import Landing from '../../../pages/Landing';
import NotFound from '../../../pages/NotFound';
import ProjectView from '../../../pages/ProjectView';
import ProjectNew from '../../../pages/ProjectWizard';
import Settings from '../../../pages/Settings/Settings';
import PageWrapper from '../PageWrapper';

export const authenticatedRouter = (logOut: () => void) =>
  createBrowserRouter(
    [
      {
        path: '/',
        loader: async () => {
          return redirect('/create');
        },
      },
      {
        path: 'login/:register?',
        element: <Navigate to='/create' />,
      },
      {
        path: 'password-reset',
        element: <Navigate to='/create' />,
      },
      {
        path: 'logout',
        element: <Loading loading={true} />,
        loader: async () => {
          await logOut();
          return redirect('/login');
        },
      },
      {
        path: 'accept-invitation',
        element: <AcceptInvitation />,
      },
      {
        element: <PageWrapper />,
        children: [
          {
            path: 'create',
            element: <Landing />,
          },
          {
            path: 'project/results/:projectId?/:requestId?',
            element: <ProjectView />,
          },
          {
            path: 'project/new/:step/:projectId?',
            element: <ProjectNew />,
          },
          {
            path: 'settings',
            element: <Settings />,
            children: [
              {
                path: '',
                element: <Navigate to='profile' />,
              },
              {
                path: 'profile',
                element: <CompanyProfile />,
              },
              {
                path: 'users',
                element: <CompanyMembers />,
              },
            ],
          },
          { path: '404', element: <NotFound /> },
          {
            path: '*',
            element: <Navigate to='/404' />,
          },
        ],
      },
    ],

    {
      future: {
        // Normalize `useNavigation()`/`useFetcher()` `formMethod` to uppercase
        v7_normalizeFormMethod: true,
      },
    },
  );
