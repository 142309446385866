import { MutableRefObject, useRef } from 'react';
import { AriaButtonProps, useButton } from 'react-aria';

interface Props extends AriaButtonProps {
  buttonRef?: MutableRefObject<any>;
  label?: string;
  logoImg: string;
  logoAlt?: string;
}

// Google Guidelines: https://developers.google.com/identity/branding-guidelines
// Microsoft Guidelines: https://learn.microsoft.com/en-us/azure/active-directory/develop/howto-add-branding-in-apps
export const PlatformIconButton = (props: Props) => {
  const ref = useRef(null);

  const { buttonRef = ref, label, logoImg, logoAlt } = props;

  const { buttonProps } = useButton(props, buttonRef);

  return (
    <div className='flex flex-col items-center justify-center space-y-2 text-sm font-medium text-bp-dkGray'>
      <button
        {...buttonProps}
        ref={buttonRef}
        className='items-center rounded-2xl border-2 bg-white p-3 hover:bg-bp-lGray'
      >
        <img
          className='h-5 w-5'
          src={logoImg}
          alt={logoAlt}
        />
      </button>
      <label>{label}</label>
    </div>
  );
};
