import { ActionPreviewCard } from '../ActionPreviewCard';

interface Props {
  result: any;
}

export const InterviewQuestionsPreview = (props: Props) => {
  const { questions } = props.result as {
    questions?: string[];
  };
  return (
    <ActionPreviewCard>
      <div className='relative flex h-full max-h-[40rem] flex-col gap-1 overflow-y-scroll p-8 md:w-96'>
        <ol className='list-outside list-decimal'>
          {questions?.map((b: string) => (
            <li
              key={b}
              className='py-1 text-left text-sm'
            >
              {b.replace(/^\d+\s*[-\\.)]?\s+/g, '')}
            </li>
          ))}
        </ol>
      </div>
    </ActionPreviewCard>
  );
};
