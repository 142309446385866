import { ActionPreviewCard } from '../ActionPreviewCard';

interface Props {
  companyName?: string;
  result: any;
}

export const GoogleAdPreview = (props: Props) => {
  const { companyName } = props;
  const { headline, description } = props.result as {
    headline?: string;
    description?: string;
  };
  return (
    <ActionPreviewCard>
      <div className='flex h-full max-h-[40rem] flex-col gap-2 overflow-y-scroll p-8 md:w-96'>
        <div className='mb-2 flex flex-col gap-2 border-b-2 border-gray-300 pb-4'>
          <h3 className='text-left text-lg font-medium leading-5 text-indigo-900 '>
            {headline}
          </h3>
          <div className='flex flex-row gap-1'>
            <div className='rounded-md border-2 border-bp-purple px-1'>
              <p className='text-xs font-semibold text-bp-purple'>Ad</p>
            </div>
            <p className='text-sm font-semibold text-bp-purple'>
              {companyName}
            </p>
          </div>
        </div>
        <p className='text-left text-sm'>{description}</p>
      </div>
    </ActionPreviewCard>
  );
};
