const CardTitle = (props: Props) => {
  const { title, icon, id, className = '', required } = props;
  return (
    <h2
      className={`flex flex-col gap-1 text-[20px] font-semibold ${className}`}
      title={title}
    >
      {icon}
      <span id={id}>
        {title}
        {!!required && (
          <span
            className='text-bp-red'
            title='required'
          >
            *
          </span>
        )}
      </span>
    </h2>
  );
};

type Props = {
  id: string;
  title: string;
  icon: React.ReactNode;
  className?: string;
  required?: boolean;
};

export default CardTitle;
