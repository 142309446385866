import {
  PasswordField,
  PlatformIconButton,
  PrimaryButton,
  TextButton,
  TextField,
  VersionChip,
} from '@boostpoint/ui';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CardIcon from '../../../assets/iconsBeta/cardNotNeeded.svg';
import GoogleLogo from '../../../assets/sso/g-logo.png';
import MicrosoftLogo from '../../../assets/sso/ms-logo.svg';
import { useAuth } from '../../../providers/AuthProvider';

const Registration = (props: Props) => {
  const { onSubmit, onSignInWithGoogle, onSignInWithMicrosoft, register } =
    props;
  const { checkForExistingAccount, authError } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({
    email: '',
    password: '',
    confirmPassword: '',
  });
  const navigate = useNavigate();

  const [params] = useSearchParams();
  const invitationId = params.get('invitationId');

  const disableSubmit = useMemo(() => {
    if (register) {
      return !(
        !!email &&
        !!password &&
        !!confirmPassword &&
        password === confirmPassword &&
        !errors.confirmPassword &&
        !errors.password &&
        !errors.email
      );
    } else {
      return !email || !password;
    }
  }, [email, password, confirmPassword, errors, register, authError]);

  const submit = () => {
    if (!disableSubmit) {
      onSubmit(email, password);
    }
  };

  useEffect(() => {
    if (!register) {
      setErrors({
        email: '',
        password: '',
        confirmPassword: '',
      });
    }
  }, [register]);

  useEffect(() => {
    if (register) {
      const e = { ...errors };
      if (password && password.length < 8) {
        e.password = 'password is too short';
      } else {
        e.password = '';
      }
      if (password && confirmPassword && password !== confirmPassword) {
        e.confirmPassword = 'passwords do not match';
      } else {
        e.confirmPassword = '';
      }
      setErrors(e);
    }
  }, [password, confirmPassword, register]);

  useEffect(() => {
    if (register && email) {
      checkAccount();
    }
  }, [register, email]);

  const checkAccount = async () => {
    try {
      const emailCheck = await checkForExistingAccount(email);
      if (emailCheck) {
        setErrors({ ...errors, email: 'this account already exists' });
      } else {
        setErrors({ ...errors, email: '' });
      }
    } catch (e: any) {
      if (e?.code === 'auth/invalid-email') {
        setErrors({ ...errors, email: 'invalid email address' });
      }
    }
  };

  return (
    <div className='m-auto flex flex-col items-center justify-between gap-2'>
      <div className='relative overflow-hidden rounded-2xl bg-white p-8 text-bp-purple md:border-[2px] md:border-solid md:border-bp-gray md:p-16'>
        {!invitationId ? (
          <>
            <h1 className='-mt-[18px] mb-4 flex flex-col self-center text-center text-[1.7em] font-semibold md:mb-8 md:items-end'>
              <span className='hidden whitespace-nowrap md:block'>
                Boostpoint&reg; <span className='text-bp-pink'>Create.AI</span>
              </span>
              <div className='md:-mt-[12px]'>
                <VersionChip>v1.0</VersionChip>
              </div>
              <span className='md:hidden'>Get started for free</span>
            </h1>
          </>
        ) : (
          <>
            <h2 className='pb-2 text-lg font-semibold text-bp-black'>
              You have been invited to join another user's workspace.
            </h2>
            <h3 className='text-md pb-8 text-bp-black'>
              Create an account or sign in to accept.
            </h3>
          </>
        )}
        <TextField
          label='Email'
          aria-label='username'
          id='username'
          type='email'
          value={email}
          name='email'
          onChange={setEmail}
          errorMessage={errors.email}
          className='email-input'
        />
        <PasswordField
          label='Password'
          value={password}
          type='password'
          name='password'
          id='password'
          onChange={setPassword}
          className='password-input mt-4'
          errorMessage={errors.password}
        />
        <div
          className={`${
            register ? 'h-20' : 'pointer-events-none h-0 scale-y-0 opacity-0'
          } transition-all duration-500`}
        >
          {!!register && (
            <PasswordField
              label='Confirm Password'
              value={confirmPassword}
              type='password'
              name='confirmPassword'
              id='confirmPassword'
              onChange={setConfirmPassword}
              className='confirm-password-input mt-4'
              errorMessage={errors.confirmPassword}
            />
          )}
        </div>
        <div className='mt-8 flex flex-row-reverse items-center justify-center gap-6'>
          <PrimaryButton
            isDisabled={disableSubmit}
            onPress={submit}
            id={!register ? 'login-btn' : 'register-btn'}
          >
            {register ? 'Register' : 'Log In'}
          </PrimaryButton>
          <TextButton
            onPress={() => {
              navigate(`/login${!register ? '/register' : ''}`);
            }}
            id={register ? 'login-btn' : 'register-btn'}
          >
            {register ? 'Log In' : 'Sign Up'}
          </TextButton>
        </div>
        <fieldset className='mt-6 border-t-2 border-bp-gray'>
          <legend className='mx-auto px-4 text-bp-black'>
            {register ? 'Or sign up with' : 'Or sign in with'}
          </legend>

          <div className='flex justify-center space-x-10 pt-6'>
            <PlatformIconButton
              label='Google'
              logoImg={GoogleLogo}
              logoAlt='Google Logo'
              onPress={onSignInWithGoogle}
            />
            <PlatformIconButton
              label='Microsoft'
              logoImg={MicrosoftLogo}
              logoAlt='Microsoft Logo'
              onPress={onSignInWithMicrosoft}
            />
          </div>
        </fieldset>
        <div className='mt-8 flex flex-row items-center justify-start gap-2'>
          <img
            src={CardIcon}
            alt='Card not needed'
            className='w-[24px]'
          />
          <p className='text-[14px] text-black'>No credit card required</p>
          <div
            className={`absolute bottom-0 left-0 flex w-full flex-row items-center justify-center bg-bp-pink p-2 ${
              !authError ? 'max-h-0 opacity-0' : 'max-h-40'
            } w-full transition-all duration-500`}
          >
            <p className='font-bold text-white '>{authError}</p>
          </div>
        </div>
      </div>
      <p className='max-w-[34em] pb-[30px] text-center text-[13px] text-white xl:text-black'>
        By signing up, I agree to the following{' '}
        <a
          href='https://boostpoint.com/bp-create-ai-agreement/'
          className='underline'
          target='_blank'
          rel='noopener noreferrer'
        >
          User Agreement
        </a>{' '}
        and agree to receive email and SMS communications from Boostpoint.
      </p>
    </div>
  );
};

type Props = {
  onSubmit: (email: string, password: string) => void;
  onSignInWithGoogle: () => void;
  onSignInWithMicrosoft: () => void;
  register: boolean;
};

export default Registration;
