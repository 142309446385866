import { SecondaryButton, TertiaryButton } from '@boostpoint/ui';

const UpgradeCTAUsersModal = (props: Props) => {
  const { close, onContinue, atLimit } = props;
  return (
    <div
      className={`block w-full flex-col items-end justify-between gap-8 xl:flex`}
    >
      <div className='w-full'>
        <h2 className='mt-8 text-[24px] font-semibold md:text-[32px]'>
          Your free trial {atLimit ? 'has ended' : 'is ending soon'}
        </h2>
        <p className='my-3'>
          Upgrade your subscription to unlock more features.
        </p>
        <ul className='my-3 list-inside list-disc'>
          <li>
            Create <strong>unlimited</strong> projects and recruitment content
          </li>
          <li>
            Create a <strong>team</strong> for your organization, adding and
            managing multiple users
          </li>
        </ul>
      </div>
      <div className='mt-4 flex w-full gap-2'>
        <SecondaryButton onPress={close}>Cancel</SecondaryButton>
        <TertiaryButton onPress={onContinue}>Upgrade Plan</TertiaryButton>
      </div>
    </div>
  );
};

type Props = {
  close: () => void;
  onContinue: () => void;
  atLimit?: boolean;
};

export default UpgradeCTAUsersModal;
