import React, { useEffect, useState } from 'react';
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useCompany } from '../../providers/CompanyProvider';
import {
  Button,
  DefaultModal,
  ModalButtons,
  ModalText,
  ModalTitle,
  PrimaryButton,
} from '@boostpoint/ui';
import clsx from 'clsx';
import RoleRequired from '../../routes/components/RoleRequired';
import { MemberRole } from '@boostpoint/types';
import { useUser } from '../../providers/UserProvider';
import { useOverlayTriggerState } from 'react-stately';
import { InvitationStatus } from '@boostpoint/types';
import { useApi } from '../../providers/ApiProvider';

const Settings: React.FC = () => {
  const { company } = useCompany();
  const { user } = useUser();
  const { client, ready } = useApi();
  const location = useLocation();
  const navigate = useNavigate();

  const { pathname } = location;

  const [invitationDialogContent, setInvitationDialogContent] = useState({
    title: '',
    description: '',
  });
  const invitationDialogState = useOverlayTriggerState({});

  const [params] = useSearchParams();
  const invitationId = params.get('invitationId');

  const fetchInvitation = async () => {
    if (invitationId && user) {
      const companyInvitation = await client.invitation.findById(invitationId);
      if (companyInvitation.invitation.status !== InvitationStatus.Sent) {
        setInvitationDialogContent({
          title: 'Invalid Invitation',
          description:
            'The invitation you are trying to accept is invalid. Please ask the person who invited you to send you a new invitation.',
        });
        invitationDialogState.open();
      } else if (companyInvitation.invitation.email !== user.email) {
        setInvitationDialogContent({
          title: 'Wrong User',
          description: `You logged in with an invitation that is not for you. Please sign in with "${companyInvitation.invitation.email}" to accept the invitation.`,
        });
        invitationDialogState.open();
      }
    }
  };

  useEffect(() => {
    if (invitationId && ready && user) {
      fetchInvitation();
    }
  }, [invitationId, client, ready, user]);

  return (
    <div>
      <h1 className='my-8 text-[32px] font-semibold'>{company?.name}</h1>
      <div className='flex w-full flex-row gap-4 border-b-2 border-bp-gray pb-4'>
        <Button onPress={() => navigate('profile')}>
          <p
            className={clsx({
              'hover:font-semibold': !pathname.includes('profile'),
              'font-bold': pathname.includes('profile'),
            })}
          >
            Profile
          </p>
        </Button>
        <RoleRequired requiredRole={MemberRole.Admin}>
          <Button onPress={() => navigate('users')}>
            <p
              className={clsx({
                'hover:font-semibold': !pathname.includes('users'),
                'font-bold': pathname.includes('users'),
              })}
            >
              Users
            </p>
          </Button>
        </RoleRequired>
      </div>
      <Outlet />
      {invitationDialogState.isOpen && (
        <DefaultModal
          state={invitationDialogState}
          isDismissable
        >
          <ModalTitle>{invitationDialogContent.title}</ModalTitle>
          <ModalText>{invitationDialogContent.description}</ModalText>
          <ModalButtons>
            <PrimaryButton onPress={invitationDialogState.close}>
              Dismiss
            </PrimaryButton>
          </ModalButtons>
        </DefaultModal>
      )}
    </div>
  );
};

export default Settings;
