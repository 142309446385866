import {
  UserBoInterface,
  UserCreateDtoInterface,
  UserUpsertDtoInterface,
} from '@boostpoint/types';
import { createContext, useContext } from 'react';

export const UserContext = createContext<any>(null);

export const useUser = () => {
  const user = useContext<{
    refreshUser: () => Promise<void>;
    createUser: (user: UserCreateDtoInterface) => Promise<void>;
    updateUser: (userUpdate: UserUpsertDtoInterface) => Promise<void>;
    user: UserBoInterface | undefined;
    isLoading: boolean;
    hasLoaded: boolean;
  }>(UserContext);
  if (!user) {
    throw new Error(`You must call useUser() inside of an <UserProvider />`);
  }
  return user;
};
