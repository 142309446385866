import { ProjectBoInterface, PromptType } from '@boostpoint/types';
import {
  ActionConfig,
  CardWrapper,
  PrimaryButton,
  SecondaryButton,
  Select,
  TextArea,
  TextField,
  WizardStepTitle,
} from '@boostpoint/ui';
import { Key, useState } from 'react';
import { IoDocumentText } from 'react-icons/io5';
import { MdPeopleAlt, MdSettings, MdPersonSearch } from 'react-icons/md';
import { Item } from 'react-stately';
import VoiceChat from '../../../assets/voice_chat_FILL1_wght400_GRAD0_opsz48.svg';
import CardTitle from '@boostpoint/ui/src/Layout/CardTitle';
import useActions from '../../../hooks/useActions';
import { useCompany } from '../../../providers/CompanyProvider';
import useActionRequest from '../../../hooks/useActionRequest';
import { actionTypeMetaMap } from '../../../constants/actionTypeMeta.map';
import { useDebouncedCallback } from 'use-debounce';

const tones = [
  { id: 'Compassionate', name: 'Compassionate' },
  { id: 'Encouraging', name: 'Encouraging' },
  { id: 'Empathetic', name: 'Empathetic' },
  { id: 'Funny', name: 'Funny' },
  { id: 'Thoughtful', name: 'Thoughtful' },
  { id: 'Personal', name: 'Personal' },
  { id: 'Persuasive', name: 'Persuasive' },
  { id: 'Professional', name: 'Professional' },
  { id: 'Warm', name: 'Warm' },
  { id: 'Witty', name: 'Witty' },
];

interface Props {
  draftProject?: ProjectBoInterface;
  onBack?(): void;
  onCreate?(configuration: Partial<ProjectBoInterface>): void;
  onRename?(name: string): void;
  onArchive?(): Promise<void>;
}

const ProjectWizardConfigurationStep = (props: Props) => {
  const { draftProject, onBack, onCreate, onRename, onArchive } = props;
  const { actions } = useActions();
  const { company } = useCompany();
  const { actionRequests, updateBulkRequest } = useActionRequest({
    projectId: draftProject?.id,
  });
  const [tone, setTone] = useState<string>('');
  // const [company, setCompany] = useState<string>('');
  const [audience, setAudience] = useState<string>('');
  const [jobTitle, setJobTitle] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>(company?.name) ?? '';
  const [contentDescription, setContentDescription] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [unsavedConfig, setUnsavedConfig] = useState<{
    id: string;
    config: any;
    key: string;
    val: any;
  } | null>(null);

  const handleSelectionChange = (key: Key) => {
    if (tone === key) {
      setTone('');
      return;
    }

    setTone(key as string);
  };

  const handleCreate = async () => {
    if (onCreate) {
      setLoading(true);

      // Just in case we click create before debounced function fires, we will save the config here to ensure data consistency
      if (unsavedConfig) {
        await updateBulkRequest([
          {
            id: unsavedConfig.id,
            config: {
              ...unsavedConfig.config,
              [unsavedConfig.key]: unsavedConfig.val,
            },
          },
        ]);
      }

      onCreate({
        config: {
          companyName,
          tone,
          audience,
          jobTitle,
          contentDescription,
        },
      });
    }
  };

  const updateConfig = useDebouncedCallback(
    async (id: string, config: any, key: string, val: any) => {
      await updateBulkRequest([
        {
          id,
          config: { ...config, [key]: val },
        },
      ]);
      setUnsavedConfig(null);
    },
    1000,
  );

  const handleConfigChanged = async (
    id: string,
    config: any,
    key: string,
    val: any,
  ) => {
    // Cache config change in case we need to save prior to debounce function firing
    setUnsavedConfig({ id, config, key, val });

    // Call debounced function to update config
    await updateConfig(id, config, key, val);
  };

  // const handleConfig = useDebouncedCallback(
  //   (id: string, config: any, key: string, val: any) => {
  //     updateBulkRequest([
  //       {
  //         id,
  //         config: { ...config, [key]: val },
  //       },
  //     ]);
  //   },
  //   1000,
  // );

  return (
    <div>
      <WizardStepTitle
        projectName={draftProject?.name}
        onRename={onRename}
        onArchive={onArchive}
        id='project-wizard-customize-header'
      >
        Let’s add the details
      </WizardStepTitle>
      <div className='mt-8 flex flex-col'>
        <h2 className='text-[20px] font-semibold'>Project Settings</h2>
        <p>These offer general direction to all actions.</p>
      </div>
      <div className='mt-4 flex flex-col gap-8 lg:grid lg:grid-cols-2'>
        <CardWrapper>
          <CardTitle
            title='Writing Tone'
            icon={
              <img
                src={VoiceChat}
                className='w-[24px]'
              />
            }
            id='project-wizard-customize-writing-tone-title'
            className='font-semibold'
            required
          />
          <p className='mb-4 mt-1'>
            Choose a tone that fits your employer brand best
          </p>
          <Select
            aria-label='pick a tone that fits your brand or mood'
            items={tones}
            selectedKey={tone}
            onSelectionChange={handleSelectionChange}
            isRequired
          >
            {item => <Item>{item.name}</Item>}
          </Select>
        </CardWrapper>
        <CardWrapper>
          <CardTitle
            title='Company'
            icon={<MdSettings fontSize='24px' />}
            id='project-wizard-customize-company-title'
            className='font-semibold'
            required
          />
          <p className='mb-4 mt-1'>Provide the hiring company’s name. </p>
          <TextField
            value={companyName}
            onChange={setCompanyName}
            aria-label='Which company profile would you like to use'
          />
        </CardWrapper>
        <CardWrapper>
          <CardTitle
            title='Audience'
            icon={
              <MdPeopleAlt
                fontSize='20px'
                color='white'
                className='rounded-sm bg-black p-[3px]'
              />
            }
            id='project-wizard-customize-audience-title'
            className='font-semibold'
          />
          <p className='mb-4 mt-1'>Who would you like to attract?</p>
          <TextField
            value={audience}
            placeholder='College grad, Software dev, Spanish speaking people'
            onChange={setAudience}
            aria-label='Who would you like to attract?'
          />
        </CardWrapper>
        <CardWrapper className='col-span-2'>
          {draftProject?.promptType !== PromptType.OpenPosition ? (
            <>
              <CardTitle
                title='Provide specific instructions or details'
                icon={<IoDocumentText fontSize='24px' />}
                id='project-wizard-customize-details-title'
                className='font-semibold'
                required
              />
              <p className='mb-4 mt-1'>
                Include benefits, upcoming events, or any relevant information.
              </p>
            </>
          ) : (
            <div className='flex flex-col gap-8'>
              <div>
                <CardTitle
                  title='Job Title'
                  icon={<MdPersonSearch fontSize='24px' />}
                  id='project-wizard-customize-job-position-title'
                  className='font-semibold'
                  required
                />
                <p className='mb-4 mt-1'>
                  What position are you seeking to fill?
                </p>
                <TextField
                  value={jobTitle}
                  onChange={setJobTitle}
                  aria-label='What position are you seeking to fill?'
                  isRequired
                />
              </div>
              <div>
                <CardTitle
                  title='Job Description'
                  icon={<IoDocumentText fontSize='24px' />}
                  id='project-wizard-customize-job-description-title'
                  className='font-semibold'
                  required
                />
                <p className='mb-4 mt-1'>
                  Paste your job description, job details, or URL
                </p>
              </div>
            </div>
          )}

          <TextArea
            value={contentDescription}
            onChange={setContentDescription}
            aria-label='Paste your job description, job details, or URL'
            isRequired
          />
        </CardWrapper>
      </div>
      <div className='mt-8 flex flex-col'>
        <h2 className='text-[20px] font-semibold'>Action Settings</h2>
        <p>These settings determine the direction of individual actions.</p>
      </div>
      <div className='mt-4 flex flex-col gap-8'>
        {actionRequests.map((ar, index) => {
          const action = actions.find(a => a.type === ar.type);
          const meta = actionTypeMetaMap.get(ar.type);
          if (action) {
            return (
              <ActionConfig
                key={`${action.name} ${index} config`}
                className='col-span-2'
                img={meta?.img}
                actionName={action.name}
                actionSettings={action?.settings}
                actionType={action.type}
                requestConfig={ar.config}
                updateConfig={(key: string, val: any) => {
                  handleConfigChanged(ar.id, ar.config, key, val);
                }}
              />
            );
          } else return <></>;
        })}
      </div>

      <div className='mt-[66px] flex flex-row-reverse justify-end gap-4 pb-24'>
        <PrimaryButton
          onPress={handleCreate}
          isLoading={loading}
          isDisabled={
            loading ||
            !tone ||
            !companyName ||
            (draftProject?.promptType === PromptType.General &&
              !contentDescription) ||
            (draftProject?.promptType === PromptType.OpenPosition && (!jobTitle || !contentDescription))
          }
        >
          Create!
        </PrimaryButton>
        <SecondaryButton onPress={onBack}>Back</SecondaryButton>
      </div>
    </div>
  );
};

export default ProjectWizardConfigurationStep;
