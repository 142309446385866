import { useRef } from 'react';
import { AriaMenuProps, useMenu } from 'react-aria';
import { useTreeState } from 'react-stately';
import { ActionMenuSection } from './ActionMenuSection';

interface Props<T extends object> extends AriaMenuProps<T> {
  onClose: () => void;
}

export const ActionMenu = <T extends object>(props: Props<T>) => {
  // Create state based on the incoming props
  const state = useTreeState(props);

  // Get props for the menu element
  const ref = useRef(null);
  const { menuProps } = useMenu(props, state, ref);

  return (
    <ul
      {...menuProps}
      ref={ref}
      className='shadow-xs min-w-[200px] rounded-md border-[1px] border-bp-gray bg-white pb-1 pt-1 focus:outline-none'
    >
      {[...state.collection].map(item => (
        <ActionMenuSection
          key={item.key}
          section={item}
          state={state}
          onAction={
            props.onAction ||
            (() => {
              return;
            })
          }
          onClose={props.onClose}
        />
      ))}
    </ul>
  );
};
