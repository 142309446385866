import { BiComment, BiLike, BiShare } from 'react-icons/bi';
import { ActionPreviewCard } from '../ActionPreviewCard';

interface Props {
  companyName?: string;
  result: any;
}

export const OrganicFacebookPreview = (props: Props) => {
  const { companyName } = props;
  const { post, hashtags } = props.result as {
    post?: string;
    hashtags?: string[];
  };
  return (
    <ActionPreviewCard>
      <div className='flex h-full max-h-[40rem] flex-col gap-2 overflow-y-scroll p-8 md:w-96'>
        <div className='flex flex-row items-center gap-2'>
          <div className='bg-sidebar h-12 w-12 rounded-full bg-bp-gray bg-cover bg-center' />
          <div className='flex flex-col'>
            <h3 className='text-lg font-medium text-indigo-900'>
              {companyName}
            </h3>
            <p className='-mt-2 text-sm text-gray-400'>1d</p>
          </div>
        </div>
        <div className='relative flex-1'>
          <p className='text-left text-sm peer-hover:font-medium peer-hover:tracking-tight peer-hover:text-bp-purple'>
            {post}
          </p>
          <div className='group flex flex-wrap overflow-hidden '>
            {hashtags?.map(tag => (
              <p
                key={tag}
                className='mr-1 text-left text-sm text-bp-indigo peer-hover:group-[]:font-medium peer-hover:group-[]:text-bp-purple'
              >
                {tag.charAt(0) === '#' ? tag : `#${tag}`}
              </p>
            ))}
          </div>
        </div>
        <div className='flex flex-row justify-between'>
          <p className='flex flex-row items-center gap-1 text-xs text-gray-500'>
            <BiLike /> 5.8K
          </p>
          <p className='flex flex-row items-center gap-1 text-xs text-gray-500'>
            97 comments | 83 shares
          </p>
        </div>
        <div className='flex w-full flex-row justify-between border-t-2 border-gray-400 px-4 pt-2'>
          <p className='flex flex-row items-center gap-1 text-sm text-gray-500'>
            <BiLike /> Like
          </p>
          <p className='flex flex-row items-center gap-1 text-sm text-gray-500'>
            <BiComment /> Comment
          </p>
          <p className='flex flex-row items-center gap-1 text-sm text-gray-500'>
            <BiShare /> Share
          </p>
        </div>
      </div>
    </ActionPreviewCard>
  );
};
