import { TertiaryButton } from '@boostpoint/ui';
import CarlAnimated from '../assets/CARL-animated.gif';
import { BiLeftArrowAlt } from 'react-icons/bi';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className='flex h-full w-full flex-col items-center justify-center md:pb-[200px]'>
      <img
        src={CarlAnimated}
        alt='Carl'
        className='w-[200px] md:w-[300px]'
      />
      <p className='mt-8 flex flex-row items-center gap-1 text-bp-purple'>
        <BiLeftArrowAlt
          fontSize='22px'
          className='hidden md:flex'
        />{' '}
        Carl couldn't find that page. Please use the menu to navigate.
      </p>
      <p className='mt-6 text-center md:hidden'>
        <TertiaryButton>
          <Link to='/project/new/name'>Create Project</Link>
        </TertiaryButton>
      </p>
    </div>
  );
};

export default NotFound;
