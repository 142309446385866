import clsx from 'clsx';
import { useRef } from 'react';
import { AriaCheckboxProps, useCheckbox } from 'react-aria';
import { useToggleState } from 'react-stately';

interface Props extends AriaCheckboxProps {
  className?: string;
}

export const Checkbox = (props: Props) => {
  const {
    children,
    onChange,
    className = '',
    isRequired,
    isDisabled,
    name,
    id,
  } = props;
  const ref = useRef(null);
  const state = useToggleState(props);
  const { inputProps } = useCheckbox(props, state, ref);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e.target.checked ? true : false);
  };

  return (
    <div
      className={`${className}`}
      id={id}
    >
      <label
        className='flex cursor-pointer items-center justify-center gap-2'
        aria-label={name}
      >
        <input
          {...inputProps}
          id={name}
          aria-label={name}
          ref={ref}
          required={isRequired}
          onChange={handleChange}
          className={clsx(
            'relative m-0 h-[12px] w-[12px] appearance-none border-[2px] border-solid border-white text-bp-purple outline outline-[1px] outline-bp-purple ',
            state.isSelected ? 'bg-bp-green' : 'bg-white',
            isDisabled ? 'cursor-not-allowed' : 'cursor-pointer',
          )}
          role='checkbox'
        />
        {children}
      </label>
    </div>
  );
};
