import { ProgressBar, TertiaryButton } from '@boostpoint/ui';

const FreemiumNotice = (props: {
  projectCount: number;
  freemiumProjectCount: number;
  handleUpgrade: () => void;
}) => {
  const { projectCount, handleUpgrade, freemiumProjectCount } = props;
  return (
    <div className='mb-8 pb-4'>
      <h3 className='mb-2 text-[20px] font-semibold'>Free Trial</h3>
      <ProgressBar value={projectCount + 8 - freemiumProjectCount} />
      <p className='mb-4 mt-2 text-bp-pink'>
        {freemiumProjectCount - projectCount} project
        {freemiumProjectCount - projectCount === 1 ? '' : 's'} left
      </p>
      <p className='my-4'>Upgrade now for unlimited recruitment content!</p>
      <TertiaryButton onPress={handleUpgrade}>Upgrade</TertiaryButton>
    </div>
  );
};

export default FreemiumNotice;
