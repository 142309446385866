import { BiChevronLeft } from 'react-icons/bi';
import { ActionPreviewCard } from '../ActionPreviewCard';

interface Props {
  companyName?: string;
  result: any;
}

export const TextCampaignPreview = (props: Props) => {
  const { companyName } = props;
  const { messages } = props.result as {
    messages?: string[];
  };
  return (
    <ActionPreviewCard>
      <div className='flex h-full flex-col gap-2 overflow-x-hidden overflow-y-scroll p-8 md:w-96'>
        <div className='my-4 flex flex-row items-center justify-between'>
          <BiChevronLeft className='text-4xl font-medium text-indigo-900' />
          <div className='flex flex-col items-center justify-center'>
            <div className='bg-sidebar h-10 w-10 rounded-full bg-cover bg-center' />
            <p className='text-sm text-gray-400'>{companyName}</p>
          </div>
          <BiChevronLeft className='text-4xl font-medium text-indigo-900 opacity-0' />
        </div>
        {messages &&
          Array.isArray(messages) &&
          messages.map((b: string) => (
            <div className='ml-auto rounded-lg rounded-br-none bg-purple-800 p-2 md:w-80'>
              <p className='text-left text-sm font-semibold text-white'>{b}</p>
            </div>
          ))}
      </div>
    </ActionPreviewCard>
  );
};
