import IL from '../../assets/IL.svg';

const IntercomLauncher = () => (
  <button
    className='w-00 pointer-events-none absolute bottom-8 right-8 z-50 h-0 rounded-full transition-all duration-500 md:pointer-events-auto md:h-14 md:w-14 md:hover:bottom-7 md:hover:right-7 md:hover:h-16 md:hover:w-16'
    id='custom_intercom'
    aria-label='intercom'
  >
    <img
      src={IL}
      alt='Intercom Launcher'
    />
  </button>
);
export default IntercomLauncher;
