import { ForwardedRef, ReactNode, forwardRef } from 'react';
import background from '../assets/dialog-border.png';

export const Dialog = forwardRef(
  (props: Props, ref: ForwardedRef<HTMLDialogElement>) => {
    return (
      <dialog
        className={`${
          props.open && 'block xl:flex'
        } bg-top-left flex-row items-end justify-between gap-12 rounded-2xl bg-no-repeat p-8 pt-12 text-[16px] backdrop:backdrop-blur-lg md:w-[50%] md:max-w-[775px] ${
          props.className ? props.className : ''
        }`}
        style={{ backgroundImage: `url(${background})` }}
        ref={ref}
      >
        {props.children}
      </dialog>
    );
  },
);

type Props = {
  children: ReactNode;
  className?: string;
  open: boolean;
};
