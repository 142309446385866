import {
  ActionTag,
  ActionType,
  ActionTypeMetaInterface,
} from '@boostpoint/types';
import iconsBeta from '../assets/iconsBeta';

export const actionTypeMetaMap: Map<ActionType, ActionTypeMetaInterface> =
  new Map([
    [
      ActionType.HiringFacebook,
      {
        type: ActionType.HiringFacebook,
        img: iconsBeta.HiringFacebook,
        tag: ActionTag.SocialMedia,
      },
    ],
    [
      ActionType.OrganicFacebook,
      {
        type: ActionType.OrganicFacebook,
        img: iconsBeta.OrganicFacebook,
        tag: ActionTag.SocialMedia,
      },
    ],
    [
      ActionType.OrganicLinkedin,
      {
        type: ActionType.OrganicLinkedin,
        img: iconsBeta.OrganicLinkedin,
        tag: ActionTag.SocialMedia,
      },
    ],
    [
      ActionType.OrganicInstagram,
      {
        type: ActionType.OrganicInstagram,
        img: iconsBeta.OrganicInstagram,
        tag: ActionTag.SocialMedia,
      },
    ],
    [
      ActionType.OrganicTwitter,
      {
        type: ActionType.OrganicTwitter,
        img: iconsBeta.OrganicTwitter,
        tag: ActionTag.SocialMedia,
      },
    ],
    [
      ActionType.OrganicTiktok,
      {
        type: ActionType.OrganicTiktok,
        img: iconsBeta.OrganicTiktok,
        tag: ActionTag.SocialMedia,
      },
    ],
    [
      ActionType.JobDesc,
      {
        type: ActionType.JobDesc,
        img: iconsBeta.JobDesc,
        tag: ActionTag.JobBoards,
      },
    ],
    [
      ActionType.JobTitleAlt,
      {
        type: ActionType.JobTitleAlt,
        img: iconsBeta.JobDesc,
        tag: ActionTag.JobBoards,
      },
    ],
    [
      ActionType.GoogleAd,
      {
        type: ActionType.GoogleAd,
        img: iconsBeta.GoogleAd,
        tag: ActionTag.SocialMedia,
      },
    ],
    [
      ActionType.HiringText,
      {
        type: ActionType.HiringText,
        img: iconsBeta.HiringText,
        tag: ActionTag.Messaging,
      },
    ],
    [
      ActionType.ReferralText,
      {
        type: ActionType.ReferralText,
        img: iconsBeta.ReferralText,
        tag: ActionTag.Messaging,
      },
    ],
    [
      ActionType.FollowupText,
      {
        type: ActionType.FollowupText,
        img: iconsBeta.FollowupText,
        tag: ActionTag.Messaging,
      },
    ],
    [
      ActionType.DmLinkedin,
      {
        type: ActionType.DmLinkedin,
        img: iconsBeta.DmLinkedin,
        tag: ActionTag.Messaging,
      },
    ],
    [
      ActionType.HiringEmail,
      {
        type: ActionType.HiringEmail,
        img: iconsBeta.HiringEmail,
        tag: ActionTag.Email,
      },
    ],
    [
      ActionType.FollowupEmail,
      {
        type: ActionType.FollowupEmail,
        img: iconsBeta.FollowupEmail,
        tag: ActionTag.Email,
      },
    ],
    [
      ActionType.FollowupVoicemail,
      {
        type: ActionType.FollowupVoicemail,
        img: iconsBeta.FollowupVoicemail,
        tag: ActionTag.Scripts,
      },
    ],
    [
      ActionType.InterviewQuestions,
      {
        type: ActionType.InterviewQuestions,
        img: iconsBeta.InterviewQuestions,
        tag: ActionTag.Scripts,
      },
    ],
  ]);
