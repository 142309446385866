import {
  ActionBoInterface,
  JobStatus,
  ActionResultBoInterface,
  ActionResultFeedbackPostDtoInterface,
  ActionResultRewritePostDtoInterface,
  ActionType,
  RewriteJobBoInterface,
} from '@boostpoint/types';
import { useMemo, useState } from 'react';
import { useOverlayTriggerState } from 'react-stately';
import {
  ActionDetails,
  ActionPreview,
  ActionResultSection,
  GoogleAdPreview,
  InterviewQuestionsPreview,
  LinkedInCampaignPreview,
  Modal,
  ModalDialog,
  OrganicFacebookPreview,
  OrganicInstagramPreview,
  OrganicLinkedInPreview,
  OrganicTiktokPreview,
  OrganicTwitterPreview,
  PrimaryButton,
  ScheduleVoicemailPreview,
  SecondaryButton,
  TextArea,
  TextCampaignPreview,
  TitlesPreview,
  WebJobPreview,
} from '..';
import { AdCampaignPreview } from '../preview-cards/AdCampaignPreview';
import { EmailPreview } from '../preview-cards/EmailPreview';

type Props = {
  userId?: string;
  onFeedback?(
    actionResultId: string,
    feedback: ActionResultFeedbackPostDtoInterface,
  ): void;
  onRewrite?(
    actionResultId: string,
    request: ActionResultRewritePostDtoInterface,
  ): void;
  action?: ActionBoInterface;
  img?: string;
  companyName?: string;
  results: ActionResultBoInterface[];
  rewriteJobs: RewriteJobBoInterface[];
  resultIndex: number;
  setResultIndex: React.Dispatch<React.SetStateAction<number>>;
};

export const GenericOverlay = (props: Props) => {
  const {
    action,
    img,
    companyName,
    results,
    rewriteJobs,
    resultIndex,
    setResultIndex,
    onRewrite,
  } = props;

  const [rewriteRequest, setRewriteRequest] = useState<{
    id: string;
    dto: ActionResultRewritePostDtoInterface;
  } | null>(null);
  const rewriteDialogState = useOverlayTriggerState({});

  // For some results that are a series, like a newsletter campaign, there may be n number of results with result indexes.
  // Let's consider an example where we have 5 newsletters in a campaign.
  // For each newsletter, there may be multiple "versions" that have been rewritten.
  // Let's say that newsletter 1 and 3 have both been rewritten one time.
  // This means that the results array will have 7 total results in it. Index 0 to 4 will all have at least 1 newsletter (5 results).
  // But index 0 and 2 will have 2 results, version=0 and version=1.
  // We want to map the results so that we are showing the 5 newsletter results to the user, using the latest versions.
  // Eventually we may want to allow the user to select the version they wish to see instead of simply choosing the latest.
  // We will create a Map of <number, ActionResultBoInterface[]>, where the key is the result index and the value is all of
  // the result versions for that index.
  const resultMap = useMemo(() => {
    const map = new Map<number, ActionResultBoInterface[]>();

    // Pre-sort results array by version in descending order so that map is created with highest versions for each index first
    results.sort((a, b) => b.version - a.version);

    // Now create map keyed by index with a value of all results
    for (const [index, result] of results.entries()) {
      // Account for nullable index. Not ideal, but works for now. Not sure that index should be nullable/undefinable.
      const idx = result.index ?? index;
      const existingEntry = map.get(idx);

      // If an entry for the index does not already exist, create one with result
      if (!existingEntry) {
        map.set(idx, [result]);
        continue;
      }

      // Otherwise, append to existing entry array
      map.set(idx, [...(existingEntry ?? []), result]);
    }

    return map;
  }, [results]);

  const selectedResult = resultMap.get(resultIndex)?.[0];

  const anyJobsInProgress = Boolean(
    rewriteJobs.find(
      job =>
        job.status === JobStatus.Queued || job.status === JobStatus.Processing,
    ),
  );

  const rewriteTitle = useMemo(() => {
    if (!rewriteRequest?.dto) {
      return `Rewrite ${action?.name}`;
    }

    const rewriteKeys = Object.keys(rewriteRequest?.dto.rewriteMap);

    if (rewriteKeys.length === 1) {
      // Take a property like "email_subject" and turn it into "Email Subject"
      const words = rewriteKeys[0].split(/_/g);
      const propertyTitle = words
        .map(w => w[0].toUpperCase() + w.slice(1))
        .join(' ');

      return `Rewrite ${propertyTitle}`;
    }

    return `Rewrite ${action?.name}`;
  }, [action, rewriteRequest?.dto]);

  const handleRewrite = (
    actionResultId: string,
    request: ActionResultRewritePostDtoInterface,
  ) => {
    setRewriteRequest({ id: actionResultId, dto: request });
    rewriteDialogState.open();
  };

  const submitRewrite = () => {
    if (!rewriteRequest) {
      throw new Error('No rewrite request to submit');
    }

    onRewrite && onRewrite(rewriteRequest?.id, rewriteRequest?.dto);
    rewriteDialogState.close();
    setRewriteRequest(null);
  };

  const handleCommentChanged = (comment: string) => {
    if (!rewriteRequest?.dto) {
      throw new Error('No rewrite request to update');
    }

    setRewriteRequest({
      ...rewriteRequest,
      dto: { ...rewriteRequest.dto, comment: comment ?? '' },
    });
  };

  return (
    <>
      <ActionDetails
        title={action?.name}
        img={img}
      >
        {selectedResult &&
          Object.keys(selectedResult?.result).map(property => {
            if (selectedResult?.result[property]) {
              let t = '';
              const p = selectedResult?.result[property];
              if (Array.isArray(p)) {
                t = p.join('\n');
              } else {
                t = p as string;
              }
              return (
                <ActionResultSection
                  isLoading={anyJobsInProgress}
                  type='textarea'
                  label={
                    property[0].toUpperCase() +
                    property.replace(/_/g, ' ').substring(1).toLowerCase()
                  }
                  onRewrite={() => {
                    handleRewrite(selectedResult.id, {
                      rewriteMap: { [property]: true },
                    });
                  }}
                  onCopy={() => {
                    navigator.clipboard.writeText(t);
                  }}
                  text={t}
                  key={t}
                />
              );
            }
          })}
      </ActionDetails>

      <ActionPreview
        feedback={selectedResult?.feedback}
        actionResultId={selectedResult?.id}
        userId={props.userId}
        onFeedback={props.onFeedback}
        onRewrite={handleRewrite}
        resultKeys={selectedResult ? Object.keys(selectedResult?.result) : []}
        isLoading={anyJobsInProgress}
      >
        {Array.from(resultMap.entries())
          .sort((a, b) => a[0] - b[0])
          .map(([idx, res]) => (
            <button
              className={`${
                idx === resultIndex && resultMap.size > 1
                  ? 'rounded-xl ring-2 ring-bp-indigo'
                  : ''
              } h-full`}
              onClick={() => {
                setResultIndex(idx);
              }}
              key={`resultPreview-${res[0]?.id}`}
            >
              {action?.type === ActionType.HiringFacebook ? (
                <AdCampaignPreview
                  companyName={companyName}
                  result={res[0]?.result}
                />
              ) : action?.type === ActionType.OrganicFacebook ? (
                <OrganicFacebookPreview
                  companyName={companyName}
                  result={res[0]?.result}
                />
              ) : action?.type === ActionType.OrganicInstagram ? (
                <OrganicInstagramPreview
                  companyName={companyName}
                  result={res[0]?.result}
                />
              ) : action?.type === ActionType.OrganicLinkedin ? (
                <OrganicLinkedInPreview
                  companyName={companyName}
                  result={res[0]?.result}
                />
              ) : action?.type === ActionType.OrganicTiktok ? (
                <OrganicTiktokPreview
                  companyName={companyName}
                  result={res[0]?.result}
                />
              ) : action?.type === ActionType.OrganicTwitter ? (
                <OrganicTwitterPreview
                  companyName={companyName}
                  result={res[0]?.result}
                />
              ) : action?.type === ActionType.FollowupVoicemail ? (
                <ScheduleVoicemailPreview result={res[0]?.result} />
              ) : action?.type === ActionType.GoogleAd ? (
                <GoogleAdPreview
                  companyName={companyName}
                  result={res[0]?.result}
                />
              ) : action?.type === ActionType.InterviewQuestions ? (
                <InterviewQuestionsPreview result={res[0]?.result} />
              ) : action?.type === ActionType.DmLinkedin ? (
                <LinkedInCampaignPreview
                  companyName={companyName}
                  result={res[0]?.result}
                />
              ) : action?.type === ActionType.FollowupText ? (
                <TextCampaignPreview
                  companyName={companyName}
                  result={res[0]?.result}
                />
              ) : action?.type === ActionType.HiringText ? (
                <TextCampaignPreview
                  companyName={companyName}
                  result={res[0]?.result}
                />
              ) : action?.type === ActionType.ReferralText ? (
                <TextCampaignPreview
                  companyName={companyName}
                  result={res[0]?.result}
                />
              ) : action?.type === ActionType.JobTitleAlt ? (
                <TitlesPreview result={res[0]?.result} />
              ) : action?.type === ActionType.HiringEmail ? (
                <EmailPreview result={res[0]?.result} />
              ) : action?.type === ActionType.FollowupEmail ? (
                <EmailPreview result={res[0]?.result} />
              ) : action?.type === ActionType.JobDesc ? (
                <WebJobPreview result={res[0]?.result} />
              ) : (
                <div>
                  <p>preview not found</p>
                </div>
              )}
            </button>
          ))}
      </ActionPreview>

      {rewriteDialogState.isOpen && (
        <Modal
          state={rewriteDialogState}
          isDismissable
        >
          <ModalDialog title={rewriteTitle}>
            <div className='pb-[24px] pt-[20px]'>
              <label
                id='rewrite-title'
                className='text-sm'
              >
                What would you like to see changed? (Optional)
              </label>
            </div>

            <TextArea
              inputClassName='h-[88px]'
              aria-labelledby='rewrite-title'
              value={rewriteRequest?.dto.comment}
              onChange={handleCommentChanged}
            />

            <div className='mt-4 flex justify-end gap-2'>
              <SecondaryButton onPress={rewriteDialogState.close}>
                Cancel
              </SecondaryButton>
              <PrimaryButton onPress={submitRewrite}>Rewrite</PrimaryButton>
            </div>
          </ModalDialog>
        </Modal>
      )}
    </>
  );
};
