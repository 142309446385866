import { ReactNode, useRef } from 'react';
import { AriaModalOverlayProps, Overlay, useModalOverlay } from 'react-aria';
import { OverlayTriggerState } from 'react-stately';

interface Props extends AriaModalOverlayProps {
  state: OverlayTriggerState;
  children: ReactNode;
}

export const Modal = ({ state, children, ...props }: Props) => {
  const ref = useRef(null);
  const { modalProps, underlayProps } = useModalOverlay(props, state, ref);

  return (
    <Overlay>
      <div
        style={{
          position: 'fixed',
          zIndex: 100,
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          background: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        {...underlayProps}
      >
        <div
          {...modalProps}
          ref={ref}
        >
          {children}
        </div>
      </div>
    </Overlay>
  );
};
