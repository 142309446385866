import clsx from 'clsx';
import { ReactNode, useRef } from 'react';
import { AriaTextFieldProps, useTextField } from 'react-aria';
import { IoIosWarning } from 'react-icons/io';

interface Props extends AriaTextFieldProps {
  className?: string;
  icon?: ReactNode;
  button?: ReactNode;
  showWarning?: boolean;
  errorMessage?: string;
}

export const TextField = (props: Props) => {
  const {
    className,
    icon,
    button,
    showWarning,
    label,
    description,
    errorMessage,
    isRequired,
    isDisabled,
  } = props;

  const ref = useRef(null);
  const { labelProps, inputProps, descriptionProps, errorMessageProps } =
    useTextField(props, ref);

  return (
    <div className={clsx('w-full flex-1', label, className)}>
      <label
        {...labelProps}
        className='text-sm text-bp-indigo'
      >
        {label}
      </label>

      {description && <div {...descriptionProps}>{description}</div>}

      {/* Override text box boundaries to create our own */}
      <div
        className={clsx(
          'font-weight-bold flex flex-row content-center justify-center gap-2 rounded-md border-[1px] bg-white p-2 px-2',
          !isDisabled &&
            !errorMessage &&
            !showWarning &&
            'border-bp-purple bg-white focus-within:border-purple-800',
          isDisabled && 'bg-bp-lGray',
          errorMessage &&
            'border-bp-pink bg-red-50 focus-within:border-bp-pink',
          showWarning &&
            'border-bp-yellow bg-yellow-50 focus-within:border-bp-yellow',
        )}
      >
        <div
          className={clsx(
            'self-center',
            !errorMessage && !showWarning && 'text-slate-300',
            errorMessage && 'text-bp-pink',
            showWarning && 'text-yellow-600',
          )}
        >
          {icon}
        </div>

        <input
          {...inputProps}
          className='text-small w-full bg-transparent text-bp-black !outline-none'
          ref={ref}
          required={isRequired}
        />

        {button}
      </div>

      {!!errorMessage && (
        <div
          className='error-message flex flex-row items-center justify-end px-4'
          {...errorMessageProps}
        >
          <p className='text-xs text-bp-pink md:text-sm'>
            <IoIosWarning />
          </p>
          <p className='text-xs text-bp-pink md:text-sm'>{`${errorMessage}`}</p>
        </div>
      )}
    </div>
  );
};
