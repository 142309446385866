import { ActionPreviewCard } from '../ActionPreviewCard';

interface Props {
  result: any;
}

export const EmailPreview = (props: Props) => {
  const { subject, body } = props.result as {
    subject?: string;
    body?: string;
  };
  return (
    <ActionPreviewCard>
      <div className='flex h-full max-h-[32rem] flex-col overflow-y-scroll text-sm font-medium'>
        <div className='m-8 mb-0 border-b-[1px] pb-4'>
          <p className='text-left'>Subject: {subject && subject.toString()}</p>
        </div>
        {body && (
          <div className='overflow-y-auto p-8 pt-4'>
            <p className='whitespace-pre-wrap text-left font-normal'>{body}</p>
          </div>
        )}
      </div>
    </ActionPreviewCard>
  );
};
