import { createContext, useContext } from 'react';

// This is for globally storing some important element references that are used in multiple places throughout the app.
export const AppRefsContext = createContext<any>(null);

export const useAppRefs = () => {
  const refs = useContext<{
    contentContainerRef: React.RefObject<HTMLDivElement>;
  }>(AppRefsContext);

  if (!refs) {
    throw new Error(
      `You must call useAppRefs() inside of an <AppRefsProvider />`,
    );
  }

  return refs;
};
