import { createContext, useContext } from 'react';

export const AuthContext = createContext<any>(null);

export const useAuth = () => {
  const auth = useContext(AuthContext);
  if (!auth) {
    throw new Error(`You must call useAuth() inside of an <AuthProvider />`);
  }
  return auth;
};
