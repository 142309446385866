import React, { useRef } from 'react';
import { AppRefsContext } from './context';

const AppRefsProvider = (props: { children: React.ReactNode }) => {
  const { children } = props;

  const contentContainerRef = useRef(null);

  const wrapped = {
    contentContainerRef,
  };

  return (
    <AppRefsContext.Provider value={wrapped}>
      {children}
    </AppRefsContext.Provider>
  );
};
export default AppRefsProvider;
