import { MemberRole } from '@boostpoint/types';
import { ReactNode, useMemo } from 'react';
import { useCompany } from '../../providers/CompanyProvider';

export interface Props {
  requiredRole: MemberRole;
  children: ReactNode;
}

// Wrapper to check user for authorized role and if not, instead of wrapping page and redirecting, just show nothing
const RoleRequired = ({ requiredRole, children }: Props) => {
  const { role, isLoadingRole, hasRole } = useCompany();

  const isAuthorized = useMemo(
    () => hasRole(requiredRole),
    [role, requiredRole, hasRole],
  );

  return <>{!isLoadingRole && isAuthorized && children}</>;
};

export default RoleRequired;
