interface Props {
  children?: React.ReactNode;
}

export const VersionChip = (props: Props) => {
  const { children } = props;

  return (
    <span className='inline-block rounded-full bg-bp-green px-4 py-1.5 text-xs font-medium text-white'>
      <div className='flex items-center justify-center'>{children}</div>
    </span>
  );
};
